import moment from '@/helpers/useMoment'
import { DATE_FORMAT, FULL_FORMAT } from '@/constants/date'

export default function (date, withTime = false, emptyValue = undefined) {
  let dateMoment = getDate(date, withTime)
  if (!dateMoment) return emptyValue

  dateMoment.local()
  const withTimeInner =
    typeof withTime === 'boolean' ? withTime : date.with_time
  return dateMoment.format(withTimeInner ? FULL_FORMAT : DATE_FORMAT)
}

export function getDate(date, withTime) {
  if (!date) return null

  if (!Object.prototype.hasOwnProperty.call(date, 'value')) {
    date = {
      value: date,
      with_time: withTime || false,
    }
  }

  if (!date.value) return undefined

  let dateMoment = moment.utc(date.value)
  if (!dateMoment.isValid()) return undefined

  return dateMoment
}
