const storeKey = 'selectedCalendars'

export function getSelectedCalendarsIds() {
  let str = localStorage.getItem(storeKey)
  if (!str) return []

  return JSON.parse(str).filter((item) => !!item)
}

export function setSelectedCalendarsIds(idsArr) {
  if (!Array.isArray(idsArr)) return

  localStorage.setItem(
    storeKey,
    JSON.stringify(idsArr.filter((item) => !!item))
  )
}

export function addSelectedCalendarId(newCalendarId) {
  let calendars = getSelectedCalendarsIds()

  calendars.push(newCalendarId)
  setSelectedCalendarsIds(calendars)
}

export function deleteSelectedCalendarById(id) {
  if (!id) return
  let calendars = getSelectedCalendarsIds()
  let index = calendars.findIndex((current) => current === id)
  if (index < 0) return

  calendars.splice(index, 1)
  setSelectedCalendarsIds(calendars)
}
