<template>
  <div id="app">
    <transition name="fade" appear mode="out-in" @enter="onEnter">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      generalPreloader: null,
    }
  },
  methods: {
    removeGeneralPreloader() {
      this.generalPreloader = document.getElementById('general-preloader')
      if (!this.generalPreloader) return
      this.generalPreloader.addEventListener('transitionend', this.onEnd, false)
      this.generalPreloader.classList.add('_hide')
    },
    onEnd(e) {
      if (e.propertyName !== 'opacity') return
      this.generalPreloader.removeEventListener(
        'transitionend',
        this.onEnd,
        false
      )
      this.generalPreloader.remove()
      this.generalPreloader = null
    },
    onEnter() {
      this.removeGeneralPreloader()
    },
  },
}
</script>
