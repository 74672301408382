import Vue from 'vue'
import { get } from 'lodash'

export default {
  mutations: {
    setErrorsToInputsMixin(
      state,
      { inputsObjPath = 'inputs', errorsObj } = {}
    ) {
      if (!errorsObj) return

      const inputsObj = get(state, inputsObjPath)

      if (!inputsObj) return

      for (let key in errorsObj) {
        let inputKey = key.split('.')[0]

        if (Object.prototype.hasOwnProperty.call(inputsObj, inputKey)) {
          Vue.set(inputsObj[inputKey], 'error', errorsObj[key])
        }
      }
    },
    formClearErrorsMixin(state, { inputsObjPath = 'inputs' } = {}) {
      const inputsObj = get(state, inputsObjPath)

      state.overFormErrorMixin = null

      if (!inputsObj) return

      for (let key in inputsObj) {
        inputsObj[key].error = null
      }
    },
  },
  actions: {
    errorHandleMixin({ commit }, { inputsObjPath, e }) {
      if (!e.response || !e.response.data) {
        if (e.status === 413) {
          return commit('setProperty', ['overFormErrorMixin', 'File Too Large'])
        }

        return commit('setProperty', ['overFormErrorMixin', e.message])
      }

      if (e.response.data && e.response.data.errors) {
        if (typeof e.response.data.errors === 'string') {
          return commit('setProperty', [
            'overFormErrorMixin',
            e.response.data.errors,
          ])
        }

        commit('setErrorsToInputsMixin', {
          inputsObjPath,
          errorsObj: e.response.data.errors,
        })
      }

      if (e.response.data && e.response.data.message) {
        return commit('setProperty', [
          'overFormErrorMixin',
          e.response.data.message,
        ])
      }

      return commit('setProperty', ['overFormErrorMixin', e.message])
    },
  },
}
