<template>
  <div :style="style" class="editor-for-doc">
    <editor-menu-bubble
      :editor="editor"
      :keep-in-bounds="keepInBounds"
      v-slot="{ commands, isActive, menu }"
    >
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <button
          @click.prevent="commands.bold"
          :class="{ _active: isActive.bold() }"
          class="editor-for-doc__btn reset-button"
        >
          <svg xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="#ico-editor-bold"></use>
          </svg>
        </button>
        <button
          @click.prevent="commands.italic"
          :class="{ _active: isActive.italic() }"
          class="editor-for-doc__btn reset-button"
        >
          <svg xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="#ico-editor-italic"></use>
          </svg>
        </button>
      </div>
    </editor-menu-bubble>

    <editor-content class="editor-for-doc__content" :editor="editor" />

    <input :value="result" autocomplete="off" type="hidden" data-result />

    <div style="display: none !important">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        id="ico-editor-bold"
      >
        <path
          d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        id="ico-editor-italic"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
      </svg>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'

export default {
  components: {
    EditorContent,
    EditorMenuBubble,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    fontSize: {
      type: Number,
      default: 12,
    },
  },
  data(vm) {
    return {
      result: vm.value,
      keepInBounds: true,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: vm.value,
        onUpdate: this.onUpdate,
        onInit: this.onInit,
      }),
    }
  },
  computed: {
    style() {
      return {
        fontSize: this.fontSize + 'px',
        lineHeight: this.fontSize * 1.3 + 'px',
      }
    },
  },
  methods: {
    onUpdate(data) {
      let html = data.getHTML()
      html = html === '<p></p>' ? null : html

      this.result = html
      this.$emit('input', html)
    },
    onInit() {
      this.$nextTick(() => {
        let html = this.editor.getHTML()
        html = html === '<p></p>' ? null : html

        this.result = html
        this.$emit('input', html)
      })
    },
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
