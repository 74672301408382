import store from '../store'

/**
 * @param {object} address
 * @param {string, boolean} [type]
 */

export default (address, type) => {
  if (!address || typeof address !== 'object') return ''

  store.dispatch('locations/loadStates')

  const stateShort = store.getters['locations/getStateShortByName'](
    address.state
  )

  if (type === true) {
    let str = ''

    if (address.street) str += address.street + '<br>'
    if (address.city) str += address.city + '<br>'
    if (address.state)
      str += (stateShort || address.state) + (address.zip ? ', ' : '<br>')
    if (address.zip) str += address.zip + '<br>'
    if (address.country) str += address.country.name || ''

    return str
  } else if (type === 'two_rows') {
    let str = ''
    const arr = [
      address.city || null,
      stateShort || address.state || null,
      address.zip || null,
      address.country ? address.country.name : null,
    ]

    if (address.street) str += address.street + '<br>'
    return str + arr.filter((a) => a).join(', ')
  } else {
    const res = [
      (address.country && address.country.name) || '',
      stateShort || address.state || '',
      address.zip || '',
      address.city || '',
      address.street || '',
    ]
    return res.filter((a) => a).join(', ')
  }
}
