// export default function getAmountToPay(invoice) {
import roundTo from '@/helpers/roundTo'
//   const invoiceInputs = invoice.inputs
//
//   const value = invoice.item_id
//       ? invoiceInputs.total - invoiceInputs.balance + invoice.previousAmount
//       : invoiceInputs.total - invoiceInputs.balance
//
//   return parseFloat(value) || 0
// }

export default function getAmountToPay(invoice) {
  const invoiceInputs = invoice.inputs

  const value = invoiceInputs.balance

  return roundTo(Number(value), 2) || 0
}
