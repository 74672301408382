import { get } from 'lodash'
import moment from '@/helpers/useMoment'
import Vue from 'vue'
import Counter from '@/helpers/Counter'
import setInputsErrors from '@/helpers/errors/setInputsErrors'
import { axiosApi } from '@/axios'
import axios from 'axios'
let cancelRequest = null

const counter = new Counter()
const getInitialState = () => {
  return {
    pending: false,
    pendingCheckStorageRates: false,
    pendingStockNumber: false,
    loaded: false,
    isMuted: false,
    inputs: {
      estimate_delivery_date: {
        value: null,
        savedValue: null,
        error: null,
      },
      delivery_date: {
        value: null,
        savedValue: null,
        error: null,
      },
      same_as_customer: {
        value: null,
        savedValue: null,
        error: null,
      },
      vendor: {
        value: null,
        savedValue: null,
        error: null,
      },
      conditions: {
        value: null,
        savedValue: null,
        saveFullObject: true,
        error: null,
      },
    },
    inputsAdditional: {
      delivery_person: {
        value: null,
        savedValue: null,
        error: null,
      },
      keys: {
        value: null,
        savedValue: null,
        error: null,
      },
      run_and_drive: {
        value: null,
        savedValue: null,
        error: null,
      },
      commodity_color: {
        value: null,
        savedValue: null,
        saveFullObject: true,
        error: null,
      },
      free_storage_days: {
        value: null,
        savedValue: null,
        error: null,
      },
      storage_per_day: {
        value: null,
        savedValue: null,
        error: null,
      },
      stock: {
        value: null,
        savedValue: null,
        error: null,
      },
    },
    rateMessage: null,
    notes: [],
    files: [],
    filesToRemove: [],
    waitingFiles: [],
    addedFiles: [],
    uploadRequests: {},
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState())
    },
    resetErrors(state) {
      ;[state.inputs, state.inputsAdditional].forEach((inputs) => {
        for (let key in inputs) {
          inputs[key].error = null
        }
      })
    },
    clearSection(state) {
      const newState = getInitialState()
      Object.assign(state.inputs, newState.inputs)
      Object.assign(state.inputsAdditional, newState.inputsAdditional)
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
    setMuted(state, data = true) {
      state.isMuted = data
    },
    setInputValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.inputs, name)) return
      state.inputs[name].value = value
    },
    setInputErrors(state, errors) {
      setInputsErrors(state.inputs, errors)
    },
    setInputAdditionalValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.inputsAdditional, name))
        return
      state.inputsAdditional[name].value = value || null
    },
    setInputsAdditionalErrors(state, errors) {
      setInputsErrors(state.inputsAdditional, errors)
    },
    addNote(state) {
      state.notes.push(createNote())
    },
    removeNote(state, index) {
      state.notes.splice(index, 1)
    },
    setNoteValue(state, { index, value }) {
      if (!state.notes[index]) return
      state.notes[index].note = value
    },
    setNoteShowInDocumentsValue(state, { index, value }) {
      if (!state.notes[index]) return
      Vue.set(state.notes[index], 'show_in_documents', value)
    },
    addFiles(state, files = []) {
      state.files = state.files.concat(files)
    },
    removeFile(state, index) {
      if (state.files[index].id) {
        state.filesToRemove.push(state.files[index].id)
      }
      const addedIndex = state.addedFiles.findIndex(
        (item) => item === state.files[index].id
      )
      if (addedIndex >= 0) {
        state.addedFiles.splice(addedIndex, 1)
      }

      state.files.splice(index, 1)
    },
    setAll(state, commodity) {
      const deliveryDetails = commodity.delivery_details

      if (deliveryDetails) {
        for (let key in state.inputs) {
          state.inputs[key].value = deliveryDetails.inputs[key]
          state.inputs[key].savedValue = deliveryDetails.inputs[key]
        }

        for (let key in state.inputsAdditional) {
          state.inputsAdditional[key].value =
            deliveryDetails.inputs.additional_information[key]
          state.inputsAdditional[key].savedValue =
            deliveryDetails.inputs.additional_information[key]
        }

        state.files = deliveryDetails.files || []

        state.notes = deliveryDetails.notes
      }

      // if (
      //     !state.inputs.vendor.value
      //     && get(commodity, 'assign_dispatch.inputs.vendor')
      //     && !get(commodity, 'assign_dispatch.inputs.dispatch_canceled')
      // ) {
      //   state.inputs.vendor.value = commodity.assign_dispatch.inputs.vendor
      // }
    },
    addWaitingFile(state, id) {
      state.waitingFiles.push(id)
    },
    removeWaitingFile(state, index) {
      if (cancelRequest && cancelRequest._id === state.waitingFiles[index]) {
        cancelRequest.source.cancel()
      }
      state.waitingFiles.splice(index, 1)
    },
    setFileId(state, [index, id]) {
      state.addedFiles.push(id)
      Vue.set(state.files[index], 'id', id)
    },
    setFileProgress(state, [index, progress]) {
      Vue.set(state.files[index], 'progress', progress)
    },
    addUploadRequest(state, [id, request, cancel]) {
      Vue.set(state.uploadRequests, id, { request, cancel })
    },
    removeUploadRequest(state, id) {
      Vue.delete(state.uploadRequests, id)
    },
  },
  actions: {
    async addFiles({ state, commit, dispatch }, files) {
      commit(
        'addFiles',
        files.map((item) => {
          item.progress = 0
          return item
        })
      )

      // add waiting
      for (let file of files) {
        if (!state.waitingFiles.includes(file._id)) {
          commit('addWaitingFile', file._id)

          const formData = new FormData()

          if (file.file) {
            formData.append('file', file.file)
          }

          const CancelToken = axios.CancelToken

          const cancel = {
            _id: file._id,
            source: CancelToken.source(),
          }

          const request = () => {
            if (!state.waitingFiles.includes(file._id)) {
              commit('removeUploadRequest', file._id)
              cancelRequest = null
              return Promise.resolve()
            }

            return axiosApi
              .post(`/temp_storage/`, formData, {
                cancelToken: cancel.source.token,
                onUploadProgress: (progressEvent) => {
                  const currentFileIndex = state.files.findIndex(
                    (item) => item._id === file._id
                  )
                  if (currentFileIndex >= 0) {
                    commit('setFileProgress', [
                      currentFileIndex,
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      ),
                    ])
                  }
                },
              })
              .then(({ data }) => {
                const currentFileIndex = state.files.findIndex(
                  (item) => item._id === file._id
                )
                if (currentFileIndex >= 0) {
                  commit('setFileId', [currentFileIndex, data.data.id])
                }
              })
              .finally(async () => {
                const currentFileIndex = state.files.findIndex(
                  (item) => item._id === file._id
                )
                if (
                  currentFileIndex >= 0 &&
                  !state.files[currentFileIndex].id
                ) {
                  commit('removeFile', currentFileIndex)
                }

                const currentWaitingIndex = state.waitingFiles.findIndex(
                  (item) => item === file._id
                )
                if (currentWaitingIndex >= 0) {
                  commit('removeWaitingFile', currentWaitingIndex)
                }
                commit('removeUploadRequest', file._id)
                cancelRequest = null
                await dispatch('checkUpload')
              })
            // .catch(Vue.prototype.$errorCatchDefault)
          }

          commit('addUploadRequest', [file._id, request, cancel])
        }
      }

      await dispatch('checkUpload')
    },
    async checkUpload({ state }) {
      if (cancelRequest) return
      for (let key in state.uploadRequests) {
        if (cancelRequest) {
          return
        } else {
          cancelRequest = state.uploadRequests[key].cancel
          await state.uploadRequests[key].request()
        }
      }
    },
    removeFile({ state, commit }, index) {
      const waitingIndex = state.waitingFiles.findIndex(
        (item) => item === state.files[index]._id
      )
      if (waitingIndex >= 0) {
        commit('removeUploadRequest', state.files[index]._id)
        commit('removeWaitingFile', waitingIndex)
      }
      commit('removeFile', index)
    },
    setAll({ commit }, commodity) {
      commit('reset')

      if (commodity) {
        commit('setAll', commodity)

        commit('setProperty', ['created', true])
      }

      commit('setMuted', false)
    },
    setErrors({ commit }, errors) {
      if (!errors) return
      commit('setInputErrors', errors.inputs)
      commit('setInputsAdditionalErrors', errors.inputs.additional_information)
    },
    storageRateCheck({ rootState, commit }) {
      const facilityLocation =
        rootState.commodity.deliveryFacilityLocation.inputs.facility_location
          .value
      const yard =
        rootState.commodity.deliveryFacilityLocation.inputs.yard.value

      if (!facilityLocation || !yard) return

      const commodityMain = get(rootState, 'commodity.main')
      const commodityType = get(commodityMain, 'type')

      const params = {
        commodity_type: commodityType ? commodityType.id : null,
        commodity_packages_type: getPackageType(),
        total_package: getTotalPackages(),

        facility_location: get(facilityLocation, 'id'),
        yard: get(yard, 'id'),
      }

      function getPackageType() {
        if (!commodityType) {
          return (
            get(
              commodityMain.carTypeInputs.commodity_packages_type,
              'value.id'
            ) ||
            get(
              commodityMain.otherTypeInputs.commodity_packages_type,
              'value.id'
            )
          )
        }

        return commodityType.vin_field
          ? get(commodityMain.carTypeInputs.commodity_packages_type, 'value.id')
          : get(
              commodityMain.otherTypeInputs.commodity_packages_type,
              'value.id'
            )
      }

      function getTotalPackages() {
        if (!commodityType) {
          return (
            get(commodityMain.carTypeInputs.total_package, 'value') ||
            get(commodityMain.otherTypeInputs.total_package, 'value') ||
            1
          )
        }

        return commodityType.vin_field
          ? get(commodityMain.carTypeInputs.total_package, 'value') || 1
          : get(commodityMain.otherTypeInputs.total_package, 'value') || 1
      }

      commit('setProperty', ['pendingCheckStorageRates', true])

      return axiosApi
        .get('/storage-rates', {
          params,
        })
        .then(({ data }) => {
          const rate = get(data, 'data[0].attributes')
          if (!rate) {
            commit('setInputAdditionalValue', {
              name: 'free_storage_days',
              value: null,
            })
            commit('setInputAdditionalValue', {
              name: 'storage_per_day',
              value: null,
            })
            return
          }

          commit('setInputAdditionalValue', {
            name: 'free_storage_days',
            value: rate.free_days,
          })
          commit('setInputAdditionalValue', {
            name: 'storage_per_day',
            value: rate.storage_per_day,
          })

          if (moment(rate.end_rate_date.value).isBefore(moment(), 'date')) {
            commit('setProperty', ['rateMessage', 'Rate is expired'])
          } else if (
            moment(rate.start_rate_date.value).isAfter(moment(), 'date')
          ) {
            commit('setProperty', ['rateMessage', 'Rate is not actual yet'])
          } else {
            commit('setProperty', ['rateMessage', null])
          }
        })
        .finally(() => {
          commit('setProperty', ['pendingCheckStorageRates', false])
        })
    },
    getStockNumber({ commit }) {
      commit('setProperty', ['pendingStockNumber', true])

      return axiosApi
        .get('/generate-code?type=commodity-stock')
        .then(({ data }) => {
          commit('setInputAdditionalValue', {
            name: 'stock',
            value: data,
          })
        })
        .finally(() => {
          commit('setProperty', ['pendingStockNumber', false])
        })
    },
    cancel() {
      if (cancelRequest) {
        cancelRequest.source.cancel()
      }
    },
  },
}

function createNote() {
  return {
    _id: counter.value,
    note: null,
    show_in_documents: false,
  }
}
