<template>
  <div v-if="commodity" class="blank-commodity-label">
    <IncludesForBlanks />
    <div class="blank-commodity-label__content">
      <div class="blank-commodity-label__head">
        <img
          :src="companyLogo"
          width="180"
          height="55"
          :alt="companyName"
          class="blank-commodity-label__logo"
        />
        <div style="margin-top: 10px">
          <EditorForDoc
            v-model="inputs.company"
            :fontSize="16"
            style="text-align: center; min-width: 200px"
            class="blank-commodity-label__editor"
          />
        </div>
      </div>

      <div class="blank-commodity-label__main">
        <table class="blank-commodity-label__table">
          <tbody>
            <tr>
              <td colspan="12">
                <div class="blank-commodity-label__cell-title">
                  Stock Number:
                </div>
                <EditorForDoc
                  v-model="inputs.stock_number"
                  :fontSize="120"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Year:</div>
                <EditorForDoc
                  v-model="inputs.year"
                  :fontSize="40"
                  class="blank-commodity-label__editor"
                />
              </td>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Make/Model:</div>
                <EditorForDoc
                  v-model="inputs.make_model"
                  :fontSize="getFontSize(inputs.make_model, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Color:</div>
                <EditorForDoc
                  v-model="inputs.color"
                  :fontSize="40"
                  class="blank-commodity-label__editor"
                />
              </td>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Keys:</div>
                <EditorForDoc
                  v-model="inputs.keys"
                  :fontSize="40"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">
                  Runs ans drives:
                </div>
                <EditorForDoc
                  v-model="inputs.runs_and_drives"
                  :fontSize="40"
                  class="blank-commodity-label__editor"
                />
              </td>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">
                  Check-in date:
                </div>
                <EditorForDoc
                  v-model="inputs.check_in_date"
                  :fontSize="40"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">
                  Commodity condition:
                </div>
                <EditorForDoc
                  v-model="inputs.commodity_condition"
                  :fontSize="getFontSize(inputs.commodity_condition, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">
                  Facility location:
                </div>
                <EditorForDoc
                  v-model="inputs.facility_location"
                  :fontSize="getFontSize(inputs.facility_location, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Area:</div>
                <EditorForDoc
                  v-model="inputs.area"
                  :fontSize="getFontSize(inputs.area, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Lot:</div>
                <EditorForDoc
                  v-model="inputs.lot"
                  :fontSize="getFontSize(inputs.lot, 40, true)"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Lot:</div>
                <EditorForDoc
                  v-model="inputs.lot"
                  :fontSize="getFontSize(inputs.lot, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Place:</div>
                <EditorForDoc
                  v-model="inputs.place"
                  :fontSize="getFontSize(inputs.place, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">Customer:</div>
                <EditorForDoc
                  v-model="inputs.customer"
                  :fontSize="getFontSize(inputs.customer, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
              <td colspan="6">
                <div class="blank-commodity-label__cell-title">
                  Destination:
                </div>
                <EditorForDoc
                  v-model="inputs.destination"
                  :fontSize="getFontSize(inputs.destination, 40)"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="blank-commodity-label__cell-title">Vin:</div>
                <EditorForDoc
                  v-model="inputs.vin"
                  :fontSize="40"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
            <tr>
              <td colspan="12" style="padding-bottom: 10px">
                <div class="blank-commodity-label__cell-title">Notes:</div>
                <EditorForDoc
                  v-model="inputs.notes"
                  :fontSize="20"
                  class="blank-commodity-label__editor"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="commodityNotes.length" class="blank-commodity-label__notes">
          <div class="blank-commodity-label__notes-title">Notes</div>
          <div
            v-for="(note, index) in commodityNotes"
            :key="index"
            class="blank-commodity-label__note"
          >
            {{ note }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { forCommodityLabel as documentMixin } from './common/documentMixin'
import formatDate from '@/helpers/formatDate'
import EditorForDoc from './common/EditorForDoc'
import IncludesForBlanks from '../common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-commodity-label.less'

export default {
  name: 'DocumentLabel',
  mixins: [documentMixin],
  components: {
    IncludesForBlanks,
    EditorForDoc,
  },
  data() {
    return {
      inputs: {
        company: null,
        stock_number: null,
        year: null,
        make_model: null,
        color: null,
        keys: null,
        runs_and_drives: null,
        commodity_condition: null,
        check_in_date: null,
        facility_location: null,
        area: null,
        lot: null,
        place: null,
        customer: null,
        destination: null,
        vin: null,
        notes: null,

        commodityNotes: [],
      },
      inputsCommodities: [],
    }
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
    commodity() {
      return this.commodities[0]
    },
    company() {
      return this.companyInfo
      // return `
      //         <p>International Freight Sevices Co.</p>
      //         <p>(916) 607-0000 </p>
      //         <p>info@uskogo.com</p>
      //         `
    },
    stockNumber() {
      return this.$get(
        this.commodity.delivery_details,
        'inputs.additional_information.stock'
      )
    },

    year() {
      return this.commodity.commodity_item.inputs.year
    },
    makeModel() {
      // return 'Toyota Cdedewddwdwedewd wdamryddwwe'
      return this.commodity.commodity_item.inputs.make_model
    },
    color() {
      return this.$get(
        this.commodity.delivery_details,
        'inputs.additional_information.commodity_color.name'
      )
    },
    keys() {
      const value = this.$get(
        this.commodity.delivery_details,
        'inputs.additional_information.keys'
      )

      if (value === true) return 'Yes'
      if (value === false) return 'No'

      return null
    },
    runsAndDrives() {
      const value = this.$get(
        this.commodity.delivery_details,
        'inputs.additional_information.run_and_drive'
      )

      if (value === true) return 'Yes'
      if (value === false) return 'No'

      return null
    },
    checkInDate() {
      return formatDate(
        this.$get(this.commodity.delivery_details, 'inputs.delivery_date'),
        false
      )
    },
    commodityCondition() {
      return this.$get(
        this.commodity.delivery_details,
        'inputs.conditions.name'
      )
    },
    facilityLocation() {
      return this.$get(
        this.commodity.facility_location,
        'inputs.facility_location.name'
      )
    },
    area() {
      return this.$get(this.commodity.facility_location, 'inputs.yard.name')
    },
    lot() {
      return this.$get(this.commodity.facility_location, 'inputs.lot.name')
    },
    place() {
      return this.$get(this.commodity.facility_location, 'inputs.place.name')
    },
    customer() {
      const customer = this.commodity.customer
      let result = `<p>${customer.first_name || ''} ${
        customer.last_name || ''
      }</p>`
      if (customer.company_name) {
        result = `<p><b>${customer.company_name}</b></p>` + result
      }
      return result
    },
    destination() {
      return this.$get(
        this.commodity.shipping_information,
        'inputs.port_of_discharge.name'
      )
    },
    VIN() {
      return this.commodity.commodity_item.inputs.vin
    },
    commodityNotes() {
      if (!this.commodity.delivery_details) {
        return []
      }

      return this.commodity.delivery_details.notes
        .filter((n) => n.show_in_documents)
        .map((n) => n.note)
    },
  },
  methods: {
    getStyles() {
      return styles
    },
    getFontSize(text, maxSize) {
      let length = (text || '').trim().length
      length = length < 10 ? 10 : length
      let result = 480 / (length - 10)

      if (result < 24) result = 24

      return result > maxSize ? maxSize : Math.round(result)
    },
    createHtmlWrap(text) {
      if (typeof text === 'number') {
        text = text.toString()
      }

      if (!text) return null

      let textTrimmed = text.trim()
      if (textTrimmed && /^(<p>|<div>).*/.test(textTrimmed)) {
        return text
      }

      return `<p>${text}</p>`
    },

    setInputsFromScratch() {
      const createHtmlWrap = this.createHtmlWrap

      this.inputs.company = createHtmlWrap(this.company)
      this.inputs.stock_number = createHtmlWrap(this.stockNumber)
      this.inputs.year = createHtmlWrap(this.year)
      this.inputs.make_model = createHtmlWrap(this.makeModel)
      this.inputs.color = createHtmlWrap(this.color)
      this.inputs.keys = createHtmlWrap(this.keys)
      this.inputs.runs_and_drives = createHtmlWrap(this.runsAndDrives)
      this.inputs.check_in_date = createHtmlWrap(this.checkInDate)
      this.inputs.commodity_condition = createHtmlWrap(this.commodityCondition)
      this.inputs.facility_location = createHtmlWrap(this.facilityLocation)
      this.inputs.area = createHtmlWrap(this.area)
      this.inputs.lot = createHtmlWrap(this.lot)
      this.inputs.place = createHtmlWrap(this.place)
      this.inputs.customer = createHtmlWrap(this.customer)
      this.inputs.destination = createHtmlWrap(this.destination)
      this.inputs.vin = createHtmlWrap(this.VIN)
      this.inputs.notes = ''
      this.inputs.commodityNotes = this.commodityNotes

      this.inputsCommodities = this.commodities.map((commodity) => {
        const value = commodity.commodity_item.inputs.value
        return {
          id: commodity.id,
          year: createHtmlWrap(commodity.commodity_item.inputs.year),
          model: createHtmlWrap(this.getCommodityModel(commodity)),
          vin: createHtmlWrap(commodity.commodity_item.inputs.vin),
          value: createHtmlWrap(value ? '$' + value : null),
        }
      })
    },
    getCommodityModel(commodity) {
      let res = `<p>${commodity.commodity_item.inputs.make_model || ''}</p>`
      const additionalCommoditiesArr = JSON.parse(
        commodity.commodity_item.additional_commodities
      )
      let additionalCommodities = ''
      if (additionalCommoditiesArr && additionalCommoditiesArr.length) {
        additionalCommodities = additionalCommoditiesArr.join(' ')
      }
      return res + additionalCommodities
    },
  },
  created() {
    this.setInputsFromScratch()
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css-blanks/blank-commodity-label';

.blank-commodity-label__editor .ProseMirror {
  background: #e4eefb;
}
</style>
