import { axiosApi } from '@/axios'
import capitalize from '@/helpers/capitalize'

export default {
  namespaced: true,
  state: {
    pendingCountries: false,
    loadedCountries: false,
    countries: [],
    pendingStates: false,
    loadedStates: false,
    states: [],
  },
  mutations: {
    setPendingCountries(state, value) {
      state.pendingCountries = value
    },
    setLoadedCountries(state, value) {
      state.loadedCountries = value
    },
    setCountries(state, items) {
      state.countries = items
    },
    setPendingState(state, value) {
      state.pendingStates = value
    },
    setLoadedState(state, value) {
      state.loadedStates = value
    },
    setState(state, items) {
      state.states = items
    },
  },
  actions: {
    loadCountries({ state, commit }) {
      if (state.pendingCountries || state.loadedCountries) {
        return Promise.resolve('ok')
      }
      commit('setPendingCountries', true)

      return axiosApi
        .get('/settings/countries')
        .then(({ data }) => {
          const countries = data.map((country) => country.attributes)
          commit('setCountries', countries)
        })
        .finally(() => {
          commit('setPendingCountries', false)
          commit('setLoadedCountries', true)
        })
    },
    loadStates({ state, commit }) {
      if (state.pendingStates || state.loadedStates) {
        return Promise.resolve('ok')
      }
      commit('setPendingState', true)

      return axiosApi
        .get('/states')
        .then(({ data }) => {
          const states = data.item
          commit('setState', states)
        })
        .finally(() => {
          commit('setPendingState', false)
          commit('setLoadedState', true)
        })
    },
  },
  getters: {
    getStateShortByName(state) {
      return (name) => {
        if (!name) return ''

        name = name.trim()
        name = capitalize(name)
        return state.states[capitalize(name)] || ''
      }
    },
  },
}
