import shippingRates from './shippingRates'
import storageRates from './storageRates'
import commodityDeliveryRates from './commodityDeliveryRates'

export default {
  namespaced: true,
  modules: {
    shippingRates,
    storageRates,
    commodityDeliveryRates,
  },
}
