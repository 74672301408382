export const STATUS_QUERY_NAME = 'status'
export const STATUS_DELETED = 'deleted'
export const STATUS_ACTIVE = 'active'
export const STATUS_INACTIVE = 'inactive'
export const STATUS_ACTIVE_AND_INACTIVE = 'active-and-inactive'
export const NO_PERSON = 'no-person'
export const PAGE_QUERY_NAME = 'page'
export const SORT_QUERY_NAME = 'sort'
export const SEARCH_QUERY_NAME = 'search'
export const MAIN_ACCOUNTS = 'main_accounts'
export const PER_PAGE = 20
