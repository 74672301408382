import Vue from 'vue'
import { axiosApi } from '@/axios'
import { get } from 'lodash'
import { ATTACHMENT_KEY_SHIPPING_FILE_DOCUMENTS } from '@/constants/mail'

function getState() {
  return {
    pending: false,
    templates: [],
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    addToMail({ commit, dispatch }, payload) {
      commit('setProperty', ['pending', true])

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const emailPlaceholders = {
          CompanyName: get(
            payload,
            'options.commodities[0].customer.company_name',
            null
          ),
        }

        let templates = await axiosApi.get(
          '/settings/email_templates/shipping_files_documents/'
        )
        templates = get(templates, 'data.data', [])

        if (templates.length) {
          templates.forEach((item) => {
            for (let key in emailPlaceholders) {
              if (emailPlaceholders[key]) {
                const regex = new RegExp('{{' + key + '}}', 'gi')
                item.attributes.subject = item.attributes.subject.replace(
                  regex,
                  emailPlaceholders[key]
                )
                item.attributes.message = item.attributes.message.replace(
                  regex,
                  emailPlaceholders[key]
                )
              }
            }
          })

          commit('setProperty', ['templates', templates])
        }

        const customerEmail = get(
          payload,
          'options.commodities[0].customer.emails[0].value',
          null
        )

        const attachments = JSON.parse(
          JSON.stringify(payload.documentsSelected)
        ).map((item) => {
          item.name = `${item.type.name || ''} - ${item.name || ''}`

          return {
            id: item.id,
            name: item.name,
            attachment: item,
          }
        })

        await dispatch(
          'mail/addToMail',
          {
            sendTo: [customerEmail],
            templates,
            attachments: {
              [ATTACHMENT_KEY_SHIPPING_FILE_DOCUMENTS]: attachments,
            },
          },
          { root: true }
        )

        resolve()
      }).finally(() => {
        commit('setProperty', ['pending', false])
      })
    },
  },
}
