import { ABC } from '@/constants/abc'
import stylesFromFile from '!!raw-loader!less-loader!@/assets/css-file-doc/file-doc-global-spec.less'
import moment from '@/helpers/useMoment'

function getBaseMixin() {
  return {
    props: {
      options: Object,
      fields: Object,
    },

    computed: {
      currentDate() {
        return this.moment()
      },
      month() {
        return this.currentDate.month() + 1
      },
      day() {
        return this.currentDate.date()
      },
      year() {
        return this.currentDate.year()
      },
    },
    methods: {
      moment,
      createName() {
        let name = ''

        const commodityWithConsignee = this.commodities.find(
          (item) => item.shipping_information.inputs.consignee
        )
        const consignee = commodityWithConsignee
          ? commodityWithConsignee.shipping_information.inputs.consignee
          : null
        const consigneeFullName = consignee
          ? (consignee.first_name || '') + ' ' + (consignee.last_name || '')
          : ''

        name = `${consigneeFullName.trim()} ${this.documentNumber}`.trim()

        this.$emit('create-name', name)
      },
      getStyles() {
        return stylesFromFile
      },
      getFieldsSave() {
        const inputs = this.inputs
        const result = {}
        for (const key in inputs) {
          result[key] = inputs[key]
        }

        return {
          ...result,
          commodities: this.inputsCommodities,
        }
      },
      createHtmlWrap(text) {
        if (typeof text === 'number') {
          text = text.toString()
        }

        if (!text) return null

        let textTrimmed = text.trim()
        if (textTrimmed && /^(<p>|<div>).*/.test(textTrimmed)) {
          return text
        }

        return `<p>${text}</p>`
      },
      getCommodityCellStyleByIndex(index) {
        return index < this.inputsCommodities.length - 1
          ? 'border-bottom: 0;border-top: 0; height:10px; padding-bottom: 12px'
          : 'border-top: 0;'
      },

      getCommodityTotalPackages(commodity) {
        return commodity.commodity_item.inputs.total_package || 1
      },
      getCommodityMainInfo(commodity) {
        const additionalCommoditiesArr = JSON.parse(
          commodity.commodity_item.additional_commodities
        )

        let additionalCommodities = ''
        let res = ''
        if (additionalCommoditiesArr && additionalCommoditiesArr.length) {
          additionalCommodities = additionalCommoditiesArr.join(' ')
        }

        const year = commodity.commodity_item.inputs.year
        const model = commodity.commodity_item.inputs.make_model
        const vin = commodity.commodity_item.inputs.vin

        const row1 =
          year || model ? `<p>${year ? year + ' ' : ''}${model || ''}</p>` : ''
        const row2 = `<p>VIN/SN: ${vin || '-'}</p>`

        if (commodity.type && commodity.type.vin_field) {
          res = row1 + row2
        } else if (commodity.type) {
          res = `<p>${commodity.commodity_item.inputs.descr || ''}</p>`
        } else {
          // in an uncommon situation
          if (commodity.commodity_item.inputs.descr) {
            res = `<p>${commodity.commodity_item.inputs.descr || ''}</p>`
          } else {
            res = row1 + row2
          }
        }

        return res + additionalCommodities
      },
      parseInputs() {
        const fields = this.fields
        const inputs = this.inputs

        for (const key in inputs) {
          this.$set(inputs, key, fields[key])
        }

        if (fields.commodities && fields.commodities.length) {
          this.inputsCommodities = JSON.parse(
            JSON.stringify(fields.commodities)
          )
        } else {
          this.inputsCommodities = []
        }
      },
    },
    created() {
      if (this.fields) {
        this.parseInputs()
      } else {
        this.setInputsFromScratch()
      }

      this.createName()
    },
  }
}

// - - - -

const forCustomer = {
  data(vm) {
    return {
      documentType: vm.options.documentType,
      booking: clone(vm.options.shippingFile.booking),
      commodities: clone(vm.options.commodities),
      container: clone(vm.options.container),
      trucking: clone(vm.options.trucking),
    }
  },
  ...getBaseMixin(),
}

forCustomer.computed.documentNumber = function () {
  const commoditiesLetters = (
    ABC[this.commodities[0].index] || ''
  ).toUpperCase()

  return `${this.options.shippingFile.number}${commoditiesLetters}`
}

// - - - -

const forFile = {
  data(vm) {
    const booking = vm.options.shippingFile.booking
    const containers = booking.trucking_information.reduce(
      (result, trucking) => {
        return result.concat(trucking.containers)
      },
      []
    )

    const commodities = containers.reduce((result, container) => {
      container.items.forEach((item) => {
        result = result.concat(item.commodities)
      })

      return result
    }, [])

    return {
      documentType: vm.options.documentType,
      booking: clone(booking),
      containers: clone(containers),
      commodities: clone(commodities),
    }
  },
  ...getBaseMixin(),
}

forFile.computed.documentNumber = function () {
  return `${this.options.shippingFile.number}`
}

// - - - -

const forCommodityLabel = {
  data(vm) {
    return {
      documentType: vm.options.documentType,
      commodities: clone(vm.options.commodities),
    }
  },
  ...getBaseMixin(),
}

forCommodityLabel.computed.documentNumber = function () {
  return `${this.options.commodities[0].id}`
}

// - - - -

function clone(item) {
  if (!item) return null

  return JSON.parse(JSON.stringify(item))
}

export { forCustomer, forFile, forCommodityLabel }
