import moment from '@/helpers/useMoment'
import { TIME_RANGE_TYPES } from '@/constants/calendar'

export default (route) => {
  let year = +route.query[TIME_RANGE_TYPES.YEAR]
  let month = route.query[TIME_RANGE_TYPES.MONTH] - 1
  let date = +route.query[TIME_RANGE_TYPES.DAY]

  if (isNaN(month) || month < 0 || month > 11) {
    month = null
  }

  if (isNaN(month)) {
    year = null
  }

  if (isNaN(month)) {
    date = null
  }

  return moment({
    year,
    month,
    date,
  })
}
