import createBlanksByModels from './createBlanksByModels'
import BVendor from '@/components/Blanks/BVendor'
import { axiosApi } from '@/axios'

function loadModel(id) {
  return axiosApi.get(`/vendors/${id}`)
}

export default function (models) {
  return createBlanksByModels({
    models,
    loadModelsMethod: loadModel,
    blankComponent: BVendor,
    blankComponentPropKey: 'person',
  })
}
