const Vendors = () =>
  import(/*webpackChunkName: "Vendors" */ '@/views/Main/Vendors')
const OneVendor = () =>
  import(/*webpackChunkName: "OneVendor" */ '@/views/Main/Vendors/OneVendor')
const VendorOverview = () =>
  import(
    /*webpackChunkName: "VendorOverview" */ '@/views/Main/Vendors/OneVendor/VendorTabs/VendorOverview'
  )
const VendorNotes = () =>
  import(
    /*webpackChunkName: "VendorNotes" */ '@/views/Main/Vendors/OneVendor/VendorTabs/VendorNotes'
  )
const VendorExpenses = () =>
  import(
    /*webpackChunkName: "VendorExpenses" */ '@/views/Main/Vendors/OneVendor/VendorTabs/VendorExpenses'
  )
const VendorEmails = () =>
  import(
    /*webpackChunkName: "VendorEmails" */ '@/views/Main/Vendors/OneVendor/VendorTabs/VendorEmails'
  )
const VendorMessages = () =>
  import(
    /*webpackChunkName: "VendorMessages" */ '@/views/Main/Vendors/OneVendor/VendorTabs/VendorMessages'
  )
const VendorCalls = () =>
  import(
    /*webpackChunkName: "VendorCalls" */ '@/views/Main/Vendors/OneVendor/VendorTabs/VendorCalls'
  )
const VendorLogs = () =>
  import(
    /*webpackChunkName: "VendorLogs" */ '@/views/Main/Vendors/OneVendor/VendorTabs/VendorLogs'
  )

import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

export default [
  {
    path: 'vendors',
    name: 'vendors',
    component: Vendors,
    meta: {
      permission: [abilityActions.read, abilitySubjects.VENDORS],
      title: 'Vendors',
    },
    children: [
      // {
      //   path: NO_PERSON,
      //   redirect: {name: 'one-vendor-E404'}
      // },
      {
        path: ':vendorId',
        name: 'one-vendor',
        component: OneVendor,
        redirect: { name: 'vendor-overview' },
        meta: {
          permission: [abilityActions.read, abilitySubjects.VENDORS],
        },
        children: [
          {
            name: 'vendor-overview',
            path: 'overview',
            component: VendorOverview,
            meta: {
              permission: [abilityActions.read, abilitySubjects.VENDORS],
            },
          },
          {
            name: 'vendor-notes',
            path: 'notes',
            component: VendorNotes,
            meta: {
              permission: [abilityActions.read, abilitySubjects.VENDORS],
            },
          },
          {
            name: 'vendor-expenses',
            path: 'expenses',
            component: VendorExpenses,
            meta: {
              permission: [abilityActions.read, abilitySubjects.VENDORS],
            },
          },
          {
            name: 'vendor-emails',
            path: 'emails',
            component: VendorEmails,
            meta: {
              permission: [abilityActions.read, abilitySubjects.VENDORS],
            },
          },
          {
            name: 'vendor-messages',
            path: 'messages',
            component: VendorMessages,
            meta: {
              permission: [abilityActions.read, abilitySubjects.VENDORS],
            },
          },
          {
            name: 'vendor-calls',
            path: 'calls',
            component: VendorCalls,
            meta: {
              permission: [abilityActions.read, abilitySubjects.VENDORS],
            },
          },
          {
            name: 'vendor-logs',
            path: 'logs',
            component: VendorLogs,
            meta: {
              permission: [abilityActions.read, abilitySubjects.VENDORS],
            },
          },
        ],
      },
    ],
  },
]
