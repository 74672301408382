import Vue from 'vue'
import pdf from './pdf'
import print from './print'
import email from './email'

function getState() {
  return {}
}

export default {
  namespaced: true,
  modules: {
    pdf,
    print,
    email,
  },
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {},
}
