export default function concatPath(...args) {
  return args.reduce((path, currentPath) => {
    currentPath = currentPath.toString()

    if (path.length) {
      if (!path.endsWith('/')) {
        path += '/'
      }

      if (currentPath.startsWith('/')) {
        currentPath = currentPath.slice(1)
      }
    }

    return path + currentPath
  }, '')
}
