export const ATTACHMENT_KEY_SHIPPING_FILE = 'shipping_file'
export const ATTACHMENT_KEY_SHIPPING_FILE_DOCUMENTS = 'shipping_file_documents'
export const ATTACHMENT_KEY_SHIPPING_FILE_PICTURES = 'shipping_file_pictures'
export const ATTACHMENT_KEY_SHIPPING_FILE_ATTACHMENTS =
  'shipping_file_attachments'
export const ATTACHMENT_KEY_COMMODITY_DOCUMENTS = 'commodity_documents'
export const ATTACHMENT_KEY_COMMODITY_FILES = 'commodity_files'
export const ATTACHMENT_KEY_CUSTOMERS = 'customers'
export const ATTACHMENT_KEY_VENDORS = 'vendors'
export const ATTACHMENT_KEY_ESTIMATES = 'estimates'
export const ATTACHMENT_KEY_INVOICES = 'invoices'
export const ATTACHMENT_KEY_PAYMENTS_RECEIVED = 'payments_received'
export const ATTACHMENT_KEY_DEPOSITS = 'deposits'
export const ATTACHMENT_KEY_BOOKING = 'booking'
export const ATTACHMENT_KEY_REPORTS = 'reports'
export const ATTACHMENT_KEY_PROJECTS = 'projects'
export const ATTACHMENT_KEY_CUSTOMER_PROFILE = 'customer_profile'
export const ATTACHMENT_KEY_VENDOR_PROFILE = 'vendor_profile'

export const ATTACHMENT_TYPE_FORM_DATA = 'form_data'

export const PLACEHOLDERS_ESTIMATES = {
  Customer: [
    {
      name: 'Company Name',
      value: 'CompanyName',
    },
  ],
  Estimate: [
    {
      name: 'Total',
      value: 'Total',
    },
    {
      name: 'Estimate Date',
      value: 'EstimateDate',
    },
    {
      name: 'Estimate Number',
      value: 'EstimateNumber',
    },
  ],
}
export const PLACEHOLDERS_INVOICES = {
  Customer: [
    {
      name: 'Company Name',
      value: 'CompanyName',
    },
  ],
  Invoice: [
    {
      name: 'Total',
      value: 'Total',
    },
    {
      name: 'Invoice Date',
      value: 'InvoiceDate',
    },
    {
      name: 'Invoice Number',
      value: 'InvoiceNumber',
    },
  ],
}
export const PLACEHOLDERS_REPORTS = {
  Report: [
    {
      name: 'Report Name',
      value: 'ReportName',
    },
    {
      name: 'Report Dates',
      value: 'ReportDates',
    },
  ],
}
export const PLACEHOLDERS_PROJECTS = {
  Customer: [
    {
      name: 'Company Name',
      value: 'CompanyName',
    },
  ],
  Project: [
    {
      name: 'Project Name',
      value: 'ProjectName',
    },
    {
      name: 'Project Number',
      value: 'ProjectNumber',
    },
    {
      name: 'Start Date',
      value: 'StartDate',
    },
    {
      name: 'Estimate end date',
      value: 'EstimateEndDate',
    },
  ],
}
export const PLACEHOLDERS_CUSTOMERS = {
  Customer: [
    {
      name: 'Company Name',
      value: 'CompanyName',
    },
  ],
}
export const PLACEHOLDERS_DOCUMENTS = {
  Customer: [
    {
      name: 'Company Name',
      value: 'CompanyName',
    },
  ],
}
export const PLACEHOLDERS_BOOKINGS = {
  Customer: [
    {
      name: 'Company Name',
      value: 'CompanyName',
    },
  ],
}
export const PLACEHOLDERS_COMMODITY_FILES = {
  Customer: [
    {
      name: 'Company Name',
      value: 'CompanyName',
    },
  ],
}
