export default {
  methods: {
    loadSwiperMixin() {
      if (window.Swiper) return Promise.resolve()

      return import(
        /* webpackChunkName: "swiper" */
        'swiper/bundle'
      ).then((data) => {
        window.Swiper = data.default
      })
    },
  },
}
