import Vue from 'vue'
import { axiosApi } from '@/axios'
import { get } from 'lodash'
import { ATTACHMENT_KEY_PROJECTS } from '@/constants/mail'
import moment from '@/helpers/useMoment'

function getState() {
  return {
    pending: false,
    templates: [],
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    addToMail({ commit, dispatch }, { selectedFiles, project }) {
      commit('setProperty', ['pending', true])

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const emailPlaceholders = {
          CompanyName: get(project, 'customer.company_name', null),
          ProjectName: project?.project_name ?? null,
          ProjectNumber: project?.project_number ?? null,
          StartDate: project?.start_date?.value
            ? moment(project.start_date.value).format('DD MMM YYYY')
            : null,
          EstimateEndDate: project?.end_date?.value
            ? moment(project.end_date.value).format('DD MMM YYYY')
            : null,
        }

        const templates = []

        await axiosApi
          .get('/settings/email_templates/projects/')
          .then(({ data }) => {
            templates.push(...get(data, 'data', []))
          })
          .catch(() => {})

        if (templates.length) {
          templates.forEach((item) => {
            for (let key in emailPlaceholders) {
              if (emailPlaceholders[key]) {
                const regex = new RegExp('{{' + key + '}}', 'gi')
                item.attributes.subject = item.attributes.subject.replace(
                  regex,
                  emailPlaceholders[key]
                )
                item.attributes.message = item.attributes.message.replace(
                  regex,
                  emailPlaceholders[key]
                )
              }
            }
          })

          commit('setProperty', ['templates', templates])
        }

        const customerEmail = get(project, 'customer.emails[0].value', null)

        const attachments = JSON.parse(JSON.stringify(selectedFiles)).map(
          (item) => {
            return {
              id: item.id,
              name: `Project${
                project?.project_number ? ' #' + project.project_number : ''
              } - ${item.file_original_client_name || ''}`,
              attachment: item,
            }
          }
        )

        await dispatch(
          'mail/addToMail',
          {
            sendTo: [customerEmail],
            templates,
            attachments: {
              [ATTACHMENT_KEY_PROJECTS]: attachments,
            },
          },
          { root: true }
        )

        resolve()
      }).finally(() => {
        commit('setProperty', ['pending', false])
      })
    },
  },
}
