import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

const BankingPaymentAccounts = () =>
  import(
    /* webpackChunkName: "BankingPaymentAccounts" */ '@/views/Main/Banking/BankingPaymentAccounts'
  )
const BankingPaymentAccount = () =>
  import(
    /* webpackChunkName: "BankingPaymentAccount" */ '@/views/Main/Banking/BankingPaymentAccount'
  )
const BankingPaymentAccountOverview = () =>
  import(
    /* webpackChunkName: "BankingPaymentAccountOverview" */ '@/views/Main/Banking/BankingPaymentAccount/Tabs/BankingPaymentAccountOverview'
  )
const BankingPaymentAccountNotes = () =>
  import(
    /* webpackChunkName: "BankingPaymentAccountNotes" */ '@/views/Main/Banking/BankingPaymentAccount/Tabs/BankingPaymentAccountNotes'
  )
const BankingPaymentAccountReconcile = () =>
  import(
    /* webpackChunkName: "BankingPaymentAccountReconcile" */ '@/views/Main/Banking/BankingPaymentAccount/Tabs/BankingPaymentAccountReconcile'
  )
const BankingPaymentAccountReconcileItem = () =>
  import(
    /* webpackChunkName: "BankingPaymentAccountReconcileItem" */ '@/views/Main/Banking/BankingPaymentAccount/Tabs/BankingPaymentAccountReconcileItem'
  )

export default [
  {
    path: 'banking',
    name: 'banking',
    redirect: {
      name: 'banking-payment-accounts',
    },
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
    },
  },
  {
    path: 'banking/payment-accounts',
    name: 'banking-payment-accounts',
    component: BankingPaymentAccounts,
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
      title: 'Banking Payment Accounts',
    },
    children: [
      {
        path: ':paymentAccountId',
        name: 'banking-payment-account',
        component: BankingPaymentAccount,
        redirect: { name: 'banking-payment-account-overview' },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SALES],
        },
        children: [
          {
            path: 'overview',
            name: 'banking-payment-account-overview',
            component: BankingPaymentAccountOverview,
            meta: {
              permission: [abilityActions.read, abilitySubjects.SALES],
            },
          },
          {
            path: 'notes',
            name: 'banking-payment-account-notes',
            component: BankingPaymentAccountNotes,
            meta: {
              permission: [abilityActions.read, abilitySubjects.SALES],
            },
          },
          {
            path: 'reconcile',
            name: 'banking-payment-account-reconcile',
            component: BankingPaymentAccountReconcile,
            meta: {
              permission: [abilityActions.read, abilitySubjects.SALES],
            },
          },
          {
            path: 'reconcile/:reconcileId',
            name: 'banking-payment-account-reconcile-item',
            component: BankingPaymentAccountReconcileItem,
            meta: {
              permission: [abilityActions.read, abilitySubjects.SALES],
            },
          },
        ],
      },
    ],
  },
]
