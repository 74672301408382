import Vue from 'vue'

Vue.filter('hrefToLink', function (value) {
  if (!value) return ''
  if (value.search(/http(s)?:\/\//i) === -1) {
    return '//' + value
  } else {
    return value
  }
})
