import moment from '@/helpers/useMoment'
import getSelectedDateFromRoute from './getSelectedDateFromRoute'
import isCorrectTimeRangeType from './isCorrectTimeRangeType'
import { TIME_RANGE_TYPES } from '@/constants/calendar'

export default function getRangeFromRoute(route, type) {
  if (!route) return undefined
  if (!type) {
    type = route.params.rangeType
  }

  if (!isCorrectTimeRangeType(type)) return undefined

  const now = getSelectedDateFromRoute(route)
  let currentTimeMin = ''
  let currentTimeMax = ''
  let timeMin = ''
  let timeMax = ''
  if (type === TIME_RANGE_TYPES.MONTH) {
    currentTimeMin = moment(now).startOf(type).startOf('week').toISOString()
    currentTimeMax = moment(now).endOf(type).endOf('week').toISOString()
    timeMin = moment(now)
      .startOf(type)
      .subtract(2, type)
      .startOf(type)
      .startOf('week')
      .toISOString()
    timeMax = moment(now)
      .startOf(type)
      .add(2, type)
      .endOf(type)
      .endOf('week')
      .toISOString()
  } else {
    currentTimeMin = moment(now).startOf(type).toISOString()
    currentTimeMax = moment(now).endOf(type).toISOString()
    timeMin = moment(now)
      .startOf(type)
      .subtract(4, type)
      .startOf(type)
      .toISOString()
    timeMax = moment(now).startOf(type).add(4, type).endOf(type).toISOString()
  }

  return {
    currentTimeMin,
    currentTimeMax,
    timeMin,
    timeMax,
    type,
  }
}
