import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

const Account = () =>
  import(/*webpackChunkName: "Account" */ '@/views/Main/Account')
const AccountProfile = () =>
  import(
    /*webpackChunkName: "AccountProfile" */ '@/views/Main/Account/AccountProfile'
  )
const AccountSubscribes = () =>
  import(
    /*webpackChunkName: "AccountSubscribes" */ '@/views/Main/Account/AccountSubscribes'
  )

export default [
  {
    path: 'account',
    component: Account,
    name: 'account',
    redirect: {
      name: 'account-profile',
    },
    meta: {
      permission: [abilityActions.read, abilitySubjects.ACCOUNT],
      title: 'Account',
    },
    children: [
      {
        path: 'profile',
        name: 'account-profile',
        component: AccountProfile,
        meta: {
          permission: [abilityActions.read, abilitySubjects.ACCOUNT],
        },
      },
      {
        path: 'subscribe',
        name: 'account-subscribe',
        component: AccountSubscribes,
        meta: {
          permission: [abilityActions.read, abilitySubjects.ACCOUNT],
        },
      },
    ],
  },
]
