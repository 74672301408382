const ShippingRates = () =>
  import(
    /*webpackChunkName: "ShippingRates" */ '@/views/Main/Rates/ShippingRates'
  )
const CommodityDeliveryRates = () =>
  import(
    /*webpackChunkName: "CommodityDeliveryRates" */ '@/views/Main/Rates/CommodityDeliveryRates'
  )
const StorageRates = () =>
  import(
    /*webpackChunkName: "StorageRates" */ '@/views/Main/Rates/StorageRates'
  )
import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

export default [
  {
    path: 'rates/shipping-rates',
    name: 'shipping-rates',
    component: ShippingRates,
    meta: {
      permission: [abilityActions.read, abilitySubjects.RATES],
      title: 'Shipping Rates',
    },
  },
  {
    path: 'rates/commodity-delivery-rates',
    name: 'commodity-delivery-rates',
    component: CommodityDeliveryRates,
    meta: {
      permission: [abilityActions.read, abilitySubjects.RATES],
      title: 'Commodity Delivery Rates',
    },
  },
  {
    path: 'rates/storage-rates',
    name: 'storage-rates',
    component: StorageRates,
    meta: {
      permission: [abilityActions.read, abilitySubjects.RATES],
      title: 'Storage Rates',
    },
  },
  {
    path: 'rates',
    name: 'rates',
    redirect: {
      name: 'commodity-delivery-rates',
    },
    meta: {
      permission: [abilityActions.read, abilitySubjects.RATES],
    },
  },
]
