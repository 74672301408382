export default {
  install(Vue) {
    if (Vue.prototype.$toaster) {
      return
    }
    Vue.prototype.$toaster = (options) => {
      const { text = '', type = null, list = [], duration = 5000 } = options

      if (!text) return

      let wrap = document.querySelector('.toaster')
      let itemEl = document.createElement('div')
      let ico_name = 'ico-exclamation-mark'
      let typeSelector = '_general'
      let closeTimer = null
      let start = 0
      let remaining = duration
      const controller1 = new AbortController()
      const controller2 = new AbortController()

      if (!wrap) {
        wrap = document.createElement('div')
        wrap.classList.add('toaster')
        document.body.appendChild(wrap)
      }

      switch (type) {
        case 'ERROR':
          typeSelector = '_error'
          break
        case 'SUCCESS':
          typeSelector = '_success'
          ico_name = 'ico-tick'
          break
        default:
          break
      }

      let listTemplate = ''
      if (list.length) {
        listTemplate = document.createElement('ul')
        for (const item of list) {
          const li = document.createElement('li')
          li.innerHTML = item
          listTemplate.appendChild(li)
        }
        listTemplate = listTemplate.outerHTML
      }

      itemEl.innerHTML = `
        <div class="toaster__item ${typeSelector}">
          <div class="toaster__item-text">${text}</div>
          <div class="toaster__item-ico">
            <svg xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="${require('@/assets/img/svg/sprite-main.svg')}#${ico_name}"></use>
            </svg>
          </div>
          ${listTemplate}
        </div>
      `

      itemEl = itemEl.firstElementChild
      itemEl.addEventListener('click', onCloseClick)
      wrap.addEventListener('mouseenter', onPause, {
        signal: controller1.signal,
      })
      wrap.addEventListener('mouseleave', onResume, {
        signal: controller2.signal,
      })

      wrap.appendChild(itemEl)

      function onPause() {
        if (duration > 0) {
          clearTimeout(closeTimer)
          closeTimer = null
          remaining -= Date.now() - start
          if (remaining < 1000) {
            remaining = 1000
          }
        }
      }

      function onResume() {
        if (closeTimer) {
          return
        }
        if (duration > 0) {
          start = Date.now()
          closeTimer = setTimeout(close, remaining)
        }
      }

      //
      setTimeout(open, 33)

      function open() {
        itemEl.classList.add('_active')
        if (duration > 0) {
          onResume()
        }
      }

      function close() {
        if (!itemEl) return
        itemEl.addEventListener('transitionend', onTransitionEnd, false)
        itemEl.classList.remove('_active')
      }

      function onCloseClick(e) {
        if (e.target.closest('.toaster__item')) {
          close()
        }
      }

      function onTransitionEnd(e) {
        if (e.propertyName !== 'opacity' || e.target !== itemEl) return
        itemEl.removeEventListener('click', onCloseClick)
        itemEl.removeEventListener('transitionend', onTransitionEnd, false)
        controller1.abort()
        controller2.abort()
        itemEl.remove()
        itemEl = null
        if (!wrap.querySelector('.toaster__item')) {
          wrap.remove()
          wrap = null
        }
      }
    }
  },
}
