// import concatPath from '../helpers/concatPath'
import { axiosApi } from '@/axios'
// import Vue from 'vue'

export default function download(link, name) {
  // let url = originUrl ? link : concatPath(baseURL, 'api/download?link=') + link
  let url = link

  if (url.startsWith('/api') || url.startsWith('api')) {
    url = url.replace(/^\/?api\//, '/')
  }
  return axiosApi
    .get(url, {
      responseType: 'blob', // important
    })
    .then((response) => {
      forceFileDownload(response, name)
      return true
    })
}

function forceFileDownload(response, name) {
  const url = URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url

  if (!name) {
    let downloadUrl = new URL(response.config.url)
    name = downloadUrl.search.slice(6)
  }

  link.setAttribute('download', name) //or any other extension
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    link.remove()
    URL.revokeObjectURL(url)
  }, 33)
}
