import Vue from 'vue'
import Counter from '@/helpers/Counter'

const counter = new Counter()

function initialContainer() {
  return {
    id: {
      value: null,
    },
    container_number: {
      value: null,
      prevValue: null,
    },
    seal_number: {
      value: null,
    },
    container_type: {
      value: null,
    },
    soc: {
      value: false,
    },
    containerized: {
      value: false,
    },
    show_reuse: {
      value: false,
    },
    reuse: {
      value: false,
    },
  }
}

function initialTruckingInputs() {
  return {
    trucking_company: {
      value: null,
    },
    empty_container_pick_up_location: {
      value: null,
    },
    empty_container_drop_location: {
      value: null,
    },
    loaded_container_pick_up_location: {
      value: null,
    },
    loaded_container_drop_location: {
      value: null,
    },
    instructions: {
      value: null,
      enabled: false,
    },
  }
}

function initialDatesInputs() {
  return {
    empty_container_pick_up_date: {
      value: null,
    },
    empty_container_drop_date: {
      value: null,
    },
    loaded_container_pick_up_date: {
      value: null,
    },
    loaded_container_drop_date: {
      value: null,
    },
  }
}

export default {
  namespaced: true,
  state: {
    isMuted: false,
    truckingList: [createTruckingList()],
  },
  getters: {
    truckingDatesInputsKeys() {
      return Object.keys(initialDatesInputs())
    },
  },
  mutations: {
    setMuted(state, data = true) {
      state.isMuted = data
    },
    reset(state) {
      state.truckingList = [createTruckingList()]
    },
    setAll(state, truckingList) {
      state.truckingList = truckingList.map((trucking) => {
        const inputs = initialTruckingInputs()

        for (let key in inputs) {
          inputs[key].value = trucking.inputs[key]
        }

        if (inputs.instructions.value) {
          inputs.instructions.enabled = true
        }

        const dates = initialDatesInputs()

        for (let key in dates) {
          dates[key].value = trucking.inputs.dates[key] || null
        }

        const containers = trucking.containers.map((container) => {
          const newContainerInputs = initialContainer()

          for (let key in newContainerInputs) {
            newContainerInputs[key].value = container[key]
          }

          newContainerInputs['container_number'].prevValue =
            newContainerInputs['container_number'].value

          return createContainer(newContainerInputs)
        })

        if (!containers.length) {
          containers.push(initialContainer())
        }

        return createTruckingList(trucking.id, inputs, containers, dates)
      })
    },
    setInputValue(state, { truckingIndex, name, value }) {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.truckingList[truckingIndex].inputs,
          name
        )
      )
        return
      state.truckingList[truckingIndex].inputs[name].value = value || null
    },
    setInputsErrorMultiple(state, errors) {
      for (const { truckingIndex, name, error } of errors) {
        if (
          !Object.prototype.hasOwnProperty.call(
            state.truckingList[truckingIndex].inputs,
            name
          )
        )
          return
        Vue.set(
          state.truckingList[truckingIndex].inputs[name],
          'error',
          error || null
        )
      }
    },
    setDatesValue(state, { truckingIndex, name, value }) {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.truckingList[truckingIndex].dates,
          name
        )
      )
        return
      state.truckingList[truckingIndex].dates[name].value = value || null
    },
    setDatesErrorMultiple(state, errors) {
      for (const { truckingIndex, name, error } of errors) {
        if (
          !Object.prototype.hasOwnProperty.call(
            state.truckingList[truckingIndex].dates,
            name
          )
        )
          return
        Vue.set(
          state.truckingList[truckingIndex].dates[name],
          'error',
          error || null
        )
      }
    },
    setContainerValue(state, { truckingIndex, containerIndex, name, value }) {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.truckingList[truckingIndex].containers[containerIndex],
          name
        )
      )
        return
      state.truckingList[truckingIndex].containers[containerIndex][name].value =
        value || null
    },
    setReuseValue(state, { truckingIndex, containerIndex, name, value }) {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.truckingList[truckingIndex].containers[containerIndex],
          name
        )
      )
        return
      state.truckingList[truckingIndex].containers[containerIndex][name].value =
        value
    },
    setShowReuseValue(state, { truckingIndex, containerIndex, value }) {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.truckingList[truckingIndex].containers[containerIndex],
          'show_reuse'
        )
      )
        return
      Vue.set(
        state.truckingList[truckingIndex].containers[containerIndex].show_reuse,
        'value',
        value
      )
    },
    setContainerErrorMultiple(state, errors) {
      for (const { truckingIndex, containerIndex, name, error } of errors) {
        if (
          !Object.prototype.hasOwnProperty.call(
            state.truckingList[truckingIndex].containers[containerIndex],
            name
          )
        )
          return
        Vue.set(
          state.truckingList[truckingIndex].containers[containerIndex][name],
          'error',
          error || null
        )
      }
    },
    addTrucking(state) {
      state.truckingList.push(createTruckingList())
    },
    removeTrucking(state, index) {
      state.truckingList.splice(index, 1)
    },
    addContainer(state, truckingIndex) {
      state.truckingList[truckingIndex].containers.push(createContainer())
    },
    removeContainer(state, { truckingIndex, containerIndex }) {
      state.truckingList[truckingIndex].containers.splice(containerIndex, 1)
    },
    toggleInstructions(state, index) {
      if (state.truckingList[index].inputs.instructions.enabled) {
        state.truckingList[index].inputs.instructions.enabled = false
        state.truckingList[index].inputs.instructions.value = null
        state.truckingList[index].inputs.instructions.error = null
      } else {
        state.truckingList[index].inputs.instructions.enabled = true
      }
    },
    clearErrorsAll(state) {
      state.truckingList.forEach((trucking) => {
        for (const key in trucking.inputs) {
          Vue.delete(trucking.inputs[key], 'error')
        }
        for (const key in trucking.dates) {
          Vue.delete(trucking.dates[key], 'error')
        }

        trucking.containers.forEach((container) => {
          for (const key in container) {
            if (!container[key].value) continue
            Vue.delete(container[key], 'error')
          }
        })
      })
    },
  },
  actions: {},
}

function createTruckingList(id, inputs, newContainers, dates) {
  const e = {
    inputs: inputs || initialTruckingInputs(),
    dates: dates || initialDatesInputs(),
    containers: newContainers || [createContainer()],
  }

  if (id) {
    e.id = id
  } else {
    e._id = counter.value
  }

  return e
}

function createContainer(newContainer) {
  let id = counter.value
  newContainer = newContainer || initialContainer()
  newContainer._id = id

  return newContainer
}
