import openInNewTab from '@/helpers/openInNewTab'
import Vue from 'vue'
import printJS from 'print-js'

function getState() {
  return {
    pending: false,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    print({ commit, dispatch }, paymentsReceived) {
      commit('setProperty', ['pending', true])

      return dispatch('paymentsReceived/pdf/createPdf', paymentsReceived, {
        root: true,
      })
        .then(({ data }) => {
          return new Promise((resolve) => {
            printJS({
              printable: data.single,
              type: 'pdf',
              showModal: true,
              onError: () => {
                openInNewTab(data.single)
              },
              onLoadingEnd: () => {
                resolve()
              },
            })
          })
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
}
