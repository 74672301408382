import Vue from 'vue'
import print from './print'

function getState() {
  return {}
}

export default {
  namespaced: true,
  modules: {
    print,
  },
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
  },
  actions: {},
}
