import openInNewTab from '@/helpers/openInNewTab'
import Vue from 'vue'
import printJS from 'print-js'
import { axiosApi } from '@/axios'

function getState() {
  return {
    pending: false,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    print({ commit }, expenses) {
      commit('setProperty', ['pending', true])

      let item = expenses[0]
      if (isNaN(item)) {
        item = item.id
      }

      axiosApi
        .get(`expenses/pdf/${item}/get-pdf`, {
          responseType: 'blob',
        })
        .then(({ data }) => {
          return new Promise((resolve) => {
            let link = URL.createObjectURL(data)

            printJS({
              printable: link,
              type: 'pdf',
              showModal: true,
              onError: () => {
                openInNewTab(link)
              },
              onLoadingEnd() {
                URL.revokeObjectURL(link)
                resolve()
              },
            })
          })
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
}
