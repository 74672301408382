import { isPlainObject } from 'lodash'

export default function getErrorsByPath(errors, path) {
  if (typeof path !== 'string') return
  const keys = path.split('.')

  if (!keys.length) return

  return getError(keys, errors)
}

function getError(keys, errors) {
  if (!isPlainObject(errors)) return errors

  if (keys.length === 1) {
    return errors[keys[0]]
  } else {
    let key = keys.shift()
    return getError(keys, errors[key])
  }
}
