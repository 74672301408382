import { get } from 'lodash'

export default function (error) {
  const status = get(error, 'response.status')
  const responseMessage = get(error, 'response.data.message')
  let defaultMessage = 'Something went wrong...'

  if (status) {
    if (status === 413) {
      defaultMessage = 'File Too Large'
    }
  }

  if (error.response) {
    return responseMessage || defaultMessage
  } else {
    return error.message || defaultMessage
  }
}
