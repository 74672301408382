<template>
  <div class="v-lightbox" tabindex="-1">
    <div class="v-lightbox__head">
      <p
        class="v-lightbox__show-count"
        :style="{ opacity: innerPhotos.length < 2 ? 0 : 1 }"
      >
        {{ innerCurrentIndex + 1 }}/{{ innerPhotos.length }}
      </p>
      <button class="v-lightbox__btn btn" @click.prevent="onClickClose">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9">
          <path
            d="M14.1,11.3c-0.2-0.2-0.2-0.5,0-0.7l7.5-7.5c0.2-0.2,0.3-0.5,0.3-0.7s-0.1-0.5-0.3-0.7l-1.4-1.4C20,0.1,19.7,0,19.5,0  c-0.3,0-0.5,0.1-0.7,0.3l-7.5,7.5c-0.2,0.2-0.5,0.2-0.7,0L3.1,0.3C2.9,0.1,2.6,0,2.4,0S1.9,0.1,1.7,0.3L0.3,1.7C0.1,1.9,0,2.2,0,2.4  s0.1,0.5,0.3,0.7l7.5,7.5c0.2,0.2,0.2,0.5,0,0.7l-7.5,7.5C0.1,19,0,19.3,0,19.5s0.1,0.5,0.3,0.7l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3  s0.5-0.1,0.7-0.3l7.5-7.5c0.2-0.2,0.5-0.2,0.7,0l7.5,7.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4c0.2-0.2,0.3-0.5,0.3-0.7  s-0.1-0.5-0.3-0.7L14.1,11.3z"
          />
        </svg>
      </button>
    </div>
    <div
      ref="main-carousel"
      class="v-lightbox__main"
      :class="{ _single: innerPhotos.length < 2 }"
    >
      <button ref="main-carousel-prev" class="v-lightbox__btn-slide _prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9">
          <use xlink:href="#v-lightbox-ico-arrow"></use>
        </svg>
      </button>
      <button ref="main-carousel-next" class="v-lightbox__btn-slide _next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9">
          <use xlink:href="#v-lightbox-ico-arrow"></use>
        </svg>
      </button>
      <ul class="v-lightbox__list" @click="closeByClickOutsidePhoto">
        <li
          v-for="(file, index) in innerPhotos"
          :key="index"
          class="v-lightbox__item"
          :class="{ '_zoom-out': !isZoomActive }"
        >
          <div
            :style="{ flexGrow: file.type === TYPES.PDF ? 1 : 0 }"
            class="v-lightbox__img-wrap"
          >
            <!-- ERROR -->
            <template v-if="file.loaded || innerCurrentIndex === index">
              <p
                v-if="file.error"
                :key="`error_${index}`"
                ref="item"
                class="v-lightbox__img-error"
              >
                <span>{{ file.error }}</span>
                <span>{{ file.path }}</span>
              </p>
              <template v-else>
                <FileShow
                  :key="`file_${index}`"
                  @loadeddata="onLoadPhoto(index)"
                  :value="file"
                  controls
                  :autoplay="innerCurrentIndex === index"
                  @load="onLoadPhoto(index)"
                  @error="onErrorPhoto(index)"
                  class="v-lightbox__img _clickable"
                  fullSize
                  :class="{ _loading: !file.loaded, _zoom: isZoomActive }"
                  @click.stop="
                    file.type === TYPES.IMAGE ? toggleZoom() : undefined
                  "
                />
                <button
                  v-if="file.type === TYPES.IMAGE"
                  type="button"
                  class="btn _square v-lightbox__zoom-btn"
                  :class="{
                    _red: isZoomActive,
                    _blue: !isZoomActive,
                  }"
                  @click.stop.prevent="toggleZoom"
                >
                  <BaseSvg v-if="isZoomActive" name="ico-zoom-out" />
                  <BaseSvg v-else name="ico-zoom-in" />
                </button>
              </template>
            </template>
          </div>
          <h4 v-if="file.title" class="v-lightbox__title">{{ file.title }}</h4>
          <BasePreloader v-if="!file.loaded" class="_transparent" />
        </li>
      </ul>
    </div>
    <div
      class="v-lightbox__sublist"
      :class="{ '_zoom-active': isZoomActive }"
      v-show="innerPhotos.length > 1"
    >
      <button ref="previews-carousel-prev" class="v-lightbox__btn-slide _prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9">
          <use xlink:href="#v-lightbox-ico-arrow"></use>
        </svg>
      </button>
      <button ref="previews-carousel-next" class="v-lightbox__btn-slide _next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9">
          <use xlink:href="#v-lightbox-ico-arrow"></use>
        </svg>
      </button>
      <div
        ref="previews-carousel"
        class="v-lightbox__sublist-container"
        @click="closeByClickOutsidePhoto"
      >
        <div class="v-lightbox__sublist-list">
          <FileShow
            v-for="(file, index) in innerPhotos"
            :key="index"
            :value="file"
            :show-name="false"
            size="xs"
            class="v-lightbox__sublist-item"
          />
        </div>
      </div>
    </div>
    <div style="display: none">
      <svg
        viewBox="0 0 5 9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="v-lightbox-ico-arrow"
      >
        <path
          d="M4.82010681,4.94554628 L1.04856165,8.81528316 C0.808645822,9.06157228 0.419663431,9.06157228 0.179864076,8.81528316 C-0.0599546918,8.56921314 -0.0599546918,8.17011123 0.179864076,7.92406113 L3.51709927,4.49993527 L0.179961137,1.07592891 C-0.0598576307,0.829759299 -0.0598576307,0.430697227 0.179961137,0.184627208 C0.419779904,-0.0615424026 0.808742883,-0.0615424026 1.04865871,0.184627208 L4.82020387,4.05442384 C4.94011325,4.17751861 5,4.33867714 5,4.49991535 C5,4.66123323 4.93999678,4.82251127 4.82010681,4.94554628 Z"
        ></path>
      </svg>
      <svg
        viewBox="0 0 128 170"
        xmlns="http://www.w3.org/2000/svg"
        id="v-lightbox-ico-document"
      >
        <g>
          <path
            d="M63.798,121H59v22h5.422c3.709,0,6.56-1.023,8.554-2.896c1.993-1.871,2.99-4.637,2.99-8.207   s-0.997-6.202-2.99-8.127C70.981,121.846,67.923,121,63.798,121z"
          />
          <path
            d="M37.721,122.136c-1.267-0.884-3.233-1.136-5.902-1.136H27v12h5.65c2.704,0,4.541-0.626,5.512-1.718   c0.971-1.093,1.456-2.631,1.456-4.729C39.618,124.456,38.985,123.02,37.721,122.136z"
          />
          <path
            d="M127.613,29l0.004-0.004L99,0.379V0h-0.379l-0.117-0.117L98.387,0H10C4.478,0,0,4.477,0,10v150   c0,5.523,4.478,10,10,10h108c5.522,0,10-4.477,10-10V29H127.613z M44.013,136.811C41.395,138.89,37.399,140,32.026,140H27v10h-8   v-36h12.923c5.581,0,9.646,0.91,12.193,3.076c2.548,2.167,3.822,5.443,3.822,9.932C47.938,131.498,46.63,134.73,44.013,136.811z    M79.06,145.061C75.68,148.354,70.522,150,63.59,150H51v-36h13.006c6.481,0,11.457,1.412,14.924,4.584   c3.466,3.172,5.2,7.496,5.2,13.146C84.13,137.382,82.439,141.768,79.06,145.061z M113,121H96v8h16v7H96v14h-8v-36h25V121z M109,29   c-5.522,0-10-4.477-10-10V4.622L123.379,29H109z"
          />
        </g>
        <rect x="5" y="100" width="120" height="60"></rect>
      </svg>
    </div>
  </div>
</template>

<script>
import filesFormat from '@/helpers/filesFormat'
import { TYPES } from '@/constants/files'
import _loadSwiperMixin from '@/mixins/_loadSwiperMixin'
import FileShow from '@/components/FileShow'

/**
 * @param {Array} files
 * @param {string} files.path
 * @param {string} files.type
 * @param {string} files.title
 */

export default {
  name: 'VLightboxOverlay',
  mixins: [_loadSwiperMixin],
  components: {
    FileShow,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
      required: true,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      innerCurrentIndex: 0,
      innerPhotos: [],
      TYPES,
      mainCarousel: null,
      previewsCarousel: null,
      isZoomActive: false,
    }
  },
  methods: {
    toggleZoom() {
      this.isZoomActive = !this.isZoomActive
    },
    onClickClose() {
      this.$root.$emit('close')
    },
    createPhotos() {
      const files = filesFormat(this.files)
      files.forEach((file) => {
        // file.loaded = !!((file.type === TYPES.PDF) || (file.type === TYPES.OTHER))
        file.loaded = false
      })

      this.innerPhotos = files
      this.innerCurrentIndex = this.currentIndex

      this.createCarousels()
    },
    closeByClickOutsidePhoto(e) {
      if (e.target.closest('.file-show__file, .v-lightbox__sublist-item'))
        return
      this.$root.$emit('close')
    },
    onLoadPhoto(index) {
      let file = this.innerPhotos[index]
      if (!file) return

      this.$set(file, 'loaded', true)
      file.error = null
    },
    onErrorPhoto(index) {
      let file = this.innerPhotos[index]
      if (!file) return

      this.$set(file, 'loaded', true)
      this.$set(file, 'error', 'Loading failed')
    },
    onSlideChange() {
      const items = this.$refs.item
      if (Array.isArray(items) && items.length) {
        items.forEach((item) => {
          if (item.tagName === 'VIDEO') {
            item.pause && item.pause()
          }
        })
      }
    },

    async createCarousels() {
      await this.loadSwiperMixin()

      this.destroyCarousels()

      this.createPreviewsCarousel()
      this.createMainCarousel()
    },
    createMainCarousel() {
      const _self = this
      this.mainCarousel = new Swiper(this.$refs['main-carousel'], {
        wrapperClass: 'v-lightbox__list',
        slideClass: 'v-lightbox__item',
        initialSlide: this.innerCurrentIndex || 0,
        slidesPerView: 'auto',
        watchOverflow: true,
        roundLengths: true,
        observer: true,
        threshold: 2,
        navigation: {
          nextEl: this.$refs['main-carousel-next'],
          prevEl: this.$refs['main-carousel-prev'],
          disabledClass: '_disabled',
          lockClass: '_lock',
        },
        thumbs: {
          swiper: this.previewsCarousel,
        },
        on: {
          slideChange() {
            _self.innerCurrentIndex = this.activeIndex
            _self.onSlideChange()
          },
        },
      })
    },
    createPreviewsCarousel() {
      this.previewsCarousel = new Swiper(this.$refs['previews-carousel'], {
        wrapperClass: 'v-lightbox__sublist-list',
        slideClass: 'v-lightbox__sublist-item',
        slidesPerView: 'auto',
        watchOverflow: true,
        roundLengths: true,
        observer: true,
        centerInsufficientSlides: true,
        threshold: 2,
        navigation: {
          nextEl: this.$refs['previews-carousel-next'],
          prevEl: this.$refs['previews-carousel-prev'],
          disabledClass: '_disabled',
          lockClass: '_lock',
        },
      })
    },
    destroyCarousels() {
      this.previewsCarousel &&
        this.previewsCarousel.destroy &&
        this.previewsCarousel.destroy(true, false)
      this.mainCarousel &&
        this.mainCarousel.destroy &&
        this.mainCarousel.destroy(true, false)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.focus({ preventScroll: true })
    })
  },
  watch: {
    files: {
      immediate: true,
      deep: true,
      handler(to) {
        if (to.length) {
          this.createPhotos()
        }
      },
    },
    innerCurrentIndex: {
      immediate: true,
      handler(to) {
        const type = this.$get(this, `innerPhotos[${to}].type`)
        if (!type) return
        if (type === TYPES.PDF || type === TYPES.OTHER) {
          this.onLoadPhoto(to)
        }

        this.isZoomActive = false
      },
    },
    isZoomActive: {
      handler(to) {
        if (to) {
          this.previewsCarousel?.disable && this.previewsCarousel?.disable()
          this.mainCarousel?.disable && this.mainCarousel?.disable()
        } else {
          this.previewsCarousel?.enable && this.previewsCarousel?.enable()
          this.mainCarousel?.enable && this.mainCarousel?.enable()
        }
      },
    },
  },
  beforeDestroy() {
    this.destroyCarousels()
  },
}
</script>
