import { axiosApi } from '@/axios'
import moment from '@/helpers/useMoment'
import router from '@/router'
import getRangeFromRoute from '@/helpers/calendar/getRangeFromRoute'
import axios from 'axios'
import { TIME_RANGE_TYPES } from '@/constants/calendar'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    pending: true,
    items: [],
    loadedRange: {},
    cancelRequest: null,
  },
  mutations: {
    pending(state, pending = false) {
      state.pending = pending
    },
    setEvents(state, events) {
      state.items = state.items.concat(events)
    },
    clearEvents(state) {
      state.items = []
    },
    setLoadedRange(state, value) {
      state.loadedRange = value
    },
    addCancelRequest(state, value) {
      state.cancelRequest = value
    },
    clearCancelRequest(state) {
      state.cancelRequest = null
    },
    runCancelRequests(state) {
      if (state.cancelRequest) {
        state.cancelRequest.cancel('Operation canceled')
        state.cancelRequest = null
      }
    },
  },
  getters: {
    getLoadedRange(state) {
      return state.loadedRange
    },
  },
  actions: {
    async loadEvents({ commit, rootGetters }, payload) {
      if (!payload) {
        payload = getRangeFromRoute(router.currentRoute, undefined) || {}
      }
      if (
        !payload ||
        !payload.type ||
        [TIME_RANGE_TYPES.YEAR].includes(payload.type)
      ) {
        commit('pending', false)
        commit('runCancelRequests')
        return
      }

      commit('pending', true)
      commit('runCancelRequests')

      const eventColors = rootGetters['calendars/getEventColors']
      const calendarsList = rootGetters['calendars/getCalendarsList']

      const calendarsIds = calendarsList.map((item) => item.id)

      commit('setLoadedRange', {
        timeMin: payload.timeMin,
        timeMax: payload.timeMax,
        type: payload.type,
      })

      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      commit('addCancelRequest', source)

      const requests = calendarsIds.map((id) => {
        if (id === 'system') {
          return axiosApi
            .get(`/calendar/events`, {
              cancelToken: source.token,
              params: {
                timeMin: payload.timeMin,
                timeMax: payload.timeMax,
              },
            })
            .then(({ data }) => {
              return {
                items: data.data,
                calendarId: id,
              }
            })
        }

        return axiosApi
          .get(
            `/google-api?url=https://www.googleapis.com/calendar/v3/calendars/${id}/events`,
            {
              cancelToken: source.token,
              params: {
                timeMin: payload.timeMin,
                timeMax: payload.timeMax,
              },
            }
          )
          .then(({ data }) => {
            return {
              ...data,
              calendarId: id,
            }
          })
      })

      return Promise.all(requests).then((res) => {
        commit('clearEvents')

        res.forEach((data) => {
          const calendarData =
            calendarsList.find((calendar) => calendar.id === data.calendarId) ||
            {}
          const calendarColor = {
            background: calendarData.backgroundColor,
            foreground: calendarData.foregroundColor,
          }
          const items = data.items.map((item) => {
            return {
              ...item,
              eventColor: item.colorId ? eventColors[item.colorId] : {},
              calendarId: data.calendarId,
              calendarColor,
              calendarData,
            }
          })
          commit('setEvents', items)
        })

        commit('pending', false)
        commit('clearCancelRequest')
      })
    },
    addGoogleEvent(_, { inputs, fullDay = true, timeZone }) {
      const calendarId = get(inputs, 'calendar.value.id', '')
      const sendData = {
        summary: inputs.summary.value,
        description: inputs.description.value,
        start: {},
        end: {},
      }

      if (fullDay) {
        sendData.start.date = moment(inputs.start.value)
          .startOf('day')
          .format('YYYY-MM-DD')
        sendData.end.date = moment(inputs.end.value)
          .endOf('day')
          .add(1, 'day')
          .endOf('day')
          .format('YYYY-MM-DD')
      } else {
        sendData.start.timeZone = timeZone || moment.tz.guess()
        sendData.end.timeZone = timeZone || moment.tz.guess()
        sendData.start.dateTime = moment(inputs.start.value)
          .tz(sendData.start.timeZone)
          .format()
        sendData.end.dateTime = moment(inputs.end.value)
          .tz(sendData.end.timeZone)
          .format()
      }
      if (inputs.selectedColor.value.id === 'calendar') {
        sendData.colorId = null
      } else {
        sendData.colorId = inputs.selectedColor.value.id
      }

      return axiosApi.post(
        `/google-api?url=https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
        sendData
      )
    },
    updateSystemEvent(_, { eventId, inputs, fullDay }) {
      const sendData = {
        summary: inputs.summary.value,
        start: inputs.start.value,
        end: inputs.end.value,
      }
      if (fullDay) {
        sendData.start = moment(inputs.start.value).startOf('day').toISOString()
        sendData.end = moment(inputs.end.value)
          .endOf('day')
          .add(1, 'day')
          .endOf('day')
          .toISOString()
      }
      return axiosApi.post(`/calendar/events/${eventId}`, sendData)
    },
    updateGoogleEvent(
      _,
      { prevCalendarId, eventId, inputs, fullDay = true, timeZone }
    ) {
      return new Promise((resolve, reject) => {
        const newCalendarId = get(inputs, 'calendar.value.id')
        const sendData = {
          summary: inputs.summary.value,
          description: inputs.description.value,
          start: {},
          end: {},
        }
        const requests = []
        if (prevCalendarId && prevCalendarId !== newCalendarId) {
          requests.push({
            method: 'post',
            body: [
              `/google-api?url=https://www.googleapis.com/calendar/v3/calendars/${prevCalendarId}/events/${eventId}/move`,
              {
                destination: newCalendarId,
              },
            ],
          })
        }
        if (fullDay) {
          sendData.start.date = moment(inputs.start.value)
            .startOf('day')
            .format('YYYY-MM-DD')
          sendData.end.date = moment(inputs.end.value)
            .endOf('day')
            .add(1, 'day')
            .endOf('day')
            .format('YYYY-MM-DD')
        } else {
          sendData.start.timeZone = timeZone || moment.tz.guess()
          sendData.end.timeZone = timeZone || moment.tz.guess()

          sendData.start.dateTime = moment(inputs.start.value)
            .tz(sendData.start.timeZone)
            .format()
          sendData.end.dateTime = moment(inputs.end.value)
            .tz(sendData.end.timeZone)
            .format()
        }

        if (inputs.selectedColor.value.id === 'calendar') {
          sendData.colorId = null
        } else {
          sendData.colorId = inputs.selectedColor.value.id
        }

        requests.push({
          method: 'put',
          body: [
            `/google-api?url=https://www.googleapis.com/calendar/v3/calendars/${newCalendarId}/events/${eventId}`,
            sendData,
          ],
        })

        const runRequests = async () => {
          try {
            for (const request of requests) {
              await axiosApi[request.method](...request.body)
            }
            return resolve()
          } catch (e) {
            return reject(e)
          }
        }
        return runRequests()
      })
    },
    deleteGoogleEvent(_, { calendarId, eventId }) {
      return axiosApi.delete(
        `/google-api?url=https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`
      )
    },
    deleteSystemEvent(_, { eventId }) {
      return axiosApi.delete(`/calendar/events/mass_delete`, {
        params: {
          id: [eventId],
        },
      })
    },
  },
}
