import createBlanksByModels from './createBlanksByModels'
import BCommodityLabel from '@/components/Blanks/FileDocuments/DocumentCommodityLabel'

export default function (models) {
  return createBlanksByModels({
    models,
    blankComponent: BCommodityLabel,
    blankComponentPropKey: 'commodity',
  })
}
