import { AbilityBuilder, Ability } from '@casl/ability'
import abilityActions from './abilityActions'
import abilitySubjects from './abilitySubjects'
const ability = new Ability()

const customersPermissions = {
  [abilitySubjects.CUSTOMERS_OVERVIEW]: abilityActions.read,
  [abilitySubjects.CUSTOMERS_COMMODITIES]: abilityActions.read,
  [abilitySubjects.CUSTOMERS_SHIPPING_FILES]: abilityActions.read,
  [abilitySubjects.CUSTOMERS_SALES]: abilityActions.read,
  [abilitySubjects.CUSTOMERS_SALES_INVOICES]: abilityActions.read,
  [abilitySubjects.CUSTOMERS_SALES_ESTIMATES]: abilityActions.read,
  [abilitySubjects.SHIPPING_FILES_CONTAINER_CUSTOMER_DOCUMENTS]:
    abilityActions.read,
  [abilitySubjects.SHIPPING_FILES_CONTAINER_CUSTOMER_ESTIMATES]:
    abilityActions.read,
  [abilitySubjects.SHIPPING_FILES_CONTAINER_CUSTOMER_INVOICES]:
    abilityActions.read,
}

const setAbility = (user, can) => {
  if (user && user.type) {
    switch (user.type) {
      case 'super_admin': {
        can('manage', 'all')
        break
      }
      case 'customer': {
        for (let key in customersPermissions) {
          can(customersPermissions[key], key)
        }
        break
      }
      default: {
        const userPermissions = user.profile.permissions
        for (let key in abilitySubjects) {
          const subject = abilitySubjects[key]
          if (Object.prototype.hasOwnProperty.call(userPermissions, key)) {
            const action = userPermissions[key]
            if (action) {
              if (
                Object.prototype.hasOwnProperty.call(abilityActions, action)
              ) {
                can(action, subject)
              } else {
                console.warn(
                  `action "${action}" is not exists in the action list`
                )
              }
            } else {
              // console.warn(`no access for "${key}", is "${action}"`)
            }
          } else {
            console.warn(`not has "${subject}" in user permissions`)
          }
        }
      }
    }
  }
}

const updateAbility = (user) => {
  const { can, rules } = new AbilityBuilder(Ability)
  setAbility(user, can)
  ability.update(rules)
}

const getDefaultPermissions = () => {
  return Object.keys(abilitySubjects)
    .sort()
    .reduce((obj, key) => {
      obj[key] = abilityActions.manage
      return obj
    }, {})
}

export { ability, updateAbility, getDefaultPermissions }
