const Invoices = () =>
  import(/*webpackChunkName: "Invoices" */ '@/views/Main/Sales/Invoices')

const NewInvoice = () =>
  import(
    /*webpackChunkName: "NewInvoice" */ '@/views/Main/Sales/Invoices/NewInvoice'
  )
const EditInvoice = () =>
  import(
    /*webpackChunkName: "EditInvoice" */ '@/views/Main/Sales/Invoices/EditInvoice'
  )
const Invoice = () =>
  import(
    /*webpackChunkName: "Invoice" */ '@/views/Main/Sales/Invoices/InvoicePage'
  )
const Estimates = () =>
  import(/*webpackChunkName: "Estimates" */ '@/views/Main/Sales/Estimates')
const Estimate = () =>
  import(
    /*webpackChunkName: "Estimate" */ '@/views/Main/Sales/Estimates/EstimatePage'
  )
const NewEstimate = () =>
  import(
    /*webpackChunkName: "NewEstimate" */ '@/views/Main/Sales/Estimates/NewEstimate'
  )
const InvoiceGenerators = () =>
  import(
    /*webpackChunkName: "InvoiceGenerators" */ '@/views/Main/Sales/InvoiceGenerators'
  )
const NewInvoiceGenerator = () =>
  import(
    /*webpackChunkName: "NewInvoiceGenerator" */ '@/views/Main/Sales/InvoiceGenerators/NewInvoiceGenerator'
  )
const InvoiceGenerator = () =>
  import(
    /*webpackChunkName: "InvoiceGenerator" */ '@/views/Main/Sales/InvoiceGenerators/InvoiceGenerator'
  )
const PaymentsReceived = () =>
  import(
    /*webpackChunkName: "PaymentsReceived" */ '@/views/Main/Sales/PaymentsReceived'
  )
const NewPaymentReceived = () =>
  import(
    /*webpackChunkName: "NewPaymentReceived" */ '@/views/Main/Sales/PaymentsReceived/NewPaymentReceived'
  )
const EditPaymentReceived = () =>
  import(
    /*webpackChunkName: "EditPaymentReceived" */ '@/views/Main/Sales/PaymentsReceived/EditPaymentReceived'
  )
const PaymentReceivedPage = () =>
  import(
    /*webpackChunkName: "PaymentReceivedPage" */ '@/views/Main/Sales/PaymentsReceived/PaymentReceivedPage'
  )
const Deposits = () =>
  import(/*webpackChunkName: "Deposits" */ '@/views/Main/Sales/Deposits')
const Deposit = () =>
  import(
    /*webpackChunkName: "Deposit" */ '@/views/Main/Sales/Deposits/DepositPage'
  )
const EditEstimate = () =>
  import(
    /*webpackChunkName: "EditEstimate" */ '@/views/Main/Sales/Estimates/EditEstimate'
  )
import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

export default [
  {
    path: 'sales/invoice/new-invoice',
    name: 'sales-new-invoice',
    component: NewInvoice,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SALES],
      title: 'New Invoice',
    },
  },
  {
    path: 'sales/invoice/edit-invoice/:invoiceId',
    name: 'sales-edit-invoice',
    component: EditInvoice,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SALES],
      title: 'Edit Invoice',
    },
  },
  {
    path: 'sales/invoices',
    name: 'sales-invoices',
    component: Invoices,
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
      title: 'Invoices',
    },
    children: [
      {
        path: ':invoiceId',
        name: 'sales-invoice',
        component: Invoice,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SALES],
        },
      },
    ],
  },
  {
    path: 'sales/estimate/new-estimate',
    name: 'sales-new-estimate',
    component: NewEstimate,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SALES],
      title: 'New Estimate',
    },
  },
  {
    path: 'sales/estimate/edit-estimate/:estimateId',
    name: 'sales-edit-estimate',
    component: EditEstimate,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SALES],
      title: 'Edit Estimate',
    },
  },
  {
    path: 'sales/estimates',
    name: 'sales-estimates',
    component: Estimates,
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
      title: 'Estimates',
    },
    children: [
      {
        path: ':estimateId',
        name: 'sales-estimate',
        component: Estimate,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SALES],
        },
      },
    ],
  },
  {
    path: 'sales/invoice-generators',
    name: 'sales-invoices-generators',
    component: InvoiceGenerators,
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
      title: 'Invoice Generators',
    },
    children: [
      {
        path: 'invoice-generator/:invoiceGeneratorId',
        name: 'sales-invoice-generator',
        component: InvoiceGenerator,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SALES],
        },
      },
    ],
  },
  {
    path: 'sales/invoice-generators/new-recurring-invoice-generator',
    name: 'sales-new-recurring-invoice-generator',
    component: NewInvoiceGenerator,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SALES],
      title: 'New Invoice Generator',
    },
  },
  {
    path: 'sales/payments-received/new-payment-received',
    name: 'sales-new-payment-received',
    component: NewPaymentReceived,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SALES],
      title: 'New Payment Received',
    },
  },
  {
    path: 'sales/payments-received/edit-payment-received/:paymentReceivedId',
    name: 'sales-edit-payment-received',
    component: EditPaymentReceived,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SALES],
      title: 'Edit Payment Received',
    },
  },
  {
    path: 'sales/payments-received',
    name: 'sales-payments-received',
    component: PaymentsReceived,
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
      title: 'Payments Received',
    },
    children: [
      {
        path: 'payment/:paymentReceivedId',
        name: 'sales-payment-received',
        component: PaymentReceivedPage,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SALES],
        },
      },
    ],
  },
  {
    path: 'sales/deposits',
    name: 'sales-deposits',
    component: Deposits,
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
      title: 'Deposits',
    },
    children: [
      {
        path: 'deposit/:depositId',
        name: 'sales-deposit',
        component: Deposit,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SALES],
        },
      },
    ],
  },
  {
    path: 'sales',
    name: 'sales',
    redirect: {
      name: 'sales-estimates',
    },
    meta: {
      permission: [abilityActions.read, abilitySubjects.SALES],
    },
  },
]
