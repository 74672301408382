import Vue from 'vue'
import { baseURL } from '@/axios'

const baseURLNew =
  baseURL[baseURL.length - 1] !== '/'
    ? baseURL
    : baseURL.slice(0, baseURL.length - 1)

Vue.filter('getImgURL', function (image) {
  if (image[0] !== '/') image = '/' + image
  image = baseURLNew + image

  return image
})
