import Vue from 'vue'
import { CancelToken } from 'axios'
import { axiosApi } from '@/axios'

function getState() {
  return {
    pending: false,
    cancelRequest: null,
    rate: null,
    commodityDeliveryRatesData: null,
    meta: null,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
    selectCommodityDeliveryRatesData(state, { index, value }) {
      state.commodityDeliveryRatesData = state.commodityDeliveryRatesData.map(
        (el, idx) => {
          if (index === idx) {
            el = {
              ...el,
              selected: value,
            }
            return el
          }
          return el
        }
      )
    },
    selectToggleCommodityDeliveryRatesData(state, { value }) {
      state.commodityDeliveryRatesData = state.commodityDeliveryRatesData.map(
        (el) => {
          return {
            ...el,
            selected: value,
          }
        }
      )
    },
    updateCommodityDeliveryRatesData(state, id) {
      state.commodityDeliveryRatesData =
        state.commodityDeliveryRatesData.filter((item) => item.id !== id)
    },
    setRate(state, payload) {
      state.rate = payload
    },
  },
  actions: {
    getRates({ state, commit }) {
      if (typeof state.cancelRequest === 'function') {
        state.cancelRequest()
        commit('setProperty', ['cancelRequest', null])
      }

      const header = {
        cancelToken: new CancelToken(function executor(c) {
          commit('setProperty', ['cancelRequest', c])
        }),
        params: {
          //   page: route.query[QUERY_PAGE_NAME] || 1,
          //   loading_place: route.query[QUERY_LOADING_PLACE_NAME],
          //   port_of_discharge: route.query[QUERY_PORT_OF_DISCHARGE_NAME],
          //   place_of_delivery: route.query[QUERY_PLACE_OF_DELIVERY_NAME]
        },
      }

      commit('setProperty', ['pending', true])
      commit('setProperty', ['meta', null])

      return axiosApi
        .get(`/settings/commodities/delivery-rates`, header)
        .then(({ data }) => {
          commit('setProperty', [
            'commodityDeliveryRatesData',
            data.data.map((el) => {
              return (el = {
                ...el,
                selected: false,
              })
            }),
          ])
          commit('setProperty', ['meta', data.meta])
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
    getRateById({ commit }, { id }) {
      commit('setProperty', ['pending', true])
      return axiosApi
        .get(`/settings/commodities/delivery-rates/${id}`)
        .then((response) => {
          commit('setRate', response.data.data.attributes)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          commit('setProperty', ['pending'])
        })
    },
    deleteRate({ commit }, id) {
      return axiosApi
        .delete(`/settings/commodities/delivery-rates/${id}`)
        .then(() => {
          commit('updateCommodityDeliveryRatesData', id)
        })
        .catch((e) => {
          throw e
        })
    },
    massDeleteRates(_, { id }) {
      return axiosApi
        .delete('/settings/commodities/delivery-rates/mass_delete', {
          params: {
            id,
          },
        })
        .then(() => {})
        .catch((err) => console.log(err))
    },
  },
  getters: {
    selectedCommodityDeliveryRatesData(state) {
      return state.commodityDeliveryRatesData
        .filter((el) => !!el.selected)
        .map((el) => el.id)
    },
    selectedAmount(state) {
      if (state.commodityDeliveryRatesData) {
        return state.commodityDeliveryRatesData.reduce((result, item) => {
          return item.selected ? result + 1 : result
        }, 0)
      }
      return null
    },
    isSomeSelected(state, getters) {
      if (state.commodityDeliveryRatesData) {
        return !!(
          state.commodityDeliveryRatesData.length && getters.selectedAmount
        )
      }
      return null
    },
    isAllSelected(state, getters) {
      if (state.commodityDeliveryRatesData) {
        return (
          getters.selectedAmount === state.commodityDeliveryRatesData.length
        )
      }
      return null
    },
    from(state) {
      if (state.meta) {
        return state.meta.from || 1
      }
      return 1
    },
    to(state) {
      if (state.meta) {
        return state.meta.to || 1
      }
      return 1
    },
    perPage(state) {
      if (state.meta) {
        return state.meta.per_page || 10
      }
      return 10
    },
  },
}
