const WINDOW_SIZE_XXL = 1920
const WINDOW_SIZE_XL = 1600
const WINDOW_SIZE_LG = 1340
const WINDOW_SIZE_ML = 1023
const WINDOW_SIZE_MD = 980
const WINDOW_SIZE_SM = 716
const WINDOW_SIZE_XS = 450

module.exports = {
  WINDOW_SIZE_LG,
  WINDOW_SIZE_MD,
  WINDOW_SIZE_ML,
  WINDOW_SIZE_SM,
  WINDOW_SIZE_XL,
  WINDOW_SIZE_XS,
  WINDOW_SIZE_XXL,
}
