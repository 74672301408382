import Vue from 'vue'
import axios from 'axios'
import { axiosApi } from '@/axios'
import router from '@/router'

import {
  PAGE_QUERY_NAME,
  SORT_QUERY_NAME,
  SEARCH_QUERY_NAME,
  PER_PAGE,
  STATUS_QUERY_NAME,
  STATUS_DELETED,
  MAIN_ACCOUNTS,
} from '@/constants/persons'
import { paymentAccountConfigs } from '@/constants/personsConfigs'

export default {
  getState() {
    return {
      loaded: false,
      pending: false,
      pendingFiles: false,
      pendingEmails: false,
      cancelRequest: null,
      total: 0,
      items: [],
      personOne: null,
      queryNames: [
        PAGE_QUERY_NAME,
        SORT_QUERY_NAME,
        SEARCH_QUERY_NAME,
        STATUS_QUERY_NAME,
        MAIN_ACCOUNTS,
      ],
    }
  },

  getMutations() {
    return {
      setCancelRequest(state, value) {
        state.cancelRequest = value
      },
      cancelRequest(state) {
        if (state.cancelRequest) {
          state.cancelRequest()
          state.cancelRequest = null
        }
      },
      save(state, data) {
        state.items = data.items
        state.total = data.total
        state.pending = false
        state.loaded = true
      },
      pending(state, isPending = true) {
        state.pending = isPending
      },
      pendingFiles(state, value) {
        state.pendingFiles = value
      },
      pendingEmails(state, value) {
        state.pendingEmails = value
      },
      reset(state) {
        if (state.cancelRequest) {
          state.cancelRequest()
          state.cancelRequest = null
        }

        state.items = []
        state.total = 0
        state.pending = false
        state.loaded = false
      },
      saveOne(state, person) {
        state.personOne = person
      },
      saveEmails(state, emails) {
        if (!state.personOne) return
        state.personOne.sent_emails = emails
      },
      resetOne(state) {
        state.personOne = null
      },
      selectChangeOne(state, { index, value }) {
        state.items[index].selected = value
      },
      selectChangeAll(state, value) {
        for (let item of state.items) {
          item.selected = value
        }
      },
      addFiles(state, files) {
        state.personOne.files = [].concat(files, state.personOne.files)
      },
      deleteFile(state, index) {
        state.personOne.files.splice(index, 1)
      },
      changeFile(state, { index, file }) {
        Vue.set(state.personOne.files, index, file)
      },
    }
  },

  getActions() {
    return {
      load({ commit, state }, route) {
        commit('cancelRequest')

        const CancelToken = axios.CancelToken

        let sort = route.query[SORT_QUERY_NAME] || null
        sort = sort ? sort.split('|') : []

        const header = {
          cancelToken: new CancelToken((c) => commit('setCancelRequest', c)),
          params: {
            query: route.query[SEARCH_QUERY_NAME],
            limit: PER_PAGE,
            page: route.query[PAGE_QUERY_NAME],
            sort: sort[0] || null,
            order: sort[1],
            status: route.query[STATUS_QUERY_NAME],
          },
        }

        if (state.props.httpBaseLink === paymentAccountConfigs.httpBaseLink) {
          const hasValue = Object.prototype.hasOwnProperty.call(
            route.query,
            MAIN_ACCOUNTS
          )
          if (!hasValue || route.query[MAIN_ACCOUNTS] === 'true') {
            header.params[MAIN_ACCOUNTS] = true
          }
        }

        commit('pending')

        return axiosApi
          .get(`/${state.props.httpBaseLink}`, header)
          .then(({ data }) => {
            let items = data.data.map(function (item) {
              item.attributes.selected = false
              return item.attributes
            })

            commit('save', {
              items,
              total: data.meta.total,
            })

            return items
          })
          .catch((error) => {
            if (axios.isCancel(error)) return
            return error
          })
          .finally(() => {
            commit('pending', false)
          })
      },
      loadOne({ commit, state }, id) {
        if (!id) {
          commit('saveOne', null)
          return Promise.resolve()
        }

        return axiosApi
          .get(`${state.props.httpBaseLink}/${id}`)
          .then(({ data }) => {
            const person = data.attributes

            commit('saveOne', person)
            return person
          })
          .catch(() => {
            commit('saveOne', null)
            // Vue.prototype.$errorCatchDefault(e)
          })
      },
      add({ commit, dispatch, state }, { id, formData }) {
        let method = 'post'

        const statePersonOneId = (state.personOne && state.personOne.id) || null

        return axiosApi[method](
          `${state.props.httpBaseLink}/${id || ''}`,
          formData
        ).then(({ data }) => {
          const person = data.item.attributes

          if (state.loaded) {
            dispatch('load', router.currentRoute)
          }
          if (statePersonOneId && String(statePersonOneId) === String(id)) {
            commit('saveOne', person)
          }

          return person
        })
      },
      delete({ commit, state }, id) {
        commit('pending')

        return axiosApi
          .delete(`/${state.props.httpBaseLink}/` + id)
          .finally(() => {
            commit('pending', false)
          })
      },
      deleteMultiple({ commit, state, getters }) {
        const ids = getters.selectedIds

        return axiosApi
          .delete(`/${state.props.httpBaseLink}/mass_delete`, {
            params: { id: ids },
          })
          .finally(() => {
            commit('pending', false)
          })
      },
      restoreMultiple({ commit, state, getters }) {
        const ids = getters.selectedIds

        return axiosApi
          .post(`/${state.props.httpBaseLink}/mass_restore`, {
            id: ids,
          })
          .finally(() => {
            commit('pending', false)
          })
      },
      setStatusMultiple({ commit, state, getters }, status) {
        if (!status) return
        const ids = getters.selectedIds

        return axiosApi
          .put(`/${state.props.httpBaseLink}/change_active_status`, {
            id: ids,
            status,
          })
          .finally(() => {
            commit('pending', false)
          })
      },
      uploadFiles({ state, commit }, { files }) {
        if (!state.personOne.id) return
        const formData = new FormData()

        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i])
        }

        commit('pendingFiles', true)

        axiosApi
          .post(
            `${state.props.httpBaseLink}/${state.personOne.id}/files/`,
            formData
          )
          .then(({ data }) => {
            commit(
              'addFiles',
              data.map((item) => item.attributes)
            )
          })
          .finally(() => {
            commit('pendingFiles', false)
          })
      },
      changeName({ state, commit }, { index, name }) {
        const file = state.personOne.files[index]

        const sendData = {
          name,
        }

        commit('pendingFiles', true)
        axiosApi
          .put(
            `${state.props.httpBaseLink}/${state.personOne.id}/files/${file.id}/change_name`,
            sendData
          )
          .then(({ data }) => {
            commit('changeFile', { index, file: data.item.attributes })
          })
          .finally(() => {
            commit('pendingFiles', false)
          })
      },
      deleteFile({ state, commit }, { index }) {
        const file = state.personOne.files[index]
        commit('pendingFiles', true)
        axiosApi
          .delete(
            `${state.props.httpBaseLink}/${state.personOne.id}/files/${file.id}`
          )
          .then(() => {
            commit('deleteFile', index)
          })
          .finally(() => {
            commit('pendingFiles', false)
          })
      },
      reloadEmails({ state, commit }) {
        const id = state.personOne.id
        if (!id) return
        commit('pendingEmails', true)

        return axiosApi
          .get(`${state.props.httpBaseLink}/${id}/sent_emails`)
          .then(({ data }) => {
            const emails = data.data.map((item) => item.attributes)
            commit('saveEmails', emails)
            return emails
          })
          .finally(() => {
            commit('pendingEmails', false)
          })
      },
    }
  },

  getGetters() {
    return {
      haveOnePerson(state) {
        const person = state.personOne
        return !!(person && person.status && person.status !== STATUS_DELETED)
      },
      selectedIds(state) {
        return state.items.reduce((arr, current) => {
          if (current.selected) arr.push(current.id)
          return arr
        }, [])
      },
      personRouteQuery(state) {
        let prevArg
        let cache

        return (route) => {
          if (prevArg === route) return cache
          const query = {}
          for (let name of state.queryNames) {
            if (route.query[name]) {
              query[name] = route.query[name]
            }
          }

          prevArg = route
          cache = query
          return query
        }
      },
    }
  },
}
