export const QUERY_PAGE_NAME = 'page'
export const QUERY_SEARCH_NAME = 'search'
export const QUERY_SEARCH_GLOBAL_NAME = 'search_global'
export const PER_PAGE_LIMIT = 20
export const STATUS_PENDING_SHIPPING = 'pending_shipping'
export const STATUS_CONTAINER_LOADED = 'container_loaded'
export const STATUS_CONTAINER_INGATED = 'container_ingated'
export const STATUS_CONTAINER_SAILED = 'container_sailed'
export const STATUS_CONTAINER_ARRIVED = 'container_arrived'
export const STATUS_SHIPPED_RELEASE_STATUS = 'shipped_release_status'
export const RELEASE_STATUS_SENT = 'sent'
export const RELEASE_STATUS_NOT_SENT = 'not_sent'

export const RELEASE_STATUSES_LABELS = {
  [RELEASE_STATUS_SENT]: 'Sent',
  [RELEASE_STATUS_NOT_SENT]: 'Not Sent',
}
