import { get } from 'lodash'
import Vue from 'vue'
import { axiosApi } from '@/axios'
import { CancelToken } from 'axios'
import documentPdf from './documentPdf'
import documentPrint from './documentPrint'
import documentEmail from './documentEmail'

let cancelRequest

function getInitialState() {
  return {
    number: null,
    numberPending: false,
    booking: null,
    bookingPending: false,
    containersValue: null,

    file: null,
    filePending: false,

    AESITN: null,
    AESITNPending: false,

    deleteCommodityPending: false,
  }
}

export default {
  namespaced: true,
  modules: {
    documentPdf,
    documentPrint,
    documentEmail,
  },
  state: getInitialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState())
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
    addCommodities(state, { containerId, customerId, commodities }) {
      const containersValue = state.containersValue
      const container = containersValue[containerId]
      if (!container[customerId]) {
        container[customerId] = []
        Vue.set(container, customerId, [])
      }

      const newCommodities = container[customerId].concat(commodities)
      containersValue[containerId][customerId] = newCommodities
      Vue.set(state, 'containersValue', { ...containersValue })
    },
    removeCommodity(
      state,
      { containerId, customerId, commodityIndex, commodityId }
    ) {
      const container = state.containersValue[containerId]

      if (!commodityIndex) {
        commodityIndex = container[customerId].findIndex(
          (c) => c.id === commodityId
        )
      }

      Vue.delete(container[customerId], commodityIndex)

      if (!container[customerId].length) {
        Vue.delete(container, customerId)
      }

      Vue.set(
        state.containersValue,
        containerId,
        JSON.parse(JSON.stringify(container))
      )
    },
    replaceCommodity(
      state,
      { containerId, customerId, commodityIndex, commodity }
    ) {
      const container = state.containersValue[containerId]

      Vue.set(container[customerId], commodityIndex, commodity)
      Vue.set(
        state.containersValue,
        containerId,
        JSON.parse(JSON.stringify(container))
      )
    },
    setCommodityAESITN(
      state,
      { commodityId, truckingId, containerId, containerItemId, aes_itn }
    ) {
      const trucking = state.booking.trucking_information.find(
        (item) => item.id === truckingId
      )
      const container = trucking
        ? trucking.containers.find((item) => item.id === containerId)
        : null
      const containerItem = container
        ? container.items.find((item) => item.id === containerItemId)
        : null
      const commodity = containerItem
        ? containerItem.commodities.find((item) => item.id === commodityId)
        : null
      if (!commodity) return
      Vue.set(commodity, 'aes_itn', aes_itn)
    },
  },
  actions: {
    load({ commit }, id) {
      if (cancelRequest) {
        cancelRequest()
        cancelRequest = null
      }

      commit('setProperty', ['filePending', true])

      return axiosApi
        .get(`shipping_files/${id}`, {
          cancelToken: new CancelToken((c) => (this.cancelRequest = c)),
        })
        .then(({ data }) => {
          const file = data.attributes
          commit('setProperty', ['file', file])
          commit('setProperty', ['number', file.number])
          commit('setProperty', ['booking', file.booking])
          commit('setProperty', ['AESITN', file.aes_itn])

          const containersValue = createContainersValue(
            file.booking.trucking_information
          )
          commit('setProperty', ['containersValue', containersValue])

          file.booking.trucking_information.forEach((trucking) => {
            trucking.containers.forEach((container) => {
              container.items.forEach((item) => {
                commit('addCommodities', {
                  containerId: container.id,
                  customerId: item.customer && item.customer.id,
                  commodities: item.commodities,
                })
              })
            })
          })
          return data
        })
        .finally(() => {
          commit('setProperty', ['filePending', false])
        })
    },
    saveFile({ state }, id) {
      const sendData = {
        number: state.number,
        booking: state.booking.id,
      }

      const containersValue = JSON.parse(JSON.stringify(state.containersValue))

      for (const containerId in containersValue) {
        const customers = containersValue[containerId]
        for (const customerId in customers) {
          customers[customerId] = customers[customerId].map((item) => item.id)
        }
      }

      Object.assign(sendData, containersValue)

      return axiosApi.post(`/shipping_files/${id || ''}`, sendData)
    },
    loadBooking({ commit, dispatch }, { id }) {
      commit('setProperty', ['bookingPending', true])

      return axiosApi
        .get(`/bookings/${id}`)
        .then(({ data }) => {
          const containersValue = createContainersValue(
            data.attributes.trucking_information
          )
          commit('setProperty', ['booking', data.attributes])
          commit('setProperty', ['containersValue', containersValue])

          dispatch('loadNumber')
        })
        .finally(() => {
          commit('setProperty', ['bookingPending', false])
        })
    },
    loadNumber({ state, commit }) {
      return axiosApi
        .get('/shipping_files/get_next_number', {
          params: {
            booking: state.booking.id,
          },
        })
        .then(({ data }) => {
          commit('setProperty', ['number', data.number])
        })
    },
    saveNumber({ commit }, { id, number }) {
      if (!id) {
        commit('setProperty', ['number', number])
        return Promise.resolve()
      }

      commit('setProperty', ['numberPending', true])

      return (
        axiosApi
          .post(`/shipping_files/${id}/number`, {
            number,
          })
          .then(({ data }) => {
            commit('setProperty', ['number', data.data.attributes.number])
          })
          // .catch((e) => {
          //   Vue.prototype.$errorCatchDefault(e)
          // })
          .finally(() => {
            commit('setProperty', ['numberPending', false])
          })
      )
    },
    addAESITN({ commit }, { id, aes_itn }) {
      commit('setProperty', ['AESITNPending', true])

      return axiosApi
        .post(`/shipping_files/${id}/aes_itn`, {
          aes_itn,
        })
        .then(({ data }) => {
          commit('setProperty', ['AESITN', data.data.attributes.aes_itn])
        })
        .finally(() => {
          commit('setProperty', ['AESITNPending', false])
        })
    },
    removeCommodity({ state, commit }, props) {
      const { commodityId } = props

      if (!state.file) {
        commit('removeCommodity', props)
        return Promise.resolve()
      }

      commit('setProperty', ['deleteCommodityPending', true])

      return (
        axiosApi
          .delete(
            `/shipping_files/${state.file.id}/delete-commodity/${commodityId}`
          )
          .then(() => {
            commit('removeCommodity', props)
          })
          // .catch(Vue.prototype.$errorCatchDefault)
          .finally(() => {
            commit('setProperty', ['deleteCommodityPending', false])
          })
      )
    },
  },
  getters: {
    containers(state) {
      return get(state, 'booking.trucking_information', []).reduce(
        (result, trucking) => {
          return result.concat(trucking.containers)
        },
        []
      )
    },
    addedCustomers(state) {
      let customers = []

      for (const key in get(state, 'containersValue', {})) {
        if (state.containersValue[key]) {
          customers = customers.concat(state.containersValue[key])
        }
      }

      return customers
    },
    shippingFileCustomers(state, getters) {
      return get(getters, 'containers', []).reduce((result, container) => {
        container.items.forEach((commodity) => {
          if (!result.some((c) => c.id === commodity.customer.id)) {
            result.push(commodity.customer)
          }
        })

        return result
      }, [])
    },
  },
}

function createContainersValue(truckingList) {
  return truckingList.reduce((result, trucking) => {
    trucking.containers.forEach((container) => {
      result[container.id] = {}
    })

    return result
  }, {})
}
