import { isPlainObject } from 'lodash'

export default function (errors) {
  if (!isPlainObject(errors)) return {}

  const result = {}

  for (const path in errors) {
    if (!Object.prototype.hasOwnProperty.call(errors, path)) continue

    const keys = path.split('.')

    parseError(result, keys, errors[path])
  }

  return result
}

function parseError(result, keys, error) {
  if (keys.length > 1) {
    if (!isPlainObject(result[keys[0]]) && !Array.isArray(result[keys[0]])) {
      if (isNaN(parseFloat(keys[1]))) {
        result[keys[0]] = {}
      } else {
        result[keys[0]] = []
      }
    }

    let key = keys.shift()
    if (Array.isArray(result)) {
      key = parseFloat(key)
    }
    parseError(result[key], keys, error)
  } else {
    result[keys[0]] = error
  }
}
