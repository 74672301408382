import { get } from 'lodash'
import Vue from 'vue'
import createPaymentReceivedBlanks from '@/actions/createPaymentReceivedBlanks'
import {
  ATTACHMENT_KEY_PAYMENTS_RECEIVED,
  ATTACHMENT_TYPE_FORM_DATA,
} from '@/constants/mail'

function getState() {
  return {
    pending: false,
    templates: [],
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    addToMail({ commit, dispatch }, paymentsReceived) {
      commit('setProperty', ['pending', true])

      return createPaymentReceivedBlanks(paymentsReceived)
        .then(async (result) => {
          // const emailPlaceholders = {
          //   InvoiceNumber: result[0].model.inputs.number,
          //   InvoiceDate: Vue.filter('dateFormat')(result[0].model.inputs.dates.invoice_date),
          //   Total: Vue.filter('dollarFormat')(result[0].model.inputs.total),
          //   CompanyName: result[0].model.inputs.customer.attributes.company_name,
          // }

          // let templates = await axiosApi.get('/settings/email_templates/invoices/')
          // templates = get(templates, 'data.data', [])
          //
          // if (templates.length) {
          //   templates.forEach(item => {
          //     for (let key in emailPlaceholders) {
          //       const regex = new RegExp('{{' + key + '}}', "gi")
          //       item.attributes.subject = item.attributes.subject.replace(regex, emailPlaceholders[key])
          //       item.attributes.message = item.attributes.message.replace(regex, emailPlaceholders[key])
          //     }
          //   })
          //
          //   commit('setProperty', ['templates', templates])
          // }

          const attachments = result.map(({ model, formData }) => {
            return {
              id: model.id,
              name: `Payment receipt ${model.inputs.number}`,
              attachment: formData,
              type: ATTACHMENT_TYPE_FORM_DATA,
            }
          })

          const sendTo = get(result, '[0].model.customer.emails[0].value', null)
          dispatch(
            'mail/addToMail',
            {
              // templates,
              sendTo: [sendTo],
              attachments: {
                [ATTACHMENT_KEY_PAYMENTS_RECEIVED]: attachments,
              },
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
}
