import Vue from 'vue'
import createCustomerBlank from '@/actions/createCustomerBlank'
import {
  ATTACHMENT_KEY_CUSTOMERS,
  ATTACHMENT_TYPE_FORM_DATA,
} from '@/constants/mail'
import { axiosApi } from '@/axios'
import { get } from 'lodash'

function getState() {
  return {
    pending: false,
    templates: [],
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    addToMail({ commit, dispatch }, customers) {
      commit('setProperty', ['pending', true])

      return createCustomerBlank(customers)
        .then(async (result) => {
          const emailPlaceholders = {
            CompanyName: result[0].model.company_name,
          }

          let templates = await axiosApi.get(
            '/settings/email_templates/customers/'
          )
          // .catch(Vue.prototype.$errorCatchDefault)
          templates = get(templates, 'data.data', [])

          if (templates.length) {
            templates.forEach((item) => {
              for (let key in emailPlaceholders) {
                const regex = new RegExp('{{' + key + '}}', 'gi')
                item.attributes.subject = item.attributes.subject.replace(
                  regex,
                  emailPlaceholders[key]
                )
                item.attributes.message = item.attributes.message.replace(
                  regex,
                  emailPlaceholders[key]
                )
              }
            })

            commit('setProperty', ['templates', templates])
          }

          const attachments = result.map(({ model, formData }) => {
            return {
              id: model.id,
              name: `Customer ${model.first_name || ''} ${
                model.last_name || ''
              } ${model.company_name ? `(${model.company_name})` : ''}`,
              attachment: formData,
              type: ATTACHMENT_TYPE_FORM_DATA,
            }
          })

          dispatch(
            'mail/addToMail',
            {
              templates,
              attachments: {
                [ATTACHMENT_KEY_CUSTOMERS]: attachments,
              },
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
}
