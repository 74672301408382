import createBlanksByModels from './createBlanksByModels'
import BCustomer from '@/components/Blanks/BCustomer'
import { axiosApi } from '@/axios'

function loadModel(id) {
  return axiosApi.get(`/customers/${id}`)
}

export default function (models) {
  return createBlanksByModels({
    models,
    loadModelsMethod: loadModel,
    blankComponent: BCustomer,
    blankComponentPropKey: 'person',
  })
}
