import { get } from 'lodash'
import Vue from 'vue'
import setInputsErrors from '@/helpers/errors/setInputsErrors'

const getInitialState = () => {
  return {
    pending: false,
    loaded: false,
    isMuted: false,
    inputs: {
      facility_location: {
        value: null,
        saveFullObject: true,
        error: null,
      },
      yard: {
        value: null,
        saveFullObject: true,
        error: null,
      },
      lot: {
        value: null,
        saveFullObject: true,
        error: null,
      },
      place: {
        value: null,
        saveFullObject: true,
        error: null,
      },
    },
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState())
    },
    resetErrors(state) {
      const inputs = state.inputs
      for (let key in inputs) {
        inputs[key].error = null
      }
    },
    clearSection(state) {
      const newState = getInitialState()
      Object.assign(state.inputs, newState.inputs)
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
    setPropertyByPath(state, [path, key, value]) {
      const obj = get(state, path)
      if (!obj) return

      Vue.set(obj, key, value)
    },
    setMuted(state, data = true) {
      state.isMuted = data
    },
    setInputValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.inputs, name)) return
      state.inputs[name].value = value
    },
    setInputErrors(state, errors) {
      setInputsErrors(state.inputs, errors)
    },
    setAll(state, data) {
      for (let key in state.inputs) {
        state.inputs[key].value = data.inputs[key]
      }
    },
  },
  actions: {
    setAll({ commit }, commodity) {
      commit('reset')

      if (commodity && commodity.facility_location) {
        commit('setAll', commodity.facility_location)

        commit('setProperty', ['created', true])
      }

      commit('setMuted', false)
    },
    setErrors({ commit }, errors) {
      if (!errors) return
      commit('setInputErrors', errors.inputs)
    },
  },
}
