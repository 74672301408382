import createBlanksByModels from './createBlanksByModels'
import BInvoice from '@/components/Blanks/BInvoice'
import { axiosApi } from '@/axios'

function loadModel(id) {
  return axiosApi.get(`/invoices/${id}`)
}

export default function (models) {
  return createBlanksByModels({
    models,
    loadModelsMethod: loadModel,
    blankComponent: BInvoice,
    blankComponentPropKey: 'invoice',
  })
}
