// import Vue from 'vue'
import RootContainer from './RootContainer'

export default {
  install(Vue) {
    const root = new Vue({
      name: 'VLightboxPlugin',
      data: {
        gallery: {
          isOpen: false,
          files: [],
          currentIndex: 0,
          transitionDuration: 300,
        },
      },
      render: (createElement) => createElement(RootContainer),
    })

    root.$mount(document.body.appendChild(document.createElement('div')))
    root.$on('close', () => {
      root.$vLightbox.close()
    })

    Vue.prototype.$vLightbox = {
      open(value) {
        if (!value) return

        if (typeof value === 'string') {
          root.gallery.files = [value]
        } else if (Array.isArray(value) && value.length) {
          root.gallery.files = value
        } else if (isValueCorrectObject(value)) {
          root.gallery.files = value.files
          root.gallery.currentIndex = value.currentIndex
        } else {
          return
        }

        root.gallery.isOpen = true
        document.documentElement.addEventListener('keyup', closeByESC)
        this.blockScroll()
      },
      close() {
        root.gallery.isOpen = false
        root.gallery.files = []
        document.documentElement.removeEventListener('keyup', closeByESC)

        this.unBlockScroll()
      },
      blockScroll() {
        if (document.documentElement.classList.contains('_modal-open')) return
        const scrollWidth = getScrollWidth()
        document.documentElement.style.overflowY = 'hidden'
        document.documentElement.style.paddingRight = scrollWidth + 'px'
      },
      unBlockScroll() {
        if (document.documentElement.classList.contains('_modal-open')) return
        document.documentElement.style.overflowY = null
        document.documentElement.style.paddingRight = null
      },
    }

    Vue.directive('lightbox', {
      inserted(el) {
        el.classList.add('_lightbox')
      },
      bind(el, binding, vnode) {
        vnode.$vLightbox = 1
        el.removeEventListener('click', el.$vLightbox)

        el.$vLightbox = function (e) {
          onTriggerClick(e, binding)
        }

        el.addEventListener('click', el.$vLightbox)
      },
      update(el, binding) {
        el.removeEventListener('click', el.$vLightbox)

        el.$vLightbox = function (e) {
          onTriggerClick(e, binding)
        }

        el.addEventListener('click', el.$vLightbox)
      },
    })

    function onTriggerClick(e, binding) {
      if (binding.modifiers.prevent) {
        e.preventDefault()
      }

      const value = binding.value

      root.$vLightbox.open(value)
    }

    function closeByESC(e) {
      if (e.key === 'Escape' && e.target.closest('.v-lightbox')) {
        root.$vLightbox.close()
      }
    }
  },
}

function isValueCorrectObject(value) {
  return (
    typeof value === 'object' &&
    Object.prototype.hasOwnProperty.call(value, 'files') &&
    value.files.length
  )
}

function getScrollWidth() {
  return (
    window.innerWidth - document.documentElement.clientWidth ||
    parseFloat(getComputedStyle(document.documentElement).paddingRight) ||
    0
  )
}
