import roundTo from '@/helpers/roundTo'
import Vue from 'vue'
import { get } from 'lodash'
import paymentReceivedEditInvoices from './paymentReceivedEditInvoices'
import { axiosApi } from '@/axios'
import storeFormMixins from '../../common/storeFormMixins'
import parseErrors from '@/helpers/errors/parseErrors'
import getCurrentDateISO from '@/helpers/getCurrentDateISO'
import getAmountToPay from '@/helpers/paymentsReceived/getAmountToPay'
import isInvoiceOverpaid from '@/helpers/paymentsReceived/isInvoiceOverpaid'

function getInitState() {
  return {
    pendingLoadingNumber: false,
    pendingSave: false,
    paymentReceived: null,
    overFormErrorMixin: null,
    inputs: {
      customer: {
        value: null,
      },
      amount_received: {
        value: null,
      },
      bank_charges: {
        value: null,
      },
      number: {
        value: null,
      },
      payment_date: {
        value: {
          value: getCurrentDateISO(),
          with_time: false,
        },
      },
      payment_method: {
        value: null,
      },
      reference_number: {
        value: null,
      },
    },
    files: [],
    filesToDeleting: [],
  }
}

export default {
  namespaced: true,
  modules: {
    paymentReceivedEditInvoices,
  },
  state: getInitState(),
  getters: {
    amountNeedForInvoices(state) {
      const invoices = state.paymentReceivedEditInvoices.invoices || []

      return invoices.reduce((sum, invoice) => {
        return roundTo(sum + getAmountToPay(invoice), 2)
      }, 0)
    },
    amountReceived(state) {
      return roundTo(Number(state.inputs.amount_received.value) || 0, 2)
    },
    amountApplied(state, getters) {
      const invoices = getters['paymentReceivedEditInvoices/getInvoicesToSave']

      return invoices.reduce((sum, invoice) => {
        return roundTo(sum + Number(invoice.payment_input.value), 2)
      }, 0)
    },
    amountUnapplied(state, getters) {
      return roundTo(getters['amountReceived'] - getters['amountApplied'], 2)
    },
    canSave(state, getters) {
      if (getters['amountUnapplied'] < 0) return false

      return !getters['paymentReceivedEditInvoices/getInvoicesToSave'].some(
        (invoice) => {
          return isInvoiceOverpaid(invoice)
        }
      )
    },
  },
  mutations: {
    ...storeFormMixins.mutations,
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
    setInput(state, [key, value]) {
      Vue.set(state.inputs[key], 'value', value)
    },
    setAmount(state, amount) {
      state.inputs.amount_received.value = roundTo(amount, 2) || 0
    },
    addNote(state) {
      Vue.set(state.inputs, 'note', {
        value: null,
      })
    },
    deleteNote(state) {
      Vue.delete(state.inputs, 'note')
    },

    //FILES
    uploadFiles(state, files) {
      state.files = state.files.concat(files)
    },
    deleteFiles(state, index) {
      const file = state.files[index]
      if (file.id) {
        state.filesToDeleting.push(file.id)
      }

      Vue.delete(state.files, index)
    },

    parsePaymentReceived(state, paymentReceived) {
      const inputs = state.inputs

      if (paymentReceived.inputs.note) {
        inputs.note = { value: null }
      }

      for (const key in inputs) {
        const input = inputs[key]
        const value = paymentReceived.inputs[key] || paymentReceived[key]

        Vue.set(input, 'value', value)
      }

      state.files = paymentReceived.files || []
      state.paymentReceived = paymentReceived
    },
  },
  actions: {
    ...storeFormMixins.actions,
    resetState({ commit }) {
      commit('resetState')
      commit('paymentReceivedEditInvoices/resetState')
    },
    loadPaymentReceived({ commit }, paymentReceivedId) {
      commit('setProperty', ['pendingLoadingNumber', true])

      return (
        axiosApi
          .get(`payments_received/${paymentReceivedId}`)
          .then(({ data }) => {
            commit('parsePaymentReceived', data.data.attributes)
            commit(
              'paymentReceivedEditInvoices/parseInvoicesFromPaymentReceived',
              data.data.attributes
            )
          })
          // .catch(Vue.prototype.$errorCatchDefault)
          .finally(() => {
            commit('setProperty', ['pendingLoadingNumber', false])
          })
      )
    },
    save({ state, getters, commit, dispatch }) {
      dispatch('resetErrors')
      commit('setProperty', ['pendingSave', true])

      const formData = new FormData()

      // INPUTS
      for (const key in state.inputs) {
        let value = state.inputs[key].value

        if (value && Object.prototype.hasOwnProperty.call(value, 'with_time')) {
          formData.append(`${key}[with_time]`, value.with_time)
          formData.append(`${key}[value]`, value.value)
        } else if (value && value.id) {
          value = value.id
          formData.append(key + '_id', value)
        } else {
          formData.append(key, value || '')
        }
      }

      // FILES
      state.files.forEach((file) => {
        if (!file.file) return
        formData.append('files[]', file.file)
      })

      // DELETE FILES
      state.filesToDeleting.forEach((id) => {
        formData.append('remove_files[]', id)
      })

      // INVOICES FOR PAYMENT
      const invoices = getters['paymentReceivedEditInvoices/getInvoicesToSave']

      invoices.forEach((invoice, index) => {
        const value = Number(invoice.payment_input.value)

        if (!value) return

        formData.append(`items[${index}][invoice_id]`, invoice.id)
        formData.append(`items[${index}][amount]`, value)
        if (invoice.item_id) {
          formData.append(`items[${index}][id]`, invoice.item_id)
        }
      })

      const id = state.paymentReceived ? state.paymentReceived.id : ''

      return axiosApi
        .post(`payments_received/${id}`, formData)
        .then((data) => {
          return data
        })
        .catch((e) => {
          dispatch('errorHandleMixin', { e })
          if (get(e, 'response.data.errors')) {
            const errors = parseErrors(e.response.data.errors)
            const itemsErrors = errors.items
            commit(
              'paymentReceivedEditInvoices/parseInvoicesErrors',
              itemsErrors
            )
          }

          throw e
        })
        .finally(() => {
          commit('setProperty', ['pendingSave', false])
        })
    },
    loadNumber({ commit }) {
      commit('setProperty', ['pendingLoadingNumber', true])

      return (
        axiosApi
          .get(`/payments_received/get_next_check_number`)
          .then(({ data }) => {
            commit('setInput', ['number', data.item.attributes.number])
          })
          // .catch(Vue.prototype.$errorCatchDefault)
          .finally(() => {
            commit('setProperty', ['pendingLoadingNumber', false])
          })
      )
    },
    applyReceivedAmount({ state, getters, commit }) {
      let amount = getters['amountReceived']

      const invoices = state.paymentReceivedEditInvoices.invoices || []

      for (const invoice of invoices) {
        const needToPay = getAmountToPay(invoice)

        let toPay = needToPay > amount ? amount : needToPay
        amount = roundTo(amount - toPay, 2)
        amount = amount < 0 ? 0 : amount

        // console.log(toPay)
        commit('paymentReceivedEditInvoices/invoicePaymentAmountInput', [
          invoice.id,
          toPay,
        ])
      }
    },
    setFullAmount({ state, getters, commit }) {
      commit('setAmount', getters['amountNeedForInvoices'])

      const invoices = state.paymentReceivedEditInvoices.invoices || []
      invoices.forEach((invoice) => {
        commit('paymentReceivedEditInvoices/invoicePayFullAmount', invoice.id)
      })
    },
    resetErrors({ commit }) {
      commit('formClearErrorsMixin')
      commit('paymentReceivedEditInvoices/setProperty', [
        'invoicesInputsErrors',
        null,
      ])
    },
  },
}
