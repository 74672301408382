let scrollTimer = null

function isInViewport(element) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export default async function (selector, force = false, wait = 500) {
  clearTimeout(scrollTimer)
  return new Promise((resolve) => {
    scrollTimer = setTimeout(() => {
      const el = document.querySelector(selector)
      if (el && (force || !isInViewport(el))) {
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
          inline: 'center',
        })
        resolve(el)
      } else {
        resolve(el)
      }
    }, wait)
  })
}
