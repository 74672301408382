import {
  STATUS_PENDING_SHIPPING,
  STATUS_CONTAINER_LOADED,
  STATUS_CONTAINER_INGATED,
  STATUS_CONTAINER_SAILED,
  STATUS_CONTAINER_ARRIVED,
  STATUS_SHIPPED_RELEASE_STATUS,
} from '@/constants/shippingFiles'

const statuses = [
  STATUS_PENDING_SHIPPING,
  STATUS_CONTAINER_LOADED,
  STATUS_CONTAINER_INGATED,
  STATUS_CONTAINER_SAILED,
  STATUS_CONTAINER_ARRIVED,
  STATUS_SHIPPED_RELEASE_STATUS,
].join('|')

import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

const ShippingFiles = () =>
  import(/* webpackChunkName: "ShippingFiles" */ '@/views/Main/Files')
const ShippingFile = () =>
  import(/* webpackChunkName: "ShippingFile" */ '@/views/Main/Files/File')
const ShippingFileEdit = () =>
  import(
    /* webpackChunkName: "ShippingFileEdit" */ '@/views/Main/Files/FileEdit'
  )

export const defaultShippingFilesRoute = {
  name: 'shipping-files',
  params: {
    status: STATUS_PENDING_SHIPPING,
  },
}

export default [
  {
    path: 'shipping-files/new-shipping-file',
    name: 'new-shipping-file',
    component: ShippingFileEdit,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SHIPPING_FILES],
      title: 'New Shipping File',
    },
  },
  {
    path: 'shipping-files/:id(\\d+)',
    name: 'edit-shipping-file',
    component: ShippingFileEdit,
    meta: {
      permission: [abilityActions.manage, abilitySubjects.SHIPPING_FILES],
      title: 'Edit Shipping File',
    },
  },
  {
    path: `shipping-files/:status(\\${statuses})?`,
    name: 'shipping-files',
    meta: {
      permission: [abilityActions.read, abilitySubjects.SHIPPING_FILES],
      title: 'Shipping Files',
    },
    component: ShippingFiles,
    beforeEnter(to, from, next) {
      if (!to.params.status) {
        return next(defaultShippingFilesRoute)
      }

      next()
    },
    children: [
      {
        path: 'file/:fileId',
        name: 'shipping-file',
        component: ShippingFile,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SHIPPING_FILES],
        },
      },
    ],
  },
]
