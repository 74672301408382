import Vue from 'vue'
import createShippingFileBlanks from '@/actions/createShippingFileBlanks'
import {
  ATTACHMENT_KEY_SHIPPING_FILE,
  ATTACHMENT_TYPE_FORM_DATA,
} from '@/constants/mail'

function getState() {
  return {
    pending: false,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    addToMail({ commit, dispatch }, shippingFiles) {
      commit('setProperty', ['pending', true])

      return createShippingFileBlanks(shippingFiles)
        .then((result) => {
          const attachments = result.map(({ shippingFile, formData }) => {
            return {
              id: shippingFile.id,
              name: `Shipping ${shippingFile.number}`,
              attachment: formData,
              type: ATTACHMENT_TYPE_FORM_DATA,
            }
          })

          const numbers = result.map(({ shippingFile }) => shippingFile.number)
          const subject =
            numbers.length > 1
              ? `Shipping files ${numbers.join(', ')}`
              : `Shipping file ${numbers[0]}`

          dispatch(
            'mail/addToMail',
            {
              subject,
              message: null,
              attachments: {
                [ATTACHMENT_KEY_SHIPPING_FILE]: attachments,
              },
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
}
