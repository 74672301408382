import Vue from 'vue'
import BasePreloader from '@/components/BasePreloader'
import BaseSvg from '@/components/BaseSvg'

Vue.component('BaseSvg', BaseSvg)
Vue.component('BasePreloader', BasePreloader)

Vue.component('BaseActions', () =>
  import(/*webpackChunkName: "BaseActions" */ '@/components/BaseActions')
)
Vue.component('BaseAlert', () =>
  import(/*webpackChunkName: "BaseAlert" */ '@/components/BaseAlert')
)
Vue.component('BaseCheckbox', () =>
  import(/*webpackChunkName: "BaseCheckbox" */ '@/components/BaseCheckbox')
)
Vue.component('BaseDropdown', () =>
  import(/*webpackChunkName: "BaseDropdown" */ '@/components/BaseDropdown')
)
Vue.component('BaseHelpTooltip', () =>
  import(
    /*webpackChunkName: "BaseHelpTooltip" */ '@/components/BaseHelpTooltip'
  )
)
Vue.component('BaseTooltip', () =>
  import(/*webpackChunkName: "BaseTooltip" */ '@/components/BaseTooltip')
)
Vue.component('BaseInput', () =>
  import(/*webpackChunkName: "BaseInput" */ '@/components/BaseInput')
)
Vue.component('BasePagination', () =>
  import(/*webpackChunkName: "BasePagination" */ '@/components/BasePagination')
)
Vue.component('BaseRadioList', () =>
  import(/*webpackChunkName: "BaseRadioList" */ '@/components/BaseRadioList')
)
Vue.component('BaseSelect', () =>
  import(/*webpackChunkName: "BaseSelect" */ '@/components/BaseSelect')
)
Vue.component('BaseSvgFiles', () =>
  import(/*webpackChunkName: "BaseSvgFiles" */ '@/components/BaseSvgFiles')
)
Vue.component('BaseDateShow', () =>
  import(/*webpackChunkName: "BaseDateShow" */ '@/components/BaseDateShow')
)
