import moment from '@/helpers/useMoment'

export default function getCurrentDateISO() {
  const date = moment()

  return moment({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  }).toISOString()
}
