export const RELATED_TO_SHIPPING_FILE_ID = 1
export const RELATED_TO_PROJECT_ID = 2
export const RELATED_TO_TYPES = [
  {
    id: RELATED_TO_SHIPPING_FILE_ID,
    name: 'Shipping file',
  },
  {
    id: RELATED_TO_PROJECT_ID,
    name: 'Project',
  },
]
