import roundTo from '@/helpers/roundTo'
import Vue from 'vue'

Vue.filter('dollarFormat', function (value, noValue = '') {
  if (!value && typeof value !== 'number') return noValue

  let res = value
  if (typeof res === 'string') {
    res = Number(res)
  }

  if (isNaN(res)) return value

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(roundTo(res, 2))
})
