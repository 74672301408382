var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[(_vm.isOpen)?_c('div',{staticClass:"modal",class:{
      _current: _vm.isCurrent,
      '_not-current': !_vm.isCurrent,
      '_full-height': _vm.fullHeight,
      '_small-title': _vm.passThroughProps?.smallTitle,
    },style:(`z-index: ${91 + _vm.currentIndex}`),attrs:{"data-modal-name":_vm.name},on:{"mousedown":_vm.onMousedown,"mouseup":_vm.onMouseup}},[_c('div',{ref:"content",staticClass:"modal__content"},[_c('div',{staticClass:"modal__head"},[(_vm.icoFormatted)?_c('BaseSvg',{staticClass:"modal__title-ico",attrs:{"name":_vm.icoFormatted.name,"width":_vm.icoFormatted.width,"height":_vm.icoFormatted.height}}):_vm._e(),_c('h2',{staticClass:"modal__title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.currentModalObj.title || _vm.title)+" ")]},null,{
              ..._vm.passThroughProps,
              _headTitle: _vm.currentModalObj.title,
            })],2),_c('a',{staticClass:"modal__close",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('BaseSvg',{attrs:{"name":"ico-close"}})],1)],1),_c('div',{key:_vm.refreshKey,tag:"component",staticClass:"modal__main"},[_vm._t("default",function(){return [_vm._v("No Data ")]},{"title":undefined,"setFullHeight":_vm.setFullHeight},_vm.passThroughProps)],2)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }