import getNavInner from '../common/getNavInner'
import getRoutesInner from '../common/getRoutesInner'
import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'
import {
  customerConfigs,
  employeeConfigs,
  paymentAccountConfigs,
  vendorConfigs,
} from '@/constants/personsConfigs'
// import concatPath from "../helpers/concatPath"

//Persons
const Salutations = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/Salutations'
  )
const PreferLanguage = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/PreferLanguage'
  )
const PhoneTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/PhoneTypes'
  )
const EmailTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/EmailTypes'
  )
const SiteTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/SiteTypes'
  )
const DocumentType = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/DocumentType'
  )
const AddressTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/AddressTypes'
  )
const SocialTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Global/SocialTypes'
  )
//Sales
const SalesTerms = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Sales/SalesTerms'
  )
// import SalesFormVariants from '../../views/Main/Lists/Sales/SalesFormVariants'
// import SalesConditions from "../../views/Main/Lists/Sales/SalesConditions"
const SalesGeneratorFrequency = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Sales/SalesGeneratorFrequency'
  )
const SalesPaymentMethods = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Sales/SalesPaymentMethods'
  )
const SalesCategories = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Sales/SalesCategories'
  )
const SalesTax = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Sales/SalesTax'
  )
const SalesDepositBankInstructions = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Sales/SalesDepositBankInstructions'
  )
//Expenses
const ExpensesPaymentMethods = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Expenses/ExpensesPaymentMethods'
  )
// import ExpenseCategories from '../../views/Main/Lists/Expenses/ExpenseCategories'
//Commodities
const CommodityType = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityType'
  )
const CommodityPackageType = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityPackageType'
  )
const CommodityDocumentType = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityDocumentType'
  )
const CommodityDocumentLocation = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityDocumentLocation'
  )
const CommodityLoadingPlace = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityLoadingPlace'
  )
const CommodityPortOfLoading = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityPortOfLoading'
  )
const CommodityPlaceOfDelivery = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityPlaceOfDelivery'
  )
const CommodityPortsOfDischarge = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityPortsOfDischarge'
  )
const CommodityTrailerType = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityTrailerType'
  )
const CommodityTerms = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityTerms'
  )
const CommodityPaymentMethods = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityPaymentMethods'
  )
const CommodityConditions = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityConditions'
  )
const CommodityColor = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityColor'
  )
const CommodityFacilityLocation = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityFacilityLocation'
  )
const CommodityYards = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityYards'
  )
const CommodityLots = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityLots'
  )
const CommodityPlaces = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityPlaces'
  )
const CommodityStates = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityStates'
  )
const CommodityDocumentStatus = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityDocumentStatus'
  )
const CommodityConsignees = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityConsignees'
  )
const CommodityNotifyPart = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityNotifyPart'
  )
const CommodityShipper = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityShipper'
  )
const CommodityOrigins = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityOrigins'
  )
const CommodityDestinations = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityDestinations'
  )
const CommodityReasons = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityReasons'
  )
// import CommodityPlaceOfDelivery from "../../views/Main/Settings/Commodities/CommodityPlaceOfDelivery"

//Bookings
// import PackagesTypes from '../../views/Main/Lists/Bookings/PackagesTypes'
// import BookingCarriers from '../../views/Main/Settings/Bookings/BookingCarriers'
// import ContainersTypes from '../../views/Main/Lists/Bookings/ContainersTypes'
// import UltimateDestinations from '../../views/Main/Lists/Bookings/UltimateDestinations'
const BookingCountriesOfOrigin = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingCountriesOfOrigin'
  )
const BookingConsignees = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingConsignees'
  )
const BookingNotifyParties = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingNotifyParties'
  )
const BookingShippers = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingShippers'
  )
const BookingForwardingAgent = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingForwardingAgent'
  )
// import Origins from '../../views/Main/Lists/Bookings/Origins'
const BookingPlacesOfDelivery = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingPlacesOfDelivery'
  )
const BookingPlacesOfReceipts = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingPlacesOfReceipts'
  )
const BookingPortsOfLoading = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingPortsOfLoading'
  )
// import Piers from '../../views/Main/Lists/Bookings/Piers'
const BookingPortsOfDischarge = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingPortsOfDischarge'
  )
const BookingTypesToMove = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingTypesToMove'
  )
const BookingIntermediateConsignees = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingIntermediateConsignees'
  )
const BookingEmptyContainerPickUpLocations = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingEmptyContainerPickUpLocations'
  )
// import TruckerInstructions from '../../views/Main/Lists/Bookings/TruckerInstructions'
// import BookingTruckingCompanies from '../../views/Main/Settings/Bookings/BookingTruckingCompanies'
// import Remarks from '../../views/Main/Lists/Bookings/Remarks'
// const CommodityTypes = () => import(/*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingCommodityTypes')
const BookingPiers = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingPiers'
  )
const BookingVessels = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingVessels'
  )
const BookingVoyages = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingVoyages'
  )
const BookingEmptyContainerDropLocations = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingEmptyContainerDropLocations'
  )
const BookingLoadedContainerPickUpLocations = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingLoadedContainerPickUpLocations'
  )
const BookingLoadedContainerDropLocations = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingLoadedContainerDropLocations'
  )
const BookingContainerType = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingContainerType'
  )
const BookingCommodityType = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Bookings/BookingCommodityTypes'
  )
const CommodityCountries = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Commodities/CommodityCountries'
  )
const DeductionTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/TimeLog/DeductionTypes'
  )
const SalesSubtotalDetails = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Sales/SalesSubtotalDetails'
  )
const Inventories = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Inventories/Inventories'
  )

//Shipping files

const ShippingFilesEmailForDocuments = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/ShippingFiles/ShippingFilesEmailForDocuments'
  )
const ShippingFilesViberForDocuments = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/ShippingFiles/ShippingFilesViberForDocuments'
  )
const ShippingFilesEmailForCustomerDocuments = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/ShippingFiles/ShippingFilesEmailForCustomerDocuments'
  )
const ShippingFilesViberForCustomerDocuments = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/ShippingFiles/ShippingFilesViberForCustomerDocuments'
  )

//Email Templates
const EmailTemplatesForEstimates = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForEstimates'
  )
const EmailTemplatesForInvoices = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForInvoices'
  )
const EmailTemplatesForCustomers = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForCustomers'
  )
const EmailTemplatesForShippingFilesDocuments = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForShippingFilesDocuments'
  )
const EmailTemplatesForBookings = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForBookings'
  )
const EmailTemplatesForCommodityFiles = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForCommodityFiles'
  )
const EmailTemplatesForReports = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForReports'
  )
const EmailTemplatesForProjects = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/EmailTemplates/EmailTemplatesForProjects.vue'
  )

// Company
const CompanyOffices = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Company/CompanyOffices'
  )
const CompanyDepartments = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Company/CompanyDepartments'
  )
const CompanyTitles = () =>
  import(
    /*webpackChunkName: "settings" */ '../../views/Main/Settings/Company/CompanyTitles'
  )

const PaymentAccountTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '@/views/Main/Settings/PaymentAccount/PaymentAccountTypes'
  )

const SalesDepositTypes = () =>
  import(
    /*webpackChunkName: "settings" */ '@/views/Main/Settings/Sales/SalesDepositTypes'
  )
const Industries = () =>
  import(
    /*webpackChunkName: "settings" */ '@/views/Main/Settings/Global/Industries'
  )

// - - - -

const rootPath = '/settings/'

const settingsItems = [
  {
    menuName: 'Customers',
    subItems: [
      {
        path: 'customers/salutations',
        menuName: 'Salutations',
        component: Salutations,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'customers/prefer-language',
        menuName: 'Prefer language',
        component: PreferLanguage,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'customers/phone-types',
        menuName: 'Phone types',
        component: PhoneTypes,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'customers/email-types',
        menuName: 'Email types',
        component: EmailTypes,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'customers/address-types',
        menuName: 'Address types',
        component: AddressTypes,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'customers/document-types',
        menuName: 'Document types',
        component: DocumentType,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'customers/site-types',
        menuName: 'Site types',
        component: SiteTypes,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'customers/social-types',
        menuName: 'Social types',
        component: SocialTypes,
        props: {
          configs: customerConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Vendors',
    subItems: [
      {
        path: 'vendors/salutations',
        menuName: 'Salutations',
        component: Salutations,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'vendors/prefer-language',
        menuName: 'Prefer language',
        component: PreferLanguage,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'vendors/phone-types',
        menuName: 'Phone types',
        component: PhoneTypes,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'vendors/email-types',
        menuName: 'Email types',
        component: EmailTypes,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'vendors/address-types',
        menuName: 'Address types',
        component: AddressTypes,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'vendors/document-type',
        menuName: 'Document types',
        component: DocumentType,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'vendors/site-types',
        menuName: 'Site types',
        component: SiteTypes,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'vendors/social-types',
        menuName: 'Social types',
        component: SocialTypes,
        props: {
          configs: vendorConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Employees',
    subItems: [
      {
        path: 'employees/salutations',
        menuName: 'Salutations',
        component: Salutations,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'employees/prefer-language',
        menuName: 'Prefer language',
        component: PreferLanguage,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'employees/phone-types',
        menuName: 'Phone types',
        component: PhoneTypes,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'employees/email-types',
        menuName: 'Email types',
        component: EmailTypes,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'employees/address-types',
        menuName: 'Address types',
        component: AddressTypes,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'employees/document-type',
        menuName: 'Document types',
        component: DocumentType,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'employees/site-types',
        menuName: 'Site types',
        component: SiteTypes,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'employees/social-types',
        menuName: 'Social types',
        component: SocialTypes,
        props: {
          configs: employeeConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Payment Accounts',
    subItems: [
      {
        path: 'payment-account/salutations',
        menuName: 'Salutations',
        component: Salutations,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/prefer-language',
        menuName: 'Prefer language',
        component: PreferLanguage,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/phone-types',
        menuName: 'Phone types',
        component: PhoneTypes,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/email-types',
        menuName: 'Email types',
        component: EmailTypes,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/address-types',
        menuName: 'Address types',
        component: AddressTypes,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/document-type',
        menuName: 'Document types',
        component: DocumentType,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/site-types',
        menuName: 'Site types',
        component: SiteTypes,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/social-types',
        menuName: 'Social types',
        component: SocialTypes,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'payment-account/account-types',
        menuName: 'Account types',
        component: PaymentAccountTypes,
        props: {
          configs: paymentAccountConfigs,
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Sales',
    subItems: [
      {
        path: 'sales/terms',
        menuName: 'Terms',
        component: SalesTerms,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        //   path: 'sales/form-variant',
        //   menuName: '- Form variants',
        //   component: SalesFormVariants
        // }, {
        //   path: 'sales/conditions',
        //   menuName: '- Conditions',
        //   component: SalesConditions
        // }, {
        path: 'sales/generator-frequency',
        menuName: 'Generator frequency',
        component: SalesGeneratorFrequency,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'sales/payment-methods',
        menuName: 'Payment methods',
        component: SalesPaymentMethods,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'sales/subtotal-details',
        menuName: 'Subtotal details',
        component: SalesSubtotalDetails,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'sales/categories',
        menuName: 'Categories',
        component: SalesCategories,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'sales/tax',
        menuName: 'Taxes',
        component: SalesTax,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'sales/deposits-bank-instructions', //todo: remove deposits-bank-instructions?
        menuName: 'Deposits bank instructions',
        component: SalesDepositBankInstructions,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'sales/deposit-types',
        menuName: 'Deposit types',
        component: SalesDepositTypes,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Expenses',
    subItems: [
      {
        path: 'expenses/payment-methods',
        menuName: 'Payment methods',
        component: ExpensesPaymentMethods,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
        // }, {
        //   path: 'expenses/terms',
        //   menuName: '? Terms',
        //   component: Terms
        // }, {
        //   path: 'expenses/late-fees',
        //   menuName: '? LateFees',
        //   component: LateFees
        // }, {
        //   path: 'expenses/categories',
        //   menuName: '- Categories',
        //   component: ExpenseCategories
      },
    ],
  },
  {
    menuName: 'Commodities',
    subItems: [
      {
        path: 'commodities/type',
        menuName: 'Type',
        component: CommodityType,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/package-type',
        menuName: 'Package type',
        component: CommodityPackageType,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/document-type',
        menuName: 'Document type',
        component: CommodityDocumentType,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/states',
        menuName: 'States',
        component: CommodityStates,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/document-status',
        menuName: 'Document status',
        component: CommodityDocumentStatus,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/document-location',
        menuName: 'Document location',
        component: CommodityDocumentLocation,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/loading-place',
        menuName: 'Loading place',
        component: CommodityLoadingPlace,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/port-of-loading',
        menuName: 'Port of loading',
        component: CommodityPortOfLoading,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/ports-of-discharge',
        menuName: 'Ports of discharge',
        component: CommodityPortsOfDischarge,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/place-of-delivery',
        menuName: 'Place of delivery',
        component: CommodityPlaceOfDelivery,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        // 	path: 'commodities/ship-to',
        // 	menuName: '- Ship to',
        // 	component: CommodityShipTo
        // }, {
        //   path: 'commodities/place-of-delivery',
        //   menuName: 'Places of delivery',
        //   component: CommodityPlaceOfDelivery
        // }, {
        path: 'commodities/countries',
        menuName: 'Country ultimate destination',
        component: CommodityCountries,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/consignees',
        menuName: 'Consignees',
        component: CommodityConsignees,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/notify-part',
        menuName: 'Notify parties',
        component: CommodityNotifyPart,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/shipper',
        menuName: 'Shippers',
        component: CommodityShipper,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/origin',
        menuName: 'Origins',
        component: CommodityOrigins,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/destination',
        menuName: 'Destinations',
        component: CommodityDestinations,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/trailer-type',
        menuName: 'Trailer type',
        component: CommodityTrailerType,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/terms',
        menuName: 'Terms',
        component: CommodityTerms,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/payment-methods',
        menuName: 'Payment methods',
        component: CommodityPaymentMethods,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/conditions',
        menuName: 'Conditions',
        component: CommodityConditions,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/colors',
        menuName: 'Colors',
        component: CommodityColor,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/facility-locations',
        menuName: 'Facility locations',
        component: CommodityFacilityLocation,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/areas',
        menuName: 'Areas',
        component: CommodityYards,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/lots',
        menuName: 'Lots',
        component: CommodityLots,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/places',
        menuName: 'Places',
        component: CommodityPlaces,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'commodities/reasons',
        menuName: 'Reasons',
        component: CommodityReasons,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Bookings',
    subItems: [
      {
        path: 'bookings/commodity-types',
        menuName: 'Commodity Type',
        component: BookingCommodityType,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        //   path: 'bookings/carriers',
        //   menuName: 'Carriers',
        //   component: BookingCarriers
        // }, {
        path: 'bookings/countries-of-origin',
        menuName: 'Countries of origin',
        component: BookingCountriesOfOrigin,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/types-to-move',
        menuName: 'Types to move',
        component: BookingTypesToMove,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/places-of-receipts',
        menuName: 'Places of receipts',
        component: BookingPlacesOfReceipts,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/ports-of-loading',
        menuName: 'Ports of loading',
        component: BookingPortsOfLoading,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/ports-of-discharge',
        menuName: 'Ports of discharge',
        component: BookingPortsOfDischarge,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/places-of-delivery',
        menuName: 'Places of delivery',
        component: BookingPlacesOfDelivery,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/piers',
        menuName: 'Piers',
        component: BookingPiers,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/vessels',
        menuName: 'Vessels',
        component: BookingVessels,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/voyages',
        menuName: 'Voyages',
        component: BookingVoyages,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/shippers',
        menuName: 'Shippers',
        component: BookingShippers,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        // 	path: 'bookings/packages-types',
        // 	menuName: '? Packages types',
        // 	component: PackagesTypes
        // }, {
        // 	path: 'bookings/containers-types',
        // 	menuName: '? Containers types',
        // 	component: ContainersTypes
        // }, {
        // 	path: 'bookings/ultimate-destinations',
        // 	menuName: '? Ultimate destinations',
        // 	component: UltimateDestinations
        // }, {
        path: 'bookings/consignees',
        menuName: 'Consignees',
        component: BookingConsignees,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/notify-parties',
        menuName: 'Notify parties',
        component: BookingNotifyParties,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/forwarding-agents',
        menuName: 'Forwarding Agents',
        component: BookingForwardingAgent,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/intermediate-consignees',
        menuName: 'Intermediate consignees',
        component: BookingIntermediateConsignees,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        // 	path: 'bookings/origins',
        // 	menuName: '? Origins',
        // 	component: Origins
        // }, {
        // 	path: 'bookings/final-destinations',
        // 	menuName: '? Final destinations',
        // 	component: FinalDestinations
        // },{
        // 	path: 'bookings/piers',
        // 	menuName: '? Piers',
        // 	component: Piers
        // },{
        // 	path: 'bookings/trucker-instructions',
        // 	menuName: '? Trucker instructions',
        // 	component: TruckerInstructions
        // }, {
        //   path: 'bookings/trucking-companies',
        //   menuName: 'Trucking companies',
        //   component: BookingTruckingCompanies
        // }, {
        path: 'bookings/container-pick-up-locations',
        menuName: 'Empty container pick up locations',
        component: BookingEmptyContainerPickUpLocations,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/empty-container-drop-locations',
        menuName: 'Empty container drop locations',
        component: BookingEmptyContainerDropLocations,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/loaded-container-pick-up-locations',
        menuName: 'Loaded container pick up locations',
        component: BookingLoadedContainerPickUpLocations,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/loaded-container-drop-locations',
        menuName: 'Loaded container drop locations',
        component: BookingLoadedContainerDropLocations,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'bookings/container-type',
        menuName: 'Container type',
        component: BookingContainerType,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Shipping files',
    subItems: [
      {
        path: 'shipping-files/email-for-documents',
        menuName: 'Emails for documents',
        component: ShippingFilesEmailForDocuments,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'shipping-files/viber-for-documents',
        menuName: 'Vibers for documents',
        component: ShippingFilesViberForDocuments,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'shipping-files/email-for-customer-documents',
        menuName: 'Emails for customer documents',
        component: ShippingFilesEmailForCustomerDocuments,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'shipping-files/viber-for-customer-documents',
        menuName: 'Vibers for customer documents',
        component: ShippingFilesViberForCustomerDocuments,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Projects',
    subItems: [
      {
        path: 'projects/industries',
        menuName: 'Industries',
        component: Industries,
        props: {
          configs: {
            listName: 'Projects',
            httpBaseLink: 'projects',
          },
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'projects/phone-types',
        menuName: 'Phone types',
        component: PhoneTypes,
        props: {
          configs: {
            listName: 'Projects',
            httpBaseLink: 'projects',
          },
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'projects/email-types',
        menuName: 'Email types',
        component: EmailTypes,
        props: {
          configs: {
            listName: 'Projects',
            httpBaseLink: 'projects',
          },
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'projects/address-types',
        menuName: 'Address types',
        component: AddressTypes,
        props: {
          configs: {
            listName: 'Projects',
            httpBaseLink: 'projects',
          },
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'projects/document-types',
        menuName: 'Document types',
        component: DocumentType,
        props: {
          configs: {
            listName: 'Projects',
            httpBaseLink: 'projects',
          },
        },
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Time log',
    subItems: [
      {
        path: 'time-log/deduction-types',
        menuName: 'Deduction types',
        component: DeductionTypes,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Inventories',
    subItems: [
      {
        path: 'inventories/names',
        menuName: 'Names',
        component: Inventories,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Email templates',
    subItems: [
      {
        path: 'email-templates/customers',
        menuName: 'Customers',
        component: EmailTemplatesForCustomers,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'email-templates/estimates',
        menuName: 'Estimates',
        component: EmailTemplatesForEstimates,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'email-templates/invoices',
        menuName: 'Invoices',
        component: EmailTemplatesForInvoices,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'email-templates/bookings',
        menuName: 'Bookings',
        component: EmailTemplatesForBookings,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'email-templates/shipping-files-documents',
        menuName: 'Shipping files documents',
        component: EmailTemplatesForShippingFilesDocuments,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'email-templates/commodity-files',
        menuName: 'Commodity files',
        component: EmailTemplatesForCommodityFiles,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'email-templates/reports',
        menuName: 'Reports',
        component: EmailTemplatesForReports,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'email-templates/projects',
        menuName: 'Projects',
        component: EmailTemplatesForProjects,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
  {
    menuName: 'Company',
    subItems: [
      {
        path: 'company/departments',
        menuName: 'Departments',
        component: CompanyDepartments,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'company/offices',
        menuName: 'Offices',
        component: CompanyOffices,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
      {
        path: 'company/titles',
        menuName: 'Titles',
        component: CompanyTitles,
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
        },
      },
    ],
  },
]

// - - - -

export function getNav() {
  return getNavInner(settingsItems, rootPath)
}

export function getRoutes() {
  return getRoutesInner(settingsItems, rootPath)
}
