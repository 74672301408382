import createBlanksByModels from './createBlanksByModels'
import BPaymentReceived from '@/components/Blanks/BPaymentReceived'
import { axiosApi } from '@/axios'

function loadModel(id) {
  return axiosApi.get(`/payments_received/${id}`)
}

export default function (models) {
  return createBlanksByModels({
    models,
    loadModelsMethod: loadModel,
    blankComponent: BPaymentReceived,
    blankComponentPropKey: 'paymentReceived',
  })
}
