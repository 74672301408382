import concatPath from '../../helpers/concatPath'

export default function getNavInner(arr, rootPath) {
  let nav = []

  for (const item of arr) {
    let curNav = getNavItem(item, rootPath)
    if (curNav) nav.push(curNav)

    if (Array.isArray(item.subItems)) {
      let subNav = getNavInner(item.subItems, rootPath)
      if (subNav.length) curNav.subItems = subNav
    }
  }

  return nav
}

function getNavItem(item, rootPath) {
  let a = {
    menuName: item.menuName,
  }

  if (item.path) a.path = concatPath(rootPath, item.path)

  return a
}
