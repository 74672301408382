<template>
  <transition name="modal">
    <VAccept v-if="isOpen" />
  </transition>
</template>

<script>
import VAccept from './VAccept'

export default {
  name: 'VAcceptRoot',
  components: {
    VAccept,
  },
  data() {
    return {}
  },
  computed: {
    isOpen() {
      return this.$root.isOpen
    },
  },
}
</script>
