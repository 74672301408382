import createBlanksByModels from './createBlanksByModels'
import BDeposit from '@/components/Blanks/BDeposit'
import { axiosApi } from '@/axios'

function loadModel(id) {
  return axiosApi.get(`/deposits/${id}`)
}

export default function (models) {
  return createBlanksByModels({
    models,
    loadModelsMethod: loadModel,
    blankComponent: BDeposit,
    blankComponentPropKey: 'deposit',
  })
}
