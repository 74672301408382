import person from '../PersonStoreInstance'
import pdf from './pdf'
import print from './print'
import email from './email'
import { customerConfigs } from '@/constants/personsConfigs'

export default {
  namespaced: true,
  modules: {
    pdf,
    print,
    email,
  },
  state: {
    props: { ...customerConfigs },
    ...person.getState(),
  },
  mutations: {
    ...person.getMutations(),
  },
  actions: {
    ...person.getActions(),
  },
  getters: {
    ...person.getGetters(),
  },
}
