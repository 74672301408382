import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import setTitle from './common/setTitle'
import checkAuth from './common/checkAuth'
import $store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  linkActiveClass: '_active',
  linkExactActiveClass: '_active-exact',
})

router.beforeEach(async (to, from, next) => {
  if (
    !to.params.force &&
    from.name === 'banking-payment-account-reconcile-item'
  ) {
    if ($store.getters['hasUnsavedChanges']) {
      const isAccept = await new Promise((resolve, reject) => {
        Vue.prototype.$vAccept.open({
          title: 'Leave this page?',
          text: 'If accepted, you will lose all unsaved changes!',
          onAccept: () => {
            resolve(true)
          },
          onCancel: () => {
            reject(false)
          },
        })
      }).catch(() => {
        return false
      })

      if (!isAccept) {
        return false
      }
    }
  } else {
    $store.commit('setUnsaved', false)
  }

  if (to.path !== from.path && Vue.prototype.$vModal) {
    Vue.prototype.$vModal.close()
  }
  await checkAuth(to, from, next)
})

router.beforeEach((to, from, next) => {
  setTitle(to)
  next()
})

export default router
