<template>
  <div class="blank-deposit">
    <IncludesForBlanks />
    <div class="blank-deposit__content">
      <table class="blank-deposit__head">
        <tbody>
          <tr>
            <td style="width: 25%">
              <img
                :src="companyLogo"
                width="130"
                height="40"
                :alt="companyName"
                class="blank-deposit__logo"
              />
            </td>
            <td class="text" v-html="companyInfo"></td>
            <td>
              <div class="blank-deposit__name">Deposit</div>
              <div class="blank-deposit__number">{{ inputs.number }}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blank-deposit__info">
        <table class="blank-deposit__main-info">
          <tbody>
            <tr>
              <td class="col-8">
                <table class="blank-deposit__info-table">
                  <tr>
                    <td>Deposit Date</td>
                    <td>{{ inputs.deposit_date.value | dateFormat }}</td>
                  </tr>
                  <tr
                    v-if="
                      $get(inputs, 'banking_date.value') &&
                      moment(inputs.banking_date.value).isValid()
                    "
                  >
                    <td>Banking date</td>
                    <td>{{ inputs.banking_date.value | dateFormat }}</td>
                  </tr>
                  <tr>
                    <td>Payment account</td>
                    <td>
                      <template v-if="$get(deposit, 'inputs.payment_account')">
                        {{
                          $get(deposit, 'inputs.payment_account.account_name')
                        }}
                        /
                        {{
                          $get(
                            deposit,
                            'inputs.payment_account.account_type.name'
                          )
                        }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>Agent</td>
                    <td>{{ deposit.agent }}</td>
                  </tr>
                </table>
              </td>
              <td>
                <div class="blank-deposit__amount-wrap">
                  <h4 class="blank-deposit__amount-title">
                    {{
                      $get(
                        deposit,
                        'inputs.deposit_type.name',
                        'Deposit Amount'
                      )
                    }}
                  </h4>
                  <p class="blank-deposit__amount">
                    {{ deposit.amount | dollarFormat }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="!$get(deposit, 'inputs.deposit_type')"
          class="blank-deposit__payments-wrap"
        >
          <h3 class="blank-deposit__payments-title">Payments received</h3>
          <table class="blank-deposit__payments-table light-table d-table">
            <thead>
              <tr>
                <th class="_payment">Payment #</th>
                <th class="_customer">Customer</th>
                <th class="_amount">Amount</th>
                <th class="_bank-instr">Pay. method</th>
                <th class="_date">Date</th>
                <th class="_agent">Agent</th>
                <th class="_note">Note</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in paymentsReceived" :key="payment.id">
                <td>{{ payment.inputs.number }}</td>
                <td>
                  {{ payment.customer.first_name }}
                  {{ payment.customer.last_name }}
                  <div
                    v-if="payment.customer.company_name"
                    style="font-weight: bold"
                  >
                    {{ payment.customer.company_name }}
                  </div>
                </td>
                <td>{{ payment.inputs.amount_received | dollarFormat }}</td>
                <td>
                  {{ payment.payment_method && payment.payment_method.name }}
                </td>
                <td>{{ payment.inputs.payment_date | dateFormat }}</td>
                <td>{{ payment.agent }}</td>
                <td>{{ payment.inputs.note }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-else-if="$get(deposit, 'inputs.description')"
          class="blank-deposit__description"
        >
          <h3 class="blank-deposit__description-title">Description:</h3>
          <div
            class="blank-deposit__description-text"
            v-html="$get(deposit, 'inputs.description')"
          ></div>
        </div>

        <div v-if="inputs.note" class="blank-deposit__note">
          <div class="blank-deposit__note-title">Note</div>
          <p>{{ inputs.note }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IncludesForBlanks from './common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-deposit.less'
import formatAddress from '@/helpers/formatAddress'
import moment from '@/helpers/useMoment'

export default {
  components: {
    IncludesForBlanks,
  },
  props: {
    deposit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wasMounted: false,
    }
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
    inputs() {
      return this.deposit.inputs || {}
    },
    paymentsReceived() {
      return this.deposit.paymentsReceived.map((p) => p.attributes)
    },
  },
  mounted() {
    this.wasMounted = true
  },
  methods: {
    moment,
    getStyles() {
      return styles
    },
    formatAddress(...args) {
      return formatAddress(...args)
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/css-blanks/blank-deposit.less';
</style>
