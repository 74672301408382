<template>
  <table class="blank-booking__table">
    <colgroup>
      <col />
      <col />
      <col />
      <col />
    </colgroup>
    <tr>
      <td rowspan="2" colspan="2">
        <div class="blank-booking__cell-title">Carrier</div>
        <div v-html="getInfoForDocs(inputs.carrier)" class="text"></div>
      </td>
      <td>
        <div class="blank-booking__cell-title">Country of origin</div>
        {{ inputs.country_of_origin && inputs.country_of_origin.name }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Type to move</div>
        {{ inputs.type_to_move && inputs.type_to_move.name }}
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding-left: 1rem">
        <div class="blank-booking__cell-title">Reference remark</div>
        <pre
          v-if="inputs.reference_remark"
          v-html="inputs.reference_remark"
          class="text"
        ></pre>
      </td>
    </tr>
    <tr>
      <td>
        <div class="blank-booking__cell-title">Place of receipt</div>
        <div
          v-html="getInfoForDocs(inputs.place_of_receipt)"
          class="text"
        ></div>
      </td>
      <td>
        <div class="blank-booking__cell-title">Port of loading</div>
        <div v-html="getInfoForDocs(inputs.port_of_loading)" class="text"></div>
      </td>
      <td>
        <div class="blank-booking__cell-title">Port of discharge</div>
        <div
          v-html="getInfoForDocs(inputs.port_of_discharge)"
          class="text"
        ></div>
        <div class="blank-booking__cell-title">Pier</div>
        {{ inputs.pier && inputs.pier.name }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Place of delivery</div>
        <div
          v-html="getInfoForDocs(inputs.place_of_delivery)"
          class="text"
        ></div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="blank-booking__cell-title">Vessel</div>
        {{ inputs.vessel && inputs.vessel.name }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Voyage</div>
        {{ inputs.voyage && inputs.voyage.name }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Commodity type</div>
        {{ $get(inputs, 'commodity_type.name', '') }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Pre carriage by</div>
        {{ inputs.pre_carriage_by }}
      </td>
    </tr>
    <tr>
      <td>
        <div class="blank-booking__cell-title">Earliest release</div>
        {{ inputs.dates && inputs.dates.earliest_release | dateFormat }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Earliest return</div>
        {{ inputs.dates && inputs.dates.earliest_return | dateFormat }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Container cut-off</div>
        {{ inputs.dates && inputs.dates.container_cut_off | dateFormat }}
      </td>
      <td>
        <div class="blank-booking__cell-title">Documentation due by</div>
        {{ inputs.dates && inputs.dates.documentation_due_by | dateFormat }}
      </td>
    </tr>
    <tr>
      <td>
        <div class="blank-booking__cell-title">AES/ITN do by</div>
        {{ inputs.dates && inputs.dates.aes_itn | dateFormat }}
      </td>
      <td>
        <div class="blank-booking__cell-title">ETS</div>
        {{ inputs.dates && inputs.dates.ets | dateFormat }}
      </td>
      <td>
        <div class="blank-booking__cell-title">ETA</div>
        {{ inputs.dates && inputs.dates.eta | dateFormat }}
      </td>
      <td>
        <div class="blank-booking__cell-title">To be completed by</div>
        {{ inputs.dates && inputs.dates.to_be_completed_by | dateFormat }}
      </td>
    </tr>
    <tr>
      <td>
        <div class="blank-booking__cell-title">Shipper</div>
        <div v-html="getInfoForDocs(inputs.shipper)" class="text"></div>
      </td>
      <td>
        <div class="blank-booking__cell-title">Consignee</div>
        <div v-html="getInfoForDocs(inputs.consignee)" class="text"></div>
      </td>
      <td>
        <div class="blank-booking__cell-title">Notify party</div>
        <div v-html="getInfoForDocs(inputs.notify_party)" class="text"></div>
      </td>
      <td>
        <div class="blank-booking__cell-title">Forwarding agent</div>
        <div
          v-html="getInfoForDocs(inputs.forwarding_agent)"
          class="text"
        ></div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="blank-booking__cell-title">Intermediate consignee</div>
        <div
          v-html="getInfoForDocs(inputs.intermediate_consignee)"
          class="text"
        ></div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'BookingTable',
  props: {
    inputs: {
      type: Object,
      required: true,
    },
    getInfoForDocs: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/css-blanks/blank-booking.less';
</style>
