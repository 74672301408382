import Vue from 'vue'

Vue.directive('click-no-selection', {
  bind(el, binding) {
    const handler = (event) => {
      if (window.getSelection().toString()) {
        event.preventDefault()
      } else {
        el.__clickNoSelection__ &&
          el.__clickNoSelection__.callback &&
          el.__clickNoSelection__.callback(event)
      }
    }

    el.__clickNoSelection__ = {
      handler,
      callback: binding.value,
    }

    el.addEventListener('click', handler)
  },

  unbind(el) {
    el.__clickNoSelection__ &&
      el.removeEventListener('click', el.__clickNoSelection__.handler)
    delete el.__clickNoSelection__
  },
})
