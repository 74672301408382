import Vue from 'vue'

Vue.filter('phoneToLink', function (value) {
  if (!value) return ''
  value = value.toString()
  value = value.replace(/[^0-9]/gim, '')

  if (value.length === 12) value = '+' + value
  return 'tel:' + value
})
