import Vue from 'vue'

export default function setInputsErrors(inputsObj, errorsObj) {
  if (!inputsObj || !errorsObj) return

  for (let key in errorsObj) {
    let inputKey = key.split('.')[0]

    if (Object.prototype.hasOwnProperty.call(inputsObj, inputKey)) {
      Vue.set(inputsObj[inputKey], 'error', errorsObj[key])
    }
  }
}
