<template>
  <transition name="modal">
    <div
      v-if="isOpen"
      :data-modal-name="name"
      class="modal"
      @mousedown="onMousedown"
      @mouseup="onMouseup"
      :style="`z-index: ${91 + currentIndex}`"
      :class="{
        _current: isCurrent,
        '_not-current': !isCurrent,
        '_full-height': fullHeight,
        '_small-title': passThroughProps?.smallTitle,
      }"
    >
      <div class="modal__content" ref="content">
        <div class="modal__head">
          <BaseSvg
            v-if="icoFormatted"
            :name="icoFormatted.name"
            :width="icoFormatted.width"
            :height="icoFormatted.height"
            class="modal__title-ico"
          />
          <h2 class="modal__title">
            <slot
              v-bind="{
                ...passThroughProps,
                _headTitle: currentModalObj.title,
              }"
              name="title"
            >
              {{ currentModalObj.title || title }}
            </slot>
          </h2>
          <a href="#" class="modal__close" @click.prevent="close">
            <BaseSvg name="ico-close" />
          </a>
        </div>
        <component :is="'div'" class="modal__main" :key="refreshKey">
          <slot
            v-bind="passThroughProps"
            :title="undefined"
            :setFullHeight="setFullHeight"
            >No Data
          </slot>
        </component>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VModal',
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    ico: {
      type: [Object, String],
    },
    refreshTriggerPropKey: {
      type: String,
      default() {
        return 'id'
      },
    },
  },
  data() {
    return {
      pressTarget: false,
      fullHeight: false,
      refreshKey: 1,
    }
  },
  computed: {
    lastModalsNames() {
      return this.$get(this.$root, `lastModalsNames`, [])
    },
    isOpen() {
      return this.lastModalsNames.includes(this.name)
    },
    currentModalObj() {
      return this.$get(this.$root, `modals[${this.name}]`, {})
    },
    passThroughProps() {
      return this.$get(this.currentModalObj, 'props', {})
    },
    icoFormatted() {
      let ico = null

      if (typeof this.ico === 'string') {
        ico = {
          name: this.ico,
        }
      } else {
        ico = this.ico
      }

      if (typeof ico !== 'object') return null

      // if (!ico.width) ico.width = 20
      // if (!ico.height) ico.height = 20

      return ico
    },
    isCurrent() {
      return this.name === this.lastModalsNames[this.lastModalsNames.length - 1]
    },
    currentIndex() {
      const index = this.lastModalsNames.findIndex((item) => item === this.name)
      return index < 0 ? 0 : index
    },
    watchData() {
      return this.$get(
        this,
        `currentModalObj.props.${this.refreshTriggerPropKey}`,
        null
      )
    },
  },
  methods: {
    setFullHeight(val) {
      this.fullHeight = val
    },
    onMousedown(event) {
      this.pressTarget = event.target.contains(this.$refs.content)
    },
    onMouseup(event) {
      if (this.pressTarget && event.target.contains(this.$refs.content)) {
        this.close()
      }
    },
    close() {
      this.$vModal.close(this.name)
    },
  },
  watch: {
    watchData: {
      handler(to, from) {
        if (this.isCurrent && to && from) {
          this.refreshKey++
        }
      },
    },
  },
}
</script>
