import Vue from 'vue'
import { cloneDeep } from 'lodash'

const initialState = {
  isMuted: false,
  initialData: null,
  inputs: {
    booking_number: {
      value: null,
      required: true,
    },
    country_of_origin: {
      value: null,
    },
    carrier: {
      value: null,
    },
    type_to_move: {
      value: null,
    },
    reference_remark: {
      value: null,
    },
    place_of_receipt: {
      value: null,
    },
    port_of_loading: {
      value: null,
      required: true,
    },
    port_of_discharge: {
      value: null,
      required: true,
    },
    pier: {
      value: null,
    },
    place_of_delivery: {
      value: null,
      required: false,
    },
    vessel: {
      value: null,
    },
    voyage: {
      value: null,
    },
    commodity_type: {
      value: null,
    },
    forwarding_agent: {
      value: null,
    },
    shipper: {
      value: null,
    },
    consignee: {
      value: null,
    },
    intermediate_consignee: {
      value: null,
    },
    notify_party: {
      value: null,
    },
    remark: {
      value: null,
      enabled: false,
    },
    pre_carriage_by: {
      value: null,
    },
  },
  dates: {
    earliest_release: {
      value: null,
    },
    earliest_return: {
      value: null,
    },
    container_cut_off: {
      value: null,
    },
    documentation_due_by: {
      value: null,
    },
    aes_itn: {
      value: null,
    },
    ets: {
      value: null,
    },
    eta: {
      value: null,
    },
    to_be_completed_by: {
      value: null,
    },
  },
}

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: {
    setMuted(state, data = true) {
      state.isMuted = data
    },
    reset(state) {
      Object.assign(state, cloneDeep(initialState))
    },
    setAll(state, inputs) {
      for (let key in state.inputs) {
        state.inputs[key].value = inputs[key]
      }
      for (let key in state.dates) {
        state.dates[key].value = inputs.dates[key]
      }
    },
    setInitialData(state, data) {
      state.initialData = cloneDeep(data)
    },
    setInputValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.inputs, name)) return
      state.inputs[name].value = value || null
    },
    setInputsErrorMultiple(state, errors) {
      for (let key in errors) {
        const { name, error } = errors[key]

        if (!Object.prototype.hasOwnProperty.call(state.inputs, name)) return
        Vue.set(state.inputs[name], 'error', error || null)
      }
    },
    setDateValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.dates, name)) return
      state.dates[name].value = value || null
    },
    setDatesErrorMultiple(state, errors) {
      for (let key in errors) {
        const { name, error } = errors[key]

        if (!Object.prototype.hasOwnProperty.call(state.dates, name)) return
        Vue.set(state.dates[name], 'error', error || null)
      }
    },
    toggleRemark(state) {
      state.inputs.remark.enabled = !state.inputs.remark.enabled
    },
    clearErrorsAll(state) {
      for (let key in state.inputs) {
        Vue.delete(state.inputs[key], 'error')
      }

      for (let key in state.dates) {
        Vue.delete(state.dates[key], 'error')
      }
    },
  },
  actions: {},
}
