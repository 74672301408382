import isNumeric from '@/helpers/isNumeric'

export default function (value = 0, precision = 0) {
  if (value === 0) return 0
  if (typeof value === 'string' || !isNumeric(value)) {
    console.warn(
      `"${typeof value}: ${value}" - is not number type, there may be problems with calculations`
    )
    return NaN
  }

  const multiplier = Math.pow(10, precision)
  const newValue = parseFloat((Math.abs(value) * multiplier).toFixed(11))
  return (Math.round(newValue) / multiplier) * Math.sign(value)
}
