import parseErrors from './parseErrors'

/**
 * @param {Error} e Error from axios
 * @returns {Object} Sum of a and b or an array that contains a, b and the sum of a and b.
 */
export default function errorHandle(e) {
  let result = {
    overFormError: null,
    formErrors: null,
  }

  if (!e.response || !e.response.data) {
    if (e.status === 413) {
      result.overFormError = 'File Too Large'
      return result
    }

    result.overFormError = e.message
    return result
  }

  if (e.response.data && e.response.data.errors) {
    if (typeof e.response.data.errors === 'string') {
      result.overFormError = e.response.data.errors
      return result
    }

    result.formErrors = parseErrors(e.response.data.errors)
  }

  if (e.response.data && e.response.data.message) {
    result.overFormError = e.response.data.message
    return result
  }

  result.overFormError = e.message
  return result
}
