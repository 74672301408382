import Counter from '@/helpers/Counter'
import setInputsErrors from '@/helpers/errors/setInputsErrors'
import { STATUS_NEED_TO_BE_DISPATCH } from '@/constants/commoditiesStatuses'
import { get } from 'lodash'
import { axiosApi } from '@/axios'

const counter = new Counter()
const getInitialState = () => {
  return {
    pending: false,
    pendingCheckDeliveryRates: false,
    loaded: false,
    hasSavedFields: false,
    isMuted: false,
    inputs: {
      origin: {
        value: null,
        savedValue: null,
        saveFullObject: true,
        required: true,
        error: null,
      },
      destination: {
        value: null,
        savedValue: null,
        saveFullObject: true,
        required: true,
        error: null,
      },
      trailer_type: {
        value: null,
        savedValue: null,
        saveFullObject: true,
        error: null,
      },
      term: {
        value: null,
        savedValue: null,
        saveFullObject: true,
        error: null,
      },
      pick_up_date: {
        value: null,
        savedValue: null,
        with_time: true,
        error: null,
      },
      delivery_date: {
        value: null,
        savedValue: null,
        with_time: true,
        error: null,
      },
      order_id: {
        value: null,
        savedValue: null,
        error: null,
      },
      price_to_pay_carrier: {
        value: null,
        savedValue: null,
        error: null,
      },
      payment_method: {
        value: null,
        savedValue: null,
        saveFullObject: true,
        error: null,
      },
      price_to_pay_customer: {
        value: null,
        savedValue: null,
        error: null,
      },
    },
    dispatchCanceled: false,
    special_instruction: null,
    notes: [],
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    isRequiredSaved(state) {
      const inputs = state.inputs

      for (const key in inputs) {
        if (inputs[key].required && !inputs[key].savedValue) {
          return false
        }
      }

      return true
    },
    canCancel(state, getters, rootState) {
      const statuses = get(rootState, 'commodity.commodity.statuses', []).map(
        (s) => s.status.replace(' ', '_')
      )

      return (
        statuses.includes(STATUS_NEED_TO_BE_DISPATCH) && !state.dispatchCanceled
      )
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState())
    },
    resetErrors(state) {
      const inputs = state.inputs
      for (let key in inputs) {
        inputs[key].error = null
      }
    },
    clearSection(state) {
      const newState = getInitialState()
      Object.assign(state.inputs, newState.inputs)
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
    setMuted(state, data = true) {
      state.isMuted = data
    },
    setInputValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.inputs, name)) return
      state.inputs[name].value = value
    },
    setInputErrors(state, errors) {
      setInputsErrors(state.inputs, errors)
    },
    addNote(state) {
      state.notes.push(createNote())
    },
    removeNote(state, index) {
      state.notes.splice(index, 1)
    },
    setNoteValue(state, { index, value }) {
      if (!state.notes[index]) return
      state.notes[index].note = value
    },
    addInstruction(state) {
      state.special_instruction = {
        value: null,
        error: null,
      }
    },
    removeInstruction(state) {
      state.special_instruction = null
    },
    setInstructionValue(state, value) {
      state.special_instruction.value = value
    },
    setAll(state, data) {
      let hasSavedFields = false
      const inputs = state.inputs

      for (let key in inputs) {
        const value = data.inputs[key]
        if (value !== null) {
          hasSavedFields = true
        }

        inputs[key].value = value
        inputs[key].savedValue = value
      }

      if (data.special_instruction) {
        state.special_instruction = {
          value: data.special_instruction,
          error: null,
        }
      }

      state.notes = data.notes
      state.dispatchCanceled = !!data.inputs.dispatch_canceled

      if (data.special_instruction || (data.notes && data.notes.length)) {
        hasSavedFields = true
      }

      state.loaded = true
      state.hasSavedFields = hasSavedFields

      // - - - -

      state.isRequiredSaved = true
      for (const key in inputs) {
        if (inputs[key].required && !inputs[key].value) {
          state.isRequiredSaved = false
        }
      }
    },
  },
  actions: {
    setAll({ commit }, commodity) {
      commit('reset')

      if (commodity && commodity.dispatch_list) {
        commit('setAll', commodity.dispatch_list)

        commit('setProperty', ['created', true])
      }

      commit('setMuted', false)
    },
    setErrors({ commit }, errors) {
      if (!errors) return
      commit('setInputErrors', errors.inputs)
    },
    deliveryRateCheck({ rootState, commit }) {
      const origin = get(
        rootState,
        'commodity.deliveryDispatchList.inputs.origin.value'
      )
      const destination = get(
        rootState,
        'commodity.deliveryDispatchList.inputs.destination.value'
      )

      if (!origin || !destination) return

      const commodityMain = get(rootState, 'commodity.main')
      const commodityType = get(commodityMain, 'type')
      const params = {
        commodity_type: commodityType ? commodityType.id : null,
        commodity_packages_type: getPackageType(),
        total_package: getTotalPackages(),

        origin: get(origin, 'id'),
        destination: get(destination, 'id'),
      }

      function getPackageType() {
        if (!commodityType) {
          return (
            get(
              commodityMain.carTypeInputs.commodity_packages_type,
              'value.id'
            ) ||
            get(
              commodityMain.otherTypeInputs.commodity_packages_type,
              'value.id'
            )
          )
        }

        return commodityType.vin_field
          ? get(commodityMain.carTypeInputs.commodity_packages_type, 'value.id')
          : get(
              commodityMain.otherTypeInputs.commodity_packages_type,
              'value.id'
            )
      }

      function getTotalPackages() {
        if (!commodityType) {
          return (
            get(commodityMain.carTypeInputs.total_package, 'value') ||
            get(commodityMain.otherTypeInputs.total_package, 'value') ||
            1
          )
        }

        return commodityType.vin_field
          ? get(commodityMain.carTypeInputs.total_package, 'value') || 1
          : get(commodityMain.otherTypeInputs.total_package, 'value') || 1
      }

      commit('setProperty', ['pendingCheckDeliveryRates', true])

      return axiosApi
        .get('/settings/commodities/delivery-rates', {
          params,
        })
        .then(({ data }) => {
          const rate = get(data, 'data[0]')
          if (!rate) {
            commit('setInputValue', {
              name: 'price_to_pay_carrier',
              value: null,
            })
            commit('setInputValue', {
              name: 'price_to_pay_customer',
              value: null,
            })
            return
          }
          commit('setInputValue', {
            name: 'price_to_pay_carrier',
            value: rate.vendor_rate,
          })
          commit('setInputValue', {
            name: 'price_to_pay_customer',
            value: rate.customer_rate,
          })
        })
        .finally(() => {
          commit('setProperty', ['pendingCheckDeliveryRates', false])
        })
    },
  },
}

function createNote() {
  return {
    _id: counter.value,
    note: null,
  }
}
