import Vue from 'vue'
import createCommodityLabelBlank from '@/actions/createCommodityLabelBlank'
import { axiosApi } from '@/axios'

function getState() {
  return {
    pending: false,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    save({ commit }, commodity) {
      commit('setProperty', ['pending', true])

      return createCommodityLabelBlank([commodity]).then((result) => {
        const newFormData = new FormData()
        result.forEach(({ formData }, index) => {
          for (const [key, value] of formData.entries()) {
            newFormData.append(`item[${index}][${key}]`, value)
          }
        })

        return axiosApi.post('commodities/', newFormData)
      })
    },
  },
}
