import store from '@/store'
import {
  STATUS_CONTAINER_INGATED,
  STATUS_CONTAINER_LOADED,
  STATUS_CONTAINER_SAILED,
  STATUS_CONTAINER_ARRIVED,
  STATUS_PENDING_SHIPPING,
  STATUS_SHIPPED_RELEASE_STATUS,
} from '@/constants/shippingFiles'

const filesStatuses = [
  STATUS_PENDING_SHIPPING,
  STATUS_CONTAINER_LOADED,
  STATUS_CONTAINER_INGATED,
  STATUS_CONTAINER_SAILED,
  STATUS_CONTAINER_ARRIVED,
  STATUS_SHIPPED_RELEASE_STATUS,
].join('|')

import abilitySubjects from '@/services/ability/abilitySubjects'
import abilityActions from '@/services/ability/abilityActions'

export default [
  {
    path: 'customers',
    name: 'customers',
    component: () =>
      import(/*webpackChunkName: "customers" */ '@/views/Main/Customers'),
    meta: {
      permission: [abilityActions.read, abilitySubjects.CUSTOMERS],
      title: 'Customers',
    },
    children: [
      {
        path: ':customerId',
        name: 'one-customer',
        component: () =>
          import(
            /*webpackChunkName: "OneCustomer" */ '@/views/Main/Customers/OneCustomer'
          ),
        redirect: { name: 'customer-overview' },
        meta: {
          permission: [abilityActions.read, abilitySubjects.CUSTOMERS_OVERVIEW],
        },
        children: [
          {
            name: 'customer-overview',
            path: 'overview',
            component: () =>
              import(
                /*webpackChunkName: "CustomerOverview" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerOverview'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_OVERVIEW,
              ],
            },
          },
          {
            name: 'customer-notes',
            path: 'notes',
            component: () =>
              import(
                /*webpackChunkName: "CustomerNotes" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerNotes'
              ),
            meta: {
              permission: [abilityActions.read, abilitySubjects.CUSTOMERS_NOTE],
            },
          },
          {
            name: 'customer-commodities',
            path: 'commodities',
            component: () =>
              import(
                /*webpackChunkName: "CustomerCommodities" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerCommodities'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_COMMODITIES,
              ],
            },
          },
          {
            name: 'customer-commodities-with-status',
            path: 'commodities/:commodityStatus',
            component: () =>
              import(
                /*webpackChunkName: "CustomerCommodities" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerCommodities'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_COMMODITIES,
              ],
            },
          },
          {
            path: 'sales',
            component: () =>
              import(
                /*webpackChunkName: "CustomerSales" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSales'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_SALES,
              ],
            },
            children: [
              {
                path: '',
                name: 'customer-sales',
                redirect: (route) => {
                  return {
                    name: 'cs-estimates',
                    query: store
                      ? store.getters['customers/personRouteQuery'](route)
                      : { ...route.query },
                  }
                },
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SALES_ESTIMATES,
                  ],
                },
              },
              {
                path: 'invoices',
                name: 'cs-invoices',
                component: () =>
                  import(
                    /*webpackChunkName: "CSInvoices" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSales/CSInvoices'
                  ),
                meta: {
                  querySearchName: 'invoices-search',
                  searchPlaceholder: 'Search invoices',
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SALES_INVOICES,
                  ],
                },
              },
              {
                path: 'payments',
                name: 'cs-payment',
                component: () =>
                  import(
                    /*webpackChunkName: "CSPayment" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSales/CSPayment'
                  ),
                meta: {
                  querySearchName: 'payments-search',
                  searchPlaceholder: 'Search payments',
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SALES_PAYMENTS,
                  ],
                },
              },
              {
                path: 'estimates',
                name: 'cs-estimates',
                component: () =>
                  import(
                    /*webpackChunkName: "CSEstimates" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSales/CSEstimates'
                  ),
                meta: {
                  querySearchName: 'estimates-search',
                  searchPlaceholder: 'Search estimates',
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SALES_ESTIMATES,
                  ],
                },
              },
              {
                path: 'discounts',
                name: 'cs-discount',
                component: () =>
                  import(
                    /*webpackChunkName: "CSDiscount" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSales/CSDiscount'
                  ),
                meta: {
                  querySearchName: 'discounts-search',
                  searchPlaceholder: 'Search discounts',
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SALES_DISCOUNTS,
                  ],
                },
              },
              {
                path: 'expenses',
                name: 'cs-expenses',
                component: () =>
                  import(
                    /*webpackChunkName: "CSExpenses" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSales/CSExpenses'
                  ),
                meta: {
                  querySearchName: 'expenses-search',
                  searchPlaceholder: 'Search expenses',
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SALES_EXPENSES,
                  ],
                },
              },
            ],
          },
          {
            name: 'customer-email',
            path: 'email',
            component: () =>
              import(
                /*webpackChunkName: "CustomerEmail" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerEmails'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_EMAILS,
              ],
            },
          },
          {
            name: 'customer-messages',
            path: 'messages',
            component: () =>
              import(
                /*webpackChunkName: "CustomerMessages" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerMessages'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_MESSAGES,
              ],
            },
          },
          {
            name: 'customer-calls',
            path: 'calls',
            component: () =>
              import(
                /*webpackChunkName: "CustomerCalls" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerCalls'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_PONE_CALLS,
              ],
            },
          },
          {
            path: 'settings',
            component: () =>
              import(
                /*webpackChunkName: "CustomerSettings" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_SETTINGS,
              ],
            },
            children: [
              {
                path: '',
                name: 'customer-settings',
                redirect: (route) => {
                  return {
                    name: 'c-settings-commodity-shippers',
                    query: store
                      ? store.getters['customers/personRouteQuery'](route)
                      : { ...route.query },
                  }
                },
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
              {
                path: 'commodity-shippers',
                name: 'c-settings-commodity-shippers',
                component: () =>
                  import(
                    /*webpackChunkName: "CSettingsCommodityShippers" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings/CSettingsCommodityShippers'
                  ),
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
              {
                path: 'commodity-consignees',
                name: 'c-settings-commodity-consignees',
                component: () =>
                  import(
                    /*webpackChunkName: "CSettingsCommodityConsignees" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings/CSettingsCommodityConsignees'
                  ),
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
              {
                path: 'commodity-notify-parties',
                name: 'c-settings-commodity-notify-parties',
                component: () =>
                  import(
                    /*webpackChunkName: "CSettingsCommodityNotifyParties" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings/CSettingsCommodityNotifyParties'
                  ),
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
              {
                path: 'booking-shippers',
                name: 'c-settings-booking-shippers',
                component: () =>
                  import(
                    /*webpackChunkName: "CSettingsCommodityShippers" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings/CSettingsCommodityShippers'
                  ),
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
              {
                path: 'booking-consignees',
                name: 'c-settings-booking-consignees',
                component: () =>
                  import(
                    /*webpackChunkName: "CSettingsCommodityConsignees" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings/CSettingsCommodityConsignees'
                  ),
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
              {
                path: 'booking-intermediate-consignees',
                name: 'c-settings-booking-intermediate-consignees',
                component: () =>
                  import(
                    /*webpackChunkName: "CSettingsCommodityConsignees" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings/CSettingsCommodityConsignees'
                  ),
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
              {
                path: 'booking-notify-parties',
                name: 'c-settings-booking-notify-parties',
                component: () =>
                  import(
                    /*webpackChunkName: "CSettingsCommodityNotifyParties" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerSettings/CSettingsCommodityNotifyParties'
                  ),
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.CUSTOMERS_SETTINGS,
                  ],
                },
              },
            ],
          },
          {
            name: 'customer-log',
            path: 'log',
            component: () =>
              import(
                /*webpackChunkName: "CustomerLogs" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerLogs'
              ),
            meta: {
              permission: [abilityActions.read, abilitySubjects.CUSTOMERS_LOGS],
            },
          },
          {
            name: 'customer-reports',
            path: 'reports/:slug?',
            component: () =>
              import(
                /*webpackChunkName: "CustomerReports" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerReports'
              ),
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_REPORTS,
              ],
            },
          },
          {
            name: 'customer-shipping-files',
            path: `customer-shipping-files/:files_status(\\${filesStatuses})?`,
            component: () =>
              import(
                /*webpackChunkName: "CustomerShippingFiles" */ '@/views/Main/Customers/OneCustomer/CustomerTabs/CustomerShippingFiles'
              ),
            beforeEnter(to, from, next) {
              if (!to.params.files_status) {
                return next({
                  name: 'customer-shipping-files',
                  params: {
                    ...to.params,
                    files_status: STATUS_PENDING_SHIPPING,
                  },
                  query: {
                    search: to.query.search,
                    sort: to.query.sort,
                    status: to.query.status,
                  },
                })
              }

              next()
            },
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.CUSTOMERS_SHIPPING_FILES,
              ],
            },
          },
        ],
      },
    ],
  },
]
