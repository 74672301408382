import { axiosApi } from '@/axios'
import {
  getSelectedCalendarsIds,
  addSelectedCalendarId,
  deleteSelectedCalendarById,
  setSelectedCalendarsIds,
} from '@/services/calendars'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    pending: true,
    loaded: false,
    items: [],
    colors: {},
  },
  mutations: {
    pending(state, pending = false) {
      state.pending = pending
    },
    loaded(state, loaded) {
      state.loaded = loaded
    },
    setItems(state, events) {
      state.items = events
    },
    setColors(state, data) {
      state.colors = data
    },
    selectItem(state, id) {
      const index = state.items.findIndex((item) => item.id === id)
      state.items[index].selected = true
    },
    unselectItem(state, id) {
      const index = state.items.findIndex((item) => item.id === id)
      state.items[index].selected = false
    },
    selectToggleAll(state, value) {
      state.items.map((item) => {
        item.selected = value
        return item
      })
    },
  },
  getters: {
    getCalendarColors(state) {
      return get(state, 'colors.calendar', {})
    },
    getEventColors(state) {
      return get(state, 'colors.event', {})
    },
    getCalendarsList(state) {
      return state.items
    },
    getSelectedCalendarsIdsState(state) {
      return state.items.filter((item) => item.selected).map((item) => item.id)
    },
    // getCalendarColorsById(state) {
    //   return state.items.reduce((item, res) => {
    //     res[`${item.id}`] = item.colorId
    //   }, {})
    // }
  },
  actions: {
    selectToggle({ commit }, { id }) {
      const selectedCalendarsIds = getSelectedCalendarsIds()
      if (selectedCalendarsIds.includes(id)) {
        deleteSelectedCalendarById(id)
        commit('unselectItem', id)
      } else {
        addSelectedCalendarId(id)
        commit('selectItem', id)
      }
    },
    selectToggleAll({ commit, state }, { value }) {
      if (value) {
        setSelectedCalendarsIds(state.items.map((item) => item._id))
      } else {
        setSelectedCalendarsIds([])
      }

      commit('selectToggleAll', value)
    },
    async loadCalendarList({ commit, state, getters }, refresh = false) {
      if (state.loaded && !refresh) return
      commit('pending', true)
      commit('setColors', {})

      const requests = []

      requests.push(
        axiosApi
          .get(
            `/google-api?url=https://www.googleapis.com/calendar/v3/users/me/calendarList`
          )
          .then(({ data }) => {
            const selectedCalendarsIds = getSelectedCalendarsIds()
            const result = data.items.map((item) => {
              item.selected = selectedCalendarsIds.includes(item.id)
              return item
            })
            result.unshift({
              id: 'system',
              summary: 'System',
              colorId: 'system_color',
              backgroundColor: '#8f1b2c',
              foregroundColor: '#ffffff',
              selected: selectedCalendarsIds.includes('system'),
            })
            commit('setItems', result)
          })
      )
      requests.push(
        axiosApi
          .get(`/google-api?url=https://www.googleapis.com/calendar/v3/colors`)
          .then(({ data }) => {
            commit('setColors', data || {})
          })
      )

      return Promise.all(requests)
        .then(() => {
          if (!localStorage.getItem('selectedCalendars')) {
            getters['getCalendarsList'].forEach((item) => {
              addSelectedCalendarId(item.id)
              commit('selectItem', item.id)
            })
          }
        })
        .finally(() => {
          commit('loaded', true)
          commit('pending', false)
        })
    },
    add({ dispatch, commit }, payload) {
      commit('pending', true)
      return axiosApi
        .post(
          '/google-api?url=https://www.googleapis.com/calendar/v3/users/me/calendarList',
          {
            id: payload.id,
          },
          {
            params: {
              colorRgbFormat: true,
            },
          }
        )
        .then(() => {
          const selectedCalendarsIds = getSelectedCalendarsIds()
          if (!selectedCalendarsIds.includes(payload.id)) {
            addSelectedCalendarId(payload.id)
            commit('selectItem', payload.id)
          }
          return dispatch('loadCalendarList', true)
        })
        .catch((error) => {
          commit('pending', false)
          return Promise.reject(error)
        })
    },
    update({ dispatch, commit }, payload) {
      commit('pending', true)
      return axiosApi
        .put(`/calendars/${payload.id}`, payload.calendar)
        .then(() => {
          return dispatch('loadCalendarList', true)
        })
        .catch((error) => {
          commit('pending', false)
          return Promise.reject(error)
        })
    },
    deleteCalendar({ dispatch, commit }, payload) {
      commit('pending', true)
      return axiosApi
        .delete(
          `/google-api?url=https://www.googleapis.com/calendar/v3/users/me/calendarList/${payload.id}`
        )
        .then(() => {
          const selectedCalendarsIds = getSelectedCalendarsIds()
          if (selectedCalendarsIds.includes(payload.id)) {
            deleteSelectedCalendarById(payload.id)
            commit('unselectItem', payload.id)
          }
          return dispatch('loadCalendarList', true)
        })
        .catch((error) => {
          commit('pending', false)
          return Promise.reject(error)
        })
    },
  },
}
