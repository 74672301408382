import normalizeStyles from '!!raw-loader!less-loader!@/assets/less/normalize/normalize.less'

const fist = `
  <?xml version="1.0" encoding="iso-8859-1"?>
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <link href="{{ resource_path($path. '/style.css') }}" rel="stylesheet">
    <title>Document</title>
    <style>
      /* * {
      outline: 1px solid red;
      outline-offset: -1px;
      }
      html {
        padding-top: 0.5in;
        padding-bottom: 0.5in;
      }*/
    </style>
  </head>
  <body>
`

const last = `</body></html>`

function getHtmlHeader(pageNumberPosition) {
  const right = getRight(pageNumberPosition)
  const textAlign = getTextAlign(pageNumberPosition)

  function getRight(p) {
    switch (p) {
      case 'right':
        return '27px'
      // case 'for-blanks':
      //   return '227px'
    }

    return '36%'
  }

  function getTextAlign(p) {
    switch (p) {
      case 'right':
        return 'right'
      // case 'for-blanks':
      //   return 'right'
    }

    return 'center'
  }

  return `
  <?xml version="1.0" encoding="iso-8859-1"?>
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <html>
    <head>
    <style>
    
    .header {
      height: 5px;
    }
  
    .pages {
      position: absolute;
      top: 5px;
      right: ${right};
      width: 28%;
      font-size: 12px;
      line-height: 1.33333333em;
      text-align: ${textAlign};
      font-family: 'Open Sans', sans-serif;
    }
    
    .pages.for-blanks[data-page="1"] {
      right: 227px;
      text-align: right
    }
    
    .container {
        height: 20px;
    }
    </style>
    </head>
      <body class="${pageNumberPosition || ''}" data-page="">
        <div class="header">
          <div class="pages  ${pageNumberPosition || ''}" data-page="">
             Page <span id='page'></span> of <span id='topage'></span>
          </div>
        </div>
        <div class="container" id="container"></div>
        <script> 
          var vars={};
          var x=window.location.search.substring(1).split('&');
          for (var i in x) {
            var z=x[i].split('=',2);
            vars[z[0]] = unescape(z[1]);
          }
          document.getElementById('page').innerHTML = vars.page; 
          document.getElementById('topage').innerHTML = vars.topage; 
          document.querySelector('.pages').setAttribute('data-page', vars.page); 
          document.querySelector('body').setAttribute('data-page', vars.page); 
          
         if(+vars.page < 2) {
            document.body.removeChild(document.getElementById('container'))
          }
        </script> 
      </body> 
    </html>
  `
}

const htmlFooter = `
  <?xml version="1.0" encoding="iso-8859-1"?>
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
  <style>
  
  .footer {
    padding-bottom: 20px;
  }
</style>
</head>
  <body>
  <div class="footer"></div>
  </body> 
</html>
  `

export default async function ($el, pageNumberPosition) {
  let bodyInner = getHTML($el.$el)

  const html = fist + bodyInner + last
  const htmlBlob = await new Blob([html], {
    type: 'text/html',
  })

  const stylesBlob = await new Blob([normalizeStyles, $el.getStyles()], {
    type: 'text/css',
  })

  const htmlHeaderBlob = await new Blob([getHtmlHeader(pageNumberPosition)], {
    type: 'text/html',
  })

  const htmlFooterBlob = await new Blob([htmlFooter], {
    type: 'text/html',
  })

  const formData = await new FormData()
  formData.append('view', htmlBlob)
  formData.append('css', stylesBlob)
  formData.append('header', htmlHeaderBlob)
  formData.append('footer', htmlFooterBlob)

  return formData
}

function getHTML(el) {
  const html = el.innerHTML

  const container = document.createElement('pre')
  document.body.appendChild(container)

  container.innerHTML = html

  // textarea
  const textareaListOrigin = el.querySelectorAll('textarea')
  const textareaList = container.querySelectorAll('textarea')

  textareaList.forEach((textarea, index) => {
    const textContainer = document.createElement('div')
    textContainer.className = textarea.className
    textContainer.innerHTML = textareaListOrigin[index].value.replace(
      /\n/g,
      '<br />'
    )
    textarea.parentElement.insertBefore(textContainer, textarea)
    textarea.parentElement.removeChild(textarea)
  })
  // end textarea

  // editor
  const editorsListOrigin = el.querySelectorAll('.editor-for-doc')
  const editorsList = container.querySelectorAll('.editor-for-doc')

  editorsList.forEach((item, index) => {
    const editorListOrigin = editorsListOrigin[index]
    const input = editorListOrigin.querySelector('[data-result]')
    const textContainer = document.createElement('div')
    textContainer.className = item.className
    textContainer.innerHTML = input.value.replaceAll('<p></p>', '<p><br></p>')
    textContainer.setAttribute('style', editorListOrigin.getAttribute('style'))
    item.parentElement.insertBefore(textContainer, item)
    item.parentElement.removeChild(item)
  })
  // end editor

  // inline input
  const inputsListOrigin = el.querySelectorAll(
    '.file-doc__inline-input-wrap input'
  )
  const inputsList = container.querySelectorAll(
    '.file-doc__inline-input-wrap input'
  )

  inputsList.forEach((input, index) => {
    const textContainer = document.createElement('div')
    textContainer.className = input.className
    textContainer.innerHTML = inputsListOrigin[index].value
    input.parentElement.insertBefore(textContainer, input)
    input.parentElement.removeChild(input)
  })
  // end inline input

  const result = container.innerHTML
  document.body.removeChild(container)

  return result
}
