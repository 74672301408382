import Vue from 'vue'
import { toPath, get } from 'lodash'
import {
  ATTACHMENT_KEY_CUSTOMERS,
  ATTACHMENT_KEY_VENDORS,
  ATTACHMENT_KEY_ESTIMATES,
  ATTACHMENT_KEY_INVOICES,
  ATTACHMENT_KEY_PAYMENTS_RECEIVED,
  ATTACHMENT_KEY_DEPOSITS,
  ATTACHMENT_KEY_BOOKING,
  ATTACHMENT_KEY_SHIPPING_FILE,
  ATTACHMENT_KEY_SHIPPING_FILE_DOCUMENTS,
  ATTACHMENT_KEY_SHIPPING_FILE_PICTURES,
  ATTACHMENT_KEY_SHIPPING_FILE_ATTACHMENTS,
  ATTACHMENT_TYPE_FORM_DATA,
  ATTACHMENT_KEY_COMMODITY_DOCUMENTS,
  ATTACHMENT_KEY_COMMODITY_FILES,
  ATTACHMENT_KEY_REPORTS,
  ATTACHMENT_KEY_PROJECTS,
} from '@/constants/mail'
import errorHandle from '@/helpers/errors/errorHandle'
import { axiosApi } from '@/axios'

/**
 addToMail props

 sendTo: [mail@mail.com, mail2@mail.com],
 subject: 'Some name',
 message: 'Some message',
 attachments: {
 [ATTACHMENT_KEY_...]: [{
 id: 1,
 name: 'Name for show',
 attachment: file with id | form data,
 type: undefined | 'form_data'
 }]
 }
 **/

function getState() {
  return {
    pending: false,
    error: false,
    isOpen: false,
    isShort: false,
    inputs: {
      send_to: {
        value: [null],
      },
      cc: {
        value: [],
      },
      bcc: {
        value: [],
      },
      subject: {
        value: null,
      },
      message: {
        value: null,
      },
      files: {
        value: [],
      },
    },

    attachments: {
      [ATTACHMENT_KEY_CUSTOMERS]: {
        name: 'Customers',
        value: [],
      },
      [ATTACHMENT_KEY_VENDORS]: {
        name: 'Persons',
        value: [],
      },
      [ATTACHMENT_KEY_ESTIMATES]: {
        name: 'Estimates',
        value: [],
      },
      [ATTACHMENT_KEY_INVOICES]: {
        name: 'Invoices',
        value: [],
      },
      [ATTACHMENT_KEY_PAYMENTS_RECEIVED]: {
        name: 'Payments Receipts',
        value: [],
      },
      [ATTACHMENT_KEY_DEPOSITS]: {
        name: 'Deposits',
        value: [],
      },
      [ATTACHMENT_KEY_BOOKING]: {
        name: 'Bookings',
        value: [],
      },
      [ATTACHMENT_KEY_SHIPPING_FILE]: {
        name: 'Shipping files',
        value: [],
      },
      [ATTACHMENT_KEY_SHIPPING_FILE_DOCUMENTS]: {
        name: 'Shipping file Documents',
        value: [],
      },
      [ATTACHMENT_KEY_SHIPPING_FILE_PICTURES]: {
        name: 'Shipping file Pictures',
        value: [],
      },
      [ATTACHMENT_KEY_SHIPPING_FILE_ATTACHMENTS]: {
        name: 'Shipping file Attachments',
        value: [],
      },
      [ATTACHMENT_KEY_COMMODITY_DOCUMENTS]: {
        name: 'Commodity Documents',
        value: [],
      },
      [ATTACHMENT_KEY_COMMODITY_FILES]: {
        name: 'Commodity files',
        value: [],
      },
      [ATTACHMENT_KEY_REPORTS]: {
        name: 'Reports',
        value: [],
      },
      [ATTACHMENT_KEY_PROJECTS]: {
        name: 'Projects',
        value: [],
      },
    },
    templates: [],
    selectedTemplate: null,
  }
}

const addressees = ['send_to', 'cc', 'bcc']
const attachmentsKeys = Object.keys(getState().attachments)

export default {
  namespaced: true,
  state: getState(),
  getters: {
    isFilledForSend(state) {
      return [
        ...state.inputs.send_to.value,
        ...state.inputs.cc.value,
        ...state.inputs.bcc.value,
      ].some((e) => e)
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, getState())
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
    setPropertyByPath(state, [path, value]) {
      const pathArr = toPath(path)
      const key = pathArr.pop()
      const baseObj = pathArr.length ? get(state, pathArr) : state

      if (!baseObj || !Object.prototype.hasOwnProperty.call(baseObj, key)) {
        return console.warn(
          `setPropertyByPath warning. ${path} is no correct path`
        )
      }

      Vue.set(baseObj, key, value)
    },
    setErrors(state, { overFormError, formErrors }) {
      state.error = overFormError || null

      for (const key in formErrors) {
        if (Object.prototype.hasOwnProperty.call(state.inputs, key)) {
          const error = formErrors[key]
          Vue.set(state.inputs[key], 'error', error)
        }
      }
    },
    resetErrors(state) {
      const inputs = state.inputs
      for (const key in inputs) {
        Vue.set(state.inputs[key], 'error', null)
      }
    },
    addAddressees(state, [key, sendTo]) {
      if (!addressees.includes(key)) {
        return console.warn(`Addressees with key "${key}" can\`t be delete`)
      }

      if (!Array.isArray(sendTo) || !sendTo.length) return

      const addresseesCurrent = state.inputs[key].value
      const sendToFiltered = sendTo.filter(
        (e) => !addresseesCurrent.includes(e)
      )
      let sendToNew = [...addresseesCurrent, ...sendToFiltered]

      sendToNew = sendToNew.filter((s) => s)
      if (!sendToNew.length) {
        sendToNew = [null]
      }

      Vue.set(state.inputs[key], 'value', sendToNew)
    },
    addAddresseeEmpty(state, key) {
      if (!addressees.includes(key)) {
        return console.warn(`Addressee with key "${key}" can\`t be added`)
      }

      Vue.set(state.inputs[key], 'value', [...state.inputs[key].value, null])
    },
    deleteAddressee(state, [key, index]) {
      if (!addressees.includes(key)) {
        return console.warn(`Addressee with key "${key}" can\`t be delete`)
      }

      Vue.delete(state.inputs[key].value, index)
    },

    //files
    addFiles(state, files) {
      const value = [...state.inputs.files.value, ...files]
      Vue.set(state.inputs.files, 'value', value)
    },
    deleteFile(state, index) {
      Vue.delete(state.inputs.files.value, index)
    },

    //attachments
    addAttachments(state, [key, attachments]) {
      if (!attachmentsKeys.includes(key)) {
        return console.warn(`Attachments with key "${key}" can\`t be added`)
      }

      const valueCurrent = state.attachments[key].value
      const valueCurrentIds = valueCurrent.map((v) => v.id)

      const valueFiltered = attachments.filter((attachment) => {
        const id = attachment && attachment.id

        return id && !valueCurrentIds.includes(id)
      })

      const value = [...valueCurrent, ...valueFiltered]
      Vue.set(state.attachments[key], 'value', value)
    },
    deleteAttachment(state, [key, index]) {
      Vue.delete(state.attachments[key].value, index)
    },
  },
  actions: {
    openWindow({ commit }) {
      commit('setProperty', ['isOpen', true])
      commit('setProperty', ['isShort', false])
    },
    addToMail({ state, commit, dispatch }, props) {
      const { sendTo, subject, message, attachments, templates } = props

      dispatch('openWindow')

      commit('addAddressees', ['send_to', sendTo])

      if (!state.templates.length && templates && templates.length) {
        commit('setProperty', ['templates', templates])
        dispatch('setTemplate', state.templates[0])
      }

      if (!state.inputs.subject.value && !state.inputs.message.value) {
        commit('setPropertyByPath', ['inputs.subject.value', subject || ''])
        commit('setPropertyByPath', ['inputs.message.value', message || ''])
      }

      if (attachments) {
        for (const key in attachments) {
          if (!attachmentsKeys.includes(key)) {
            console.warn(`Attachments with key "${key}" can\`t be added`)
          } else {
            commit('addAttachments', [key, attachments[key]])
          }
        }
      }
    },
    setTemplate({ commit }, payload) {
      commit('setProperty', ['selectedTemplate', payload])
      commit('setPropertyByPath', [
        'inputs.subject.value',
        get(payload, 'attributes.subject', ''),
      ])
      commit('setPropertyByPath', [
        'inputs.message.value',
        get(payload, 'attributes.message', ''),
      ])
    },
    closeWindow({ commit }) {
      commit('setProperty', ['isOpen', false])
      commit('setProperty', ['isShort', false])
    },
    collapseToggleWindow({ state, commit }) {
      commit('setProperty', ['isShort', !state.isShort])
    },
    deleteNewMail({ commit }) {
      commit('reset')
    },
    send({ state, getters, commit }) {
      if (!getters.isFilledForSend) return

      commit('setProperty', ['pending', true])

      const sendData = getSendData(state)

      axiosApi
        .post('/mail/', sendData)
        .then(() => {
          commit('reset')
          // Vue.prototype.$vModal.open('success', {
          //   title: 'Email successfully sent',
          // })
          Vue.prototype.$toaster({
            text: 'Email successfully sent',
            type: 'SUCCESS',
          })
        })
        .catch((e) => {
          commit('setErrors', errorHandle(e))
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
}

function getSendData(state) {
  const sendData = new FormData()
  const { inputs, attachments } = state

  // addressees
  for (const addresseeKey of addressees) {
    for (const addressee of inputs[addresseeKey].value) {
      sendData.append(`${addresseeKey}[]`, addressee || '')
    }
  }

  // subject
  // message
  sendData.append('subject', inputs.subject.value || '')
  sendData.append('message', inputs.message.value || '')

  //files
  for (const file of inputs.files.value) {
    sendData.append('files[]', file.file)
  }

  //attachments
  for (const key in attachments) {
    attachments[key].value.forEach((attachmentObj, attachmentIndex) => {
      if (attachmentObj.type === ATTACHMENT_TYPE_FORM_DATA) {
        const formDataEntries = Array.from(attachmentObj.attachment.entries())

        formDataEntries.forEach(([entryKey, entryValue]) => {
          sendData.append(
            `attachments[${key}][${attachmentIndex}][${entryKey}]`,
            entryValue
          )
        })

        sendData.append(
          `attachments[${key}][${attachmentIndex}][id]`,
          attachmentObj.id
        )
      } else {
        sendData.append(
          `attachments[${key}][${attachmentIndex}][id]`,
          (attachmentObj.attachment && attachmentObj.attachment.id) ||
            attachmentObj.id
        )
      }

      sendData.append(
        `attachments[${key}][${attachmentIndex}][name]`,
        attachmentObj.name
      )
    })
  }

  return sendData
}
