export const STATUS_WAITING_LIST = 'waiting_list'
export const STATUS_NEED_TO_BE_DISPATCH = 'need_to_be_dispatched'
export const STATUS_DISPATCHED = 'dispatched'
export const STATUS_PENDING_DELIVERY = 'pending_delivery'
export const STATUS_DISPATCH_CANCELED = 'dispatch_canceled'
export const STATUS_IN_YARD = 'in_yard'
export const STATUS_IN_FILE = 'in_file'
export const STATUS_DELETE = 'delete'
export const STATUS_SHIPPED = 'shipped'
export const STATUS_CUSTOMER_OWN_LOADING = 'customer_own_loading'
