const Company = () =>
  import(/*webpackChunkName: "Company" */ '@/views/Main/Company')
const CompanyProfile = () =>
  import(
    /*webpackChunkName: "CompanyProfile" */ '@/views/Main/Company/CompanyProfile'
  )
const CompanyEmployees = () =>
  import(
    /*webpackChunkName: "CompanyEmployees" */ '@/views/Main/Company/CompanyEmployees'
  )
const CompanyEmployee = () =>
  import(
    /*webpackChunkName: "CompanyEmployee" */ '@/views/Main/Company/CompanyEmployees/Employee'
  )
const CompanyEmployeeNotes = () =>
  import(
    /*webpackChunkName: "CompanyEmployeeNotes" */ '@/views/Main/Company/CompanyEmployees/Employee/Tabs/EmployeeNotes'
  )
const CompanyEmployeeTimeLog = () =>
  import(
    /*webpackChunkName: "CompanyEmployeeTimeLog" */ '@/views/Main/Company/CompanyEmployees/Employee/Tabs/EmployeeTimeLog'
  )
const CompanyEmployeeLog = () =>
  import(
    /*webpackChunkName: "CompanyEmployeeLog" */ '@/views/Main/Company/CompanyEmployees/Employee/Tabs/EmployeeLog'
  )
const CompanyEmployeeOverview = () =>
  import(
    /*webpackChunkName: "CompanyEmployeeOverview" */ '@/views/Main/Company/CompanyEmployees/Employee/Tabs/EmployeeOverview'
  )
import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

export default [
  {
    path: 'company',
    name: 'company',
    component: Company,
    redirect: {
      name: 'company-profile',
    },
    meta: {
      permission: [abilityActions.read, abilitySubjects.ACCOUNT_COMPANY],
      title: 'Company',
    },
    children: [
      {
        path: 'profile',
        name: 'company-profile',
        component: CompanyProfile,
        meta: {
          permission: [abilityActions.read, abilitySubjects.ACCOUNT_COMPANY],
        },
      },
      {
        path: 'employees',
        name: 'company-employees',
        component: CompanyEmployees,
        meta: {
          permission: [abilityActions.read, abilitySubjects.ACCOUNT_COMPANY],
        },
        children: [
          {
            path: ':employeeId',
            name: 'company-employee',
            component: CompanyEmployee,
            redirect: { name: 'company-employee-overview' },
            meta: {
              permission: [
                abilityActions.read,
                abilitySubjects.ACCOUNT_COMPANY,
              ],
            },
            children: [
              {
                path: 'overview',
                name: 'company-employee-overview',
                component: CompanyEmployeeOverview,
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.ACCOUNT_COMPANY,
                  ],
                },
              },
              {
                path: 'notes',
                name: 'company-employee-notes',
                component: CompanyEmployeeNotes,
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.ACCOUNT_COMPANY,
                  ],
                },
              },
              {
                path: 'time-log',
                name: 'company-employee-time-log',
                component: CompanyEmployeeTimeLog,
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.ACCOUNT_COMPANY,
                  ],
                },
              },
              {
                path: 'log',
                name: 'company-employee-log',
                component: CompanyEmployeeLog,
                meta: {
                  permission: [
                    abilityActions.read,
                    abilitySubjects.ACCOUNT_COMPANY,
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
]
