export const STATUS_UNPAID = 'unpaid'
export const STATUS_PARTIALLY_PAID = 'partially paid'
export const STATUS_PAID = 'paid'
export const STATUS_PRINTED = 'printed'
export const STATUS_SENT = 'sent'

export const FORMAT_REGULAR = 'regular_format'
export const FORMAT_TAX = 'tax_format'

export const filterByOptions = [
  {
    value: null,
    label: 'Show all',
  },
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'overdue',
    label: 'Overdue',
  },
]
