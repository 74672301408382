export const IMAGES_FORMATS_BY_EXT = {
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  png: 'image/png',
  bmp: 'image/bmp',
  svg: 'image/svg+xml',
  webp: 'image/webp',
  heic: 'image/heic',
  tiff: 'image/tiff',
  tif: 'image/tif',
}
export const IMAGES_FORMATS = Object.values(IMAGES_FORMATS_BY_EXT)

export const VIDEOS_FORMATS_BY_EXT = {
  webm: 'video/webm',
  mp4: 'video/mp4',
  ogg: 'video/ogg',
}
export const VIDEOS_FORMATS = Object.values(VIDEOS_FORMATS_BY_EXT)

export const TABLES_FILES_FORMATS_BY_EXT = {
  csv: 'text/csv',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
export const TABLES_FILES_FORMATS = Object.values(TABLES_FILES_FORMATS_BY_EXT)

export const DOC_FILES_FORMATS_BY_EXT = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}
export const DOC_FILES_FORMATS = Object.values(DOC_FILES_FORMATS_BY_EXT)
export const PDF_FORMAT_BY_EXT = {
  pdf: 'application/pdf',
}
export const PDF_FORMATS = Object.values(PDF_FORMAT_BY_EXT)

export const TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  PDF: 'PDF',
  OTHER: 'OTHER',
}

export const ALL_FORMATS_BY_EXT = {
  ...TABLES_FILES_FORMATS_BY_EXT,
  ...IMAGES_FORMATS_BY_EXT,
  ...VIDEOS_FORMATS_BY_EXT,
  ...PDF_FORMAT_BY_EXT,
  ...DOC_FILES_FORMATS_BY_EXT,
}
