<template>
  <div class="c-v-accept modal" @mousedown="onMousedown" @mouseup="onMouseup">
    <div class="modal__content" ref="content">
      <div class="modal__head">
        <!--          <BaseSvg class="modal__title-ico"/>-->
        <h2 class="modal__title">
          {{ modalProps.title || 'Accept this action?' }}
        </h2>
        <a href="#" class="modal__close" @click.prevent="onCancel">
          <BaseSvg name="ico-close" />
        </a>
      </div>
      <div class="c-v-accept__main modal__main">
        <BasePreloader v-if="pending" />
        <div
          v-if="modalProps.text"
          v-html="modalProps.text"
          class="c-v-accept__text text"
        ></div>
        <BaseInput
          v-if="withPassword"
          label="Confirmation password"
          v-model="password.value"
          :error="password.error"
          required
          type="password"
          class="c-v-accept__password"
        />
        <div class="c-v-accept__footer">
          <button @click.prevent="onCancel" class="c-v-accept__btn btn _gray">
            Cancel
          </button>
          <button @click.prevent="onAccept" class="c-v-accept__btn btn _red">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VAcceptModal',
  data() {
    return {
      pressTarget: false,
      password: {
        value: null,
        error: null,
      },
    }
  },
  computed: {
    withPassword() {
      return this.$root.withPassword
    },
    modalProps() {
      return this.$root.modalProps
    },
    pending() {
      return this.$root.pending
    },
  },
  methods: {
    onAccept() {
      this.password.error = null

      if (this.withPassword) {
        if (!this.password.value) {
          return (this.password.error = 'This field is required')
        }
        this.$vAccept._accept(this.password.value)
      } else {
        this.$vAccept._accept()
      }
    },
    onCancel() {
      this.$vAccept._cancel()
    },
    onMousedown(event) {
      this.pressTarget = event.target.contains(this.$refs.content)
    },
    onMouseup(event) {
      if (this.pressTarget && event.target.contains(this.$refs.content))
        this.onCancel()
    },
  },
  // watch: {
  //   'isOpen'(value) {
  //     if (value) {
  //       this.$vAccept.blockScroll()
  //     } else {
  //       this.$vAccept.unBlockScroll()
  //     }
  //   }
  // }
}
</script>

<style scoped lang="less">
.c-v-accept {
  z-index: 9999;

  & .modal__head {
    background-color: @c-yellow;

    color: white;
  }

  & .modal__close {
    border-color: white;

    & svg {
      fill: white;
    }
  }

  & .modal__content {
    width: 40rem;
  }

  &__main {
    position: relative;
  }

  &__text {
    margin-top: -0.4rem;
    margin-bottom: 2rem;

    text-align: center;

    white-space: pre-line;
  }

  &__password {
    margin-bottom: 2rem;
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }

  &__btn {
    margin-right: 1rem;
  }
}
</style>
