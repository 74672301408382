import { invert } from 'lodash'

export const TYPE_CAR = 1
export const TYPE_OTHER = 2
export const QUERY_PAGE_NAME = 'page'
export const QUERY_SEARCH_NAME = 'search'
export const QUERY_SEARCH_GLOBAL_NAME = 'search_global'
export const QUERY_LOADING_PLACE_NAME = 'loading_place'
export const QUERY_PORT_OF_LOADING_NAME = 'port_of_loading'
export const QUERY_PORT_OF_DISCHARGE_NAME = 'port_of_discharge'
export const QUERY_PLACE_OF_DELIVERY_NAME = 'place_of_delivery'
export const STORE_SECTION_NAMES_BY_SERVER_KEY = {
  main: 'main',
  shipping_information: 'shipping',
  dispatch_list: 'deliveryDispatchList',
  assign_dispatch: 'deliveryAssignDispatch',
  add_status: 'deliveryAddStatus',
  delivery_details: 'deliveryDetails',
  facility_location: 'deliveryFacilityLocation',
}

export const SERVER_SECTION_KEY_BY_STORE_SECTION_NAMES = invert(
  STORE_SECTION_NAMES_BY_SERVER_KEY
)
