import { isPlainObject } from 'lodash'
// import { baseURL } from '@/axios'
// import concatPath from '@/helpers/concatPath'
import {
  IMAGES_FORMATS,
  IMAGES_FORMATS_BY_EXT,
  PDF_FORMATS,
  PDF_FORMAT_BY_EXT,
  TABLES_FILES_FORMATS_BY_EXT,
  TYPES,
  VIDEOS_FORMATS,
  VIDEOS_FORMATS_BY_EXT,
  DOC_FILES_FORMATS_BY_EXT,
} from '@/constants/files'

// const downloadUrl = concatPath(baseURL, 'api/get_file?link=')

export default function filesFormat(files) {
  if (!Array.isArray(files)) return []

  return files.map((file) => {
    if (isPlainObject(file)) {
      file = { ...file }

      return setTypeToFile(file)
    } else if (typeof file === 'string') {
      file = {
        path: file,
        mime: getTypeByExt(file),
      }

      return setTypeToFile(file)
    }

    return file
  })
}

export function setTypeToFile(file) {
  file = { ...file }

  if (isPdf(file.mime)) {
    file.type = TYPES.PDF
    // file.path = createPathForPdf(file.path, !!file.x)
  } else if (isVideo(file.mime)) {
    file.type = TYPES.VIDEO
  } else if (isImage(file.mime)) {
    file.type = TYPES.IMAGE
  } else {
    file.type = TYPES.OTHER
  }

  return file
}

export function isPdf(mimeType) {
  return PDF_FORMATS.includes(mimeType)
}

export function isVideo(mimeType) {
  return VIDEOS_FORMATS.includes(mimeType)
}

export function isImage(mimeType) {
  return IMAGES_FORMATS.includes(mimeType)
}

// export function createPathForPdf(path, fromServer) {
//   // if (path.startsWith(downloadUrl)) return path
//
//   path += '#toolbar=0&statusbar=0&navpanes=0&scrollbar=0&view=Fit'
//
//   if (fromServer) {
//     // path = downloadUrl + path
//   }
//
//   return path
// }

const allExt = Object.assign(
  IMAGES_FORMATS_BY_EXT,
  VIDEOS_FORMATS_BY_EXT,
  PDF_FORMAT_BY_EXT,
  TABLES_FILES_FORMATS_BY_EXT,
  DOC_FILES_FORMATS_BY_EXT
)

function getTypeByExt(path) {
  const ext = path.split('.').pop() || ''
  return allExt[ext.toLowerCase()]
}

export const fileToGenerativePart = async (file) => {
  const base64EncodedDataPromise = new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result.split(',')[1])
    reader.readAsDataURL(file)
  })
  return {
    content: await base64EncodedDataPromise,
    mimeType: file.type,
  }
}
