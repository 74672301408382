import { axiosApi } from '@/axios'
import parseErrors from '@/helpers/errors/parseErrors'
import main from './main'
import trucking from './trucking'
import { get } from 'lodash'
import scrollToError from '@/helpers/scrollToError'

export default {
  namespaced: true,
  modules: {
    main,
    trucking,
  },
  state: {
    pending: false,
    loaded: false,
    overFormError: null,
  },
  mutations: {
    setOverFormError(state, error) {
      state.overFormError = error
    },
  },
  actions: {
    reset({ commit }) {
      commit('setOverFormError', null)
      commit('main/reset')
      commit('trucking/reset')
    },
    load({ commit }, { id, isNew }) {
      return axiosApi.get(`bookings/${id}`).then(({ data }) => {
        commit('main/setInitialData', data.attributes)

        if (isNew) {
          data.attributes.inputs.booking_number = ''
          data.attributes.inputs.dates = {}
          data.attributes.trucking_information.forEach((trucking) => {
            delete trucking.id
            trucking.inputs.dates = {}

            trucking.containers = []
          })
        }
        commit('main/setAll', data.attributes.inputs)
        commit('trucking/setAll', data.attributes.trucking_information)
      })
    },
    save({ state, getters, commit, dispatch }, id) {
      commit('setOverFormError', null)
      commit('main/clearErrorsAll')
      commit('trucking/clearErrorsAll')

      let errorsMainInputs = []
      let errorsMainDates = []
      let errorsTrucking = []
      let errorsTruckingDates = []
      let errorsContainers = []
      const sendData = {
        inputs: {
          dates: {},
        },
      }

      // main

      for (let name in state.main.inputs) {
        if (
          state.main.inputs[name].required &&
          !state.main.inputs[name].value
        ) {
          errorsMainInputs.push({
            name,
            error: 'This field is required',
          })
        }

        sendData.inputs[name] = state.main.inputs[name].value
      }

      sendData.inputs.commodity =
        sendData.inputs.commodity && sendData.inputs.commodity.id

      // const newEvents = []
      // const deleteEvents = []

      for (let name in state.main.dates) {
        let date = state.main.dates[name]

        if (date.required && !date.value) {
          errorsMainDates.push({
            name,
            error: 'This field is required',
          })
        }

        // if (date.value && date.value.reminder) {
        //   newEvents.push({
        //     type: 'booking',
        //     date_field: name,
        //     summary: `Booking ${name}`,
        //   })
        // } else {
        //   const eventToDelete = get(state, 'main.initialData.events', []) .find(
        //     (item) => item.date_field === name
        //   )
        //   if (eventToDelete) {
        //     deleteEvents.push(eventToDelete.id)
        //   }
        // }
        sendData.inputs.dates[name] = date.value ? date.value : null
      }

      // trucking

      sendData.trucking_information = []

      state.trucking.truckingList.forEach((trucking, truckingIndex) => {
        const sendTrucking = {
          inputs: {
            dates: {},
          },
          containers: [],
        }

        if (trucking.id) {
          sendTrucking.id = trucking.id
        }

        for (let name in trucking.inputs) {
          if (trucking.inputs[name].required && !trucking.inputs[name].value) {
            errorsTrucking.push({
              truckingIndex,
              name,
              error: 'This field is required',
            })
          }

          sendTrucking.inputs[name] = trucking.inputs[name].value
        }

        delete sendTrucking.inputs._id

        for (let name in trucking.dates) {
          let date = trucking.dates[name]

          if (date.required && !date.value) {
            errorsTruckingDates.push({
              truckingIndex,
              name,
              error: 'This field is required',
            })
          }

          // const date_field = `trucking[${truckingIndex}]${name}`

          // if (date.value && date.value.reminder) {
          //   newEvents.push({
          //     type: 'booking',
          //     date_field,
          //     summary: `Booking Trucking ${truckingIndex + 1} ${name}`,
          //   })
          // } else {
          //   const eventToDelete = get(state, 'main.initialData.events', []) .find(
          //     (item) => item.date_field === date_field
          //   )
          //   if (eventToDelete) {
          //     deleteEvents.push(eventToDelete.id)
          //   }
          // }

          sendTrucking.inputs.dates[name] = trucking.dates[name].value
        }

        trucking.containers.forEach((container, containerIndex) => {
          const sendContainer = {}

          for (let name in container) {
            if (container[name].required && !container[name].value) {
              errorsContainers.push({
                truckingIndex,
                containerIndex,
                name,
                error: 'This field is required',
              })
            }

            sendContainer[name] = container[name].value
          }

          if (!sendContainer.id) {
            delete sendContainer.id
          }

          delete sendContainer._id

          sendTrucking.containers.push(sendContainer)
        })

        sendData.trucking_information.push(sendTrucking)
      })

      // - -

      if (
        errorsMainInputs.length ||
        errorsMainDates.length ||
        errorsTrucking.length ||
        errorsTruckingDates.length ||
        errorsContainers.length
      ) {
        if (errorsMainInputs.length) {
          commit('main/setInputsErrorMultiple', errorsMainInputs)
        }

        if (errorsMainDates.length) {
          commit('main/setDatesErrorMultiple', errorsMainDates)
        }

        if (errorsTrucking.length) {
          commit('trucking/setInputsErrorMultiple', errorsTrucking)
        }

        if (errorsTruckingDates.length) {
          commit('trucking/setDatesErrorMultiple', errorsTruckingDates)
        }

        return createRequiredError()
      }

      function createRequiredError() {
        commit('setOverFormError', 'Required fields can not be empty')

        scrollToError()

        return Promise.reject(new Error('Required fields can`t be empty'))
      }

      const method = id ? 'put' : 'post'

      return axiosApi[method](`bookings/${id || ''}`, sendData)
        .then(async ({ data }) => {
          // const requests = []
          // if (newEvents.length) {
          //   requests.push(
          //     axiosApi.post('/calendar/events', {
          //       events: newEvents.map((item) => {
          //         return {
          //           ...item,
          //           id: get(data, 'item.attributes.id'),
          //         }
          //       }),
          //     })
          //   )
          // }
          // if (deleteEvents.length) {
          //   requests.push(
          //     axiosApi.delete('/calendar/events/mass_delete', {
          //       params: {
          //         id: deleteEvents,
          //       },
          //     })
          //   )
          // }
          // return Promise.all(requests).then(() => {
          //   return data
          // })
          return data
        })
        .then((data) => {
          dispatch('bookings/refresh', undefined, { root: true })

          return data.item.attributes
        })
        .catch((errorsObj) => {
          if (!errorsObj.response || !errorsObj.response.data) {
            commit('setOverFormError', errorsObj.message)
            return errorsObj
          }

          const dataError = errorsObj.response.data
          if (dataError.message) {
            commit('setOverFormError', dataError.message)
          }

          // const errors = dataError.errors
          // console.log(errors, state)
          const errors = parseErrors(errorsObj.response.data.errors)

          errorsMainInputs = []
          errorsMainDates = []
          errorsContainers = []

          if (errors.inputs) {
            for (const name in errors.inputs.dates) {
              errorsMainDates.push({
                name,
                error: errors.inputs.dates[name][0],
              })
            }

            delete errors.inputs.dates

            for (const name in errors.inputs) {
              errorsMainInputs.push({
                name,
                error: errors.inputs[name][0],
              })
            }
          }

          if (errors.trucking_information) {
            errors.trucking_information.forEach((trucking, truckingIndex) => {
              if (Array.isArray(trucking.containers)) {
                trucking.containers.forEach((container, containerIndex) => {
                  for (let name in container) {
                    errorsContainers.push({
                      truckingIndex,
                      containerIndex,
                      name,
                      error: container[name][0],
                    })
                  }
                })

                delete trucking.containers
              }

              getters['trucking/truckingDatesInputsKeys'].forEach((dateKey) => {
                if (!Object.prototype.hasOwnProperty.call(trucking, dateKey))
                  return

                errorsTruckingDates.push({
                  truckingIndex,
                  name: dateKey,
                  error:
                    get(trucking[dateKey], 'value[0]') ||
                    get(trucking[dateKey], '[0]'),
                })

                delete trucking[dateKey]
              })

              if (trucking.inputs) {
                const dates = trucking.inputs.dates
                for (let name in dates) {
                  errorsTruckingDates.push({
                    truckingIndex,
                    name,
                    error:
                      get(dates[name], 'value[0]') || get(dates[name], '[0]'),
                  })
                }

                delete trucking.inputs.dates

                for (let name in trucking.inputs) {
                  errorsTrucking.push({
                    truckingIndex,
                    name,
                    error: trucking.inputs[name][0],
                  })
                }
              }
            })
          }

          if (errorsMainInputs.length) {
            commit('main/setInputsErrorMultiple', errorsMainInputs)
          }

          if (errorsMainDates.length) {
            commit('main/setDatesErrorMultiple', errorsMainDates)
          }

          if (errorsTrucking.length) {
            commit('trucking/setInputsErrorMultiple', errorsTrucking)
          }

          if (errorsTruckingDates.length) {
            commit('trucking/setDatesErrorMultiple', errorsTruckingDates)
          }

          if (errorsContainers.length) {
            commit('trucking/setContainerErrorMultiple', errorsContainers)
          }

          return errorsObj
        })
    },
  },
}
