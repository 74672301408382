import { axiosApi } from '@/axios'
import { cloneDeep } from 'lodash'
import MainApp from '@/main'

import commodityMainStore from './commodityMainStore'
import commodityShippingStore from './commodityShippingStore'
import commodityDispatchListStore from './delivery/commodityDispatchListStore'
import commodityAddStatusStore from './delivery/commodityAddStatusStore'
import commodityAssignDispatchStore from './delivery/commodityAssignDispatchStore'
import commodityDeliveryDetailsStore from './delivery/commodityDeliveryDetailsStore'
import commodityFacilityLocationStore from './delivery/commodityFacilityLocationStore'
import commodityLabel from './commodityLabel'
import email from './email'

import errorHandle from '@/helpers/errors/errorHandle'
import getErrorsByPath from '@/helpers/errors/getErrorsByPath'
import _formMixin from '@/mixins/_formMixin'
import filesFormat from '@/helpers/filesFormat'

const getInitialState = () => {
  return {
    pending: false,
    pendingSave: false,
    pendingClearKeys: [],
    pendingVin: false,
    loaded: false,
    commodity: null,
    allFiles: [],
    loadingFiles: null,
    pendingSort: false,
    errors: null,
  }
}

export default {
  namespaced: true,
  modules: {
    main: commodityMainStore,
    shipping: commodityShippingStore,
    deliveryDispatchList: commodityDispatchListStore,
    deliveryAddStatus: commodityAddStatusStore,
    deliveryAssignDispatch: commodityAssignDispatchStore,
    deliveryDetails: commodityDeliveryDetailsStore,
    deliveryFacilityLocation: commodityFacilityLocationStore,
    commodityLabel: commodityLabel,
    // estimates: commodityEstimatesStore,
    email,
  },
  state: getInitialState(),
  getters: {
    files(state) {
      return {
        main: {
          name: 'Commodity',
          files: filesFormat(state.main.files),
        },
        assignDispatch: {
          name: 'Vendor',
          files: filesFormat(state.deliveryAssignDispatch.files),
        },
        status: {
          name: 'Status',
          files: filesFormat(state.deliveryAddStatus.files),
        },
        deliveryDetails: {
          name: 'Delivery details',
          files: filesFormat(state.deliveryDetails.files),
        },
        commodityLoading: {
          name: 'Commodity loading',
          files: filesFormat(state.loadingFiles),
        },
      }
    },
    allFilesFormat(state) {
      const allFiles = state.allFiles
      return filesFormat(allFiles)
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState())
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
    pending(state, value = true) {
      state.pending = value
    },
    loaded(state, value = true) {
      state.loaded = value
    },
    setCommodity(state, commodity) {
      state.commodity = commodity
    },
  },
  actions: {
    reset({ commit }) {
      commit('reset')
      commit('main/reset')
      commit('shipping/reset')
      commit('deliveryDispatchList/reset')
      commit('deliveryAssignDispatch/reset')
      commit('deliveryAddStatus/reset')
      commit('deliveryDetails/reset')
      commit('deliveryFacilityLocation/reset')
    },
    resetWithCommodity({ state, dispatch }) {
      const commodity = state.commodity

      dispatch('reset')
      dispatch('saveCommodity', commodity)
    },

    save({ state, commit, dispatch }, sectionKeys) {
      if (!state.main.customer || !state.main.type) {
        // deliveryDetails
        return Promise.reject(
          new Error('Commodity must has "customer" and "Commodity type"')
        )
      }

      if (!Array.isArray(sectionKeys)) {
        sectionKeys = sectionKeys ? [sectionKeys] : []
      }

      const commodityId = (state.commodity && state.commodity.id) || ''
      const formData = new FormData()
      const main = state['main']
      const shipping = state['shipping']
      const deliveryDispatchList = state['deliveryDispatchList']
      const deliveryAssignDispatch = state['deliveryAssignDispatch']
      const deliveryAddStatus = state['deliveryAddStatus']
      const deliveryDetails = state['deliveryDetails']
      const deliveryFacilityLocation = state['deliveryFacilityLocation']

      // - REQUIRED -
      if (!commodityId) {
        formData.append('customer', main.customer.id)
      } else {
        formData.append('customer', JSON.stringify(main.customer))
      }

      formData.append('commodity_type_id', main.type.id)

      if (!commodityId) {
        // - MAIN -
        if (main.isMuted) {
          saveMain(state, formData)
        }

        // - SHIPPING -
        if (shipping.isMuted) {
          saveShipping(state, formData)
        }

        // - DISPATCH LIST -
        if (deliveryDispatchList.isMuted) {
          saveDeliveryDispatchList(state, formData)
        }

        // - ASSIGN DISPATCH -
        if (deliveryAssignDispatch.isMuted) {
          saveDeliveryAssignDispatch(state, formData)
        }

        // - ADD STATUS -
        if (deliveryAddStatus.isMuted) {
          saveDeliveryAddStatus(state, formData)
        }

        // - DELIVERY DETAILS -
        if (deliveryDetails.isMuted) {
          saveDeliveryDetails(state, formData)
        }

        // - FACILITY LOCATION  -
        if (deliveryFacilityLocation.isMuted) {
          saveDeliveryFacilityLocation(state, formData)
        }
      } else {
        if (!sectionKeys || !sectionKeys.length) return

        // - MAIN -
        saveMain(state, formData)

        // // - MAIN -
        // if (sectionKey === 'main') {
        //   saveMain(state, formData)
        // }
        // - SHIPPING -
        if (sectionKeys.includes('shipping')) {
          saveShipping(state, formData)
        }

        // - DISPATCH LIST -
        if (sectionKeys.includes('deliveryDispatchList')) {
          saveDeliveryDispatchList(state, formData)
        }

        // - ASSIGN DISPATCH -
        if (sectionKeys.includes('deliveryAssignDispatch')) {
          saveDeliveryAssignDispatch(state, formData)
        }

        // - ADD STATUS -
        if (sectionKeys.includes('deliveryAddStatus')) {
          saveDeliveryAddStatus(state, formData)
        }

        // - DELIVERY DETAILS -
        if (sectionKeys.includes('deliveryDetails')) {
          saveDeliveryDetails(state, formData)
          saveDeliveryFacilityLocation(state, formData)
        }

        // - FACILITY LOCATION  -
        if (sectionKeys.includes('deliveryFacilityLocation')) {
          saveDeliveryFacilityLocation(state, formData)
        }
      }

      commit('setProperty', ['errors', null])
      commit('main/resetErrors')
      commit('shipping/resetErrors')
      commit('deliveryDispatchList/resetErrors')
      commit('deliveryAssignDispatch/resetErrors')
      commit('deliveryAddStatus/resetErrors')
      commit('deliveryDetails/resetErrors')
      commit('deliveryFacilityLocation/resetErrors')

      // deliveryAssignDispatch
      // deliveryAddStatus
      // deliveryDetails
      // deliveryFacilityLocation

      commit('setProperty', ['pendingSave', true])

      return axiosApi
        .post(`commodities/${commodityId}`, formData)
        .then(({ data }) => {
          data = data.item.attributes

          dispatch('saveCommodity', data)
          return data
        })
        .catch((e) => {
          const errors = errorHandle(e)
          if (e.response && e.response.data && e.response.data.errors) {
            errors.sectionKeys = Object.keys(e.response.data.errors)
          }

          commit('setProperty', ['errors', errors])

          dispatch('main/setErrors', errors.formErrors)
          dispatch(
            'shipping/setErrors',
            getErrorsByPath(errors.formErrors, 'shipping_information')
          )
          dispatch(
            'deliveryDispatchList/setErrors',
            getErrorsByPath(errors.formErrors, 'dispatch_list')
          )
          dispatch(
            'deliveryAssignDispatch/setErrors',
            getErrorsByPath(errors.formErrors, 'assign_dispatch')
          )
          dispatch(
            'deliveryAddStatus/setErrors',
            getErrorsByPath(errors.formErrors, 'add_status')
          )
          dispatch(
            'deliveryDetails/setErrors',
            getErrorsByPath(errors.formErrors, 'delivery_details')
          )
          dispatch(
            'deliveryFacilityLocation/setErrors',
            getErrorsByPath(errors.formErrors, 'facility_location')
          )

          throw e
        })
        .finally(() => {
          commit('setProperty', ['pendingSave', false])
        })
    },
    load({ state, commit, dispatch }, id) {
      commit('pending', true)

      if (state.loaded) {
        commit('reset')
      }

      return axiosApi
        .get(`commodities/${id}`)
        .then(({ data }) => {
          data = data.attributes

          dispatch('saveCommodity', data)

          commit('loaded')
          return data
        })
        .finally((data) => {
          commit('pending', false)
          return data
        })
    },
    saveCommodity({ commit, dispatch }, data) {
      commit('setProperty', ['commodity', data])
      commit('setProperty', [
        'allFiles',
        data.all_files.map((file) => file.attributes),
      ])
      commit('setProperty', ['loadingFiles', data.loading_files])

      data = cloneDeep(data)

      //set to modules
      // console.log('data', data)
      dispatch('main/setAll', data)

      if (data.shipping_information) dispatch('shipping/setAll', data)
      if (data.dispatch_list)
        commit('deliveryDispatchList/setAll', data.dispatch_list)
      if (data.assign_dispatch)
        commit('deliveryAssignDispatch/setAll', data.assign_dispatch)
      if (data.add_status) commit('deliveryAddStatus/setAll', data.add_status)
      commit('deliveryDetails/setAll', data)
      if (data.facility_location)
        commit('deliveryFacilityLocation/setAll', data.facility_location)

      //set muted false
      commit('main/setMuted', false)
      commit('shipping/setMuted', false)
      commit('deliveryDispatchList/setMuted', false)
      commit('deliveryAddStatus/setMuted', false)
      commit('deliveryAssignDispatch/setMuted', false)
      commit('deliveryDetails/setMuted', false)
      commit('deliveryFacilityLocation/setMuted', false)
    },
    sort({ commit }, ids) {
      commit('setProperty', ['pendingSort', true])

      return axiosApi
        .put('/files/change_order_number', {
          files: ids,
        })
        .then(({ data }) => {
          commit('setProperty', [
            'allFiles',
            data.map((file) => file.attributes),
          ])
        })
        .finally(() => {
          commit('setProperty', ['pendingSort', false])
        })
    },
    cancelDispatch({ commit, dispatch }, { id, section }) {
      commit('setProperty', ['pendingSave', true])

      return axiosApi
        .put(`commodities/${id}/cancel_dispatch`, {
          section,
        })
        .then(({ data }) => {
          dispatch('saveCommodity', data.item.attributes)
        })
        .finally(() => {
          commit('setProperty', ['pendingSave', false])
        })
    },
    clearSection({ state, commit, dispatch }, { sectionKeys }) {
      if (!Array.isArray(sectionKeys)) return

      commit('setProperty', ['pendingClearKeys', sectionKeys])

      sectionKeys.forEach((key) => {
        commit(`${key}/clearSection`, key)
      })

      return dispatch('save', sectionKeys)
        .catch((e) => {
          dispatch('saveCommodity', state.commodity)

          const errors = errorHandle(e)

          MainApp.$vModal.open('warning', {
            title: 'Can`t clear commodity section',
            message: errors.overFormError,
          })
        })
        .finally(() => {
          commit('setProperty', ['pendingClearKeys', []])
        })
    },
    checkVin({ state, commit }) {
      const vin = state.main.carTypeInputs.vin.value

      if (!vin || state.main.vinSaved) return

      const makeModelValue = state.main.carTypeInputs.make_model.value
      const yearValue = state.main.carTypeInputs.year.value

      if (makeModelValue && yearValue) return

      commit('setProperty', ['pendingVin', true])

      axiosApi
        .get('/get_car_by_vin', {
          params: {
            vin,
          },
        })
        .then(({ data: { make_model, year } }) => {
          if (!makeModelValue) {
            commit('main/setCarTypeInputValue', {
              name: 'make_model',
              value: make_model,
            })
          }

          if (!yearValue) {
            commit('main/setCarTypeInputValue', {
              name: 'year',
              value: year,
            })
          }
        })
        .finally(() => {
          commit('setProperty', ['pendingVin', false])
        })
    },
  },
}

function saveMain(state, formData) {
  const main = state['main']

  // - -

  let inputs = main.type.vin_field ? main.carTypeInputs : main.otherTypeInputs
  inputs = _formMixin.methods.formCreateSendDateMixin(inputs)

  formData.append('commodity_item[inputs]', JSON.stringify(inputs))

  for (let id of main.addedFiles) {
    formData.append('commodity_item[temp_files][]', id)
  }

  // for (let file of main.files) {
  //   // if (file.file) {
  //   //   formData.append('commodity_item[files][]', file.file)
  //   // }
  // }

  for (let id of main.filesToRemove) {
    formData.append('commodity_item[files_to_remove][]', id)
  }

  main.documents.forEach((document, index) => {
    let docInputs = _formMixin.methods.formCreateSendDateMixin(document.inputs)
    if (document.id) docInputs.id = document.id

    formData.append(
      `commodity_item[documents][${index}][document_inputs]`,
      JSON.stringify(docInputs)
    )

    for (let file of document.files) {
      formData.append(`commodity_item[documents][${index}][files][]`, file.file)
    }
  })

  for (let id of main.documentsToRemove) {
    formData.append(`commodity_item[documents_to_remove][]`, id)
  }

  let documentsStatus =
    main.documents_status.value && main.documents_status.value.id
      ? main.documents_status.value.id
      : null
  let documentsLocation =
    main.documents_location.value && main.documents_location.value.id
      ? main.documents_location.value.id
      : null

  if (documentsStatus) {
    formData.append('commodity_item[documents_status]', documentsStatus)
  }

  if (documentsLocation) {
    formData.append('commodity_item[documents_location]', documentsLocation)
  }

  let additionalCommodity =
    (main.additionalCommodities[0] &&
      JSON.stringify([main.additionalCommodities[0].value])) ||
    JSON.stringify([])
  formData.append('commodity_item[additional_commodities]', additionalCommodity)

  for (let note of main.notes) {
    note = {
      ...note,
    }

    delete note._id
    formData.append('commodity_item[notes][]', JSON.stringify(note))
  }

  if (!main.notes.length) {
    formData.append('commodity_item[notes][]', '')
  }
}

function saveShipping(state, formData) {
  const shipping = state['shipping']
  console.log(shipping)

  // - -

  let inputs = _formMixin.methods.formCreateSendDateMixin(shipping.inputs)
  formData.append('shipping_information[inputs]', JSON.stringify(inputs))

  for (let note of shipping.notes) {
    note = {
      ...note,
    }

    delete note._id
    formData.append('shipping_information[notes][]', JSON.stringify(note))
  }
  if (!shipping.notes.length) {
    formData.append('shipping_information[notes][]', '')
  }
}

function saveDeliveryDispatchList(state, formData) {
  const deliveryDispatchList = state['deliveryDispatchList']

  // - -

  let inputs = _formMixin.methods.formCreateSendDateMixin(
    deliveryDispatchList.inputs
  )
  formData.append('dispatch_list[inputs]', JSON.stringify(inputs))

  for (let note of deliveryDispatchList.notes) {
    note = {
      ...note,
    }

    delete note._id
    formData.append('dispatch_list[notes][]', JSON.stringify(note))
  }

  if (!deliveryDispatchList.notes.length) {
    formData.append('dispatch_list[notes][]', '')
  }

  if (
    deliveryDispatchList.special_instruction &&
    deliveryDispatchList.special_instruction.value
  ) {
    formData.append(
      'dispatch_list[special_instruction]',
      deliveryDispatchList.special_instruction.value
    )
  } else {
    formData.append('dispatch_list[special_instruction]', '')
  }
}

function saveDeliveryAssignDispatch(state, formData) {
  const deliveryAssignDispatch = state['deliveryAssignDispatch']

  // - -

  let inputs = _formMixin.methods.formCreateSendDateMixin(
    deliveryAssignDispatch.inputs
  )
  formData.append('assign_dispatch[inputs]', JSON.stringify(inputs))

  for (let id of deliveryAssignDispatch.addedFiles) {
    formData.append('assign_dispatch[temp_files][]', id)
  }
  // for (let file of deliveryAssignDispatch.files) {
  //   if (file.file) {
  //     formData.append('assign_dispatch[files][]', file.file)
  //   }
  // }

  for (let id of deliveryAssignDispatch.filesToRemove) {
    formData.append('assign_dispatch[files_to_remove][]', id)
  }

  for (let note of deliveryAssignDispatch.notes) {
    note = {
      ...note,
    }

    delete note._id

    formData.append('assign_dispatch[notes][]', JSON.stringify(note))
  }

  if (!deliveryAssignDispatch.notes.length) {
    formData.append('assign_dispatch[notes][]', '')
  }
}

function saveDeliveryAddStatus(state, formData) {
  const deliveryAddStatus = state['deliveryAddStatus']

  // - -

  let inputs = _formMixin.methods.formCreateSendDateMixin(
    deliveryAddStatus.inputs
  )
  formData.append('add_status[inputs]', JSON.stringify(inputs))

  for (let id of deliveryAddStatus.addedFiles) {
    formData.append('add_status[temp_files][]', id)
  }
  // for (let file of deliveryAddStatus.files) {
  //   if (file.file) {
  //     formData.append('add_status[files][]', file.file)
  //   }
  // }

  for (let id of deliveryAddStatus.filesToRemove) {
    formData.append('add_status[files_to_remove][]', id)
  }

  for (let note of deliveryAddStatus.notes) {
    note = {
      ...note,
    }

    delete note._id
    formData.append('add_status[notes][]', JSON.stringify(note))
  }

  if (!deliveryAddStatus.notes.length) {
    formData.append('add_status[notes][]', '')
  }
}

function saveDeliveryDetails(state, formData) {
  const deliveryDetails = state['deliveryDetails']

  // - -

  let inputsAdditional = _formMixin.methods.formCreateSendDateMixin(
    deliveryDetails.inputsAdditional
  )
  let inputs = Object.assign(
    {},
    _formMixin.methods.formCreateSendDateMixin(deliveryDetails.inputs)
  )
  inputs.additional_information = inputsAdditional
  formData.append('delivery_details[inputs]', JSON.stringify(inputs))

  for (let id of deliveryDetails.addedFiles) {
    formData.append('delivery_details[temp_files][]', id)
  }

  // for (let file of deliveryDetails.files) {
  //   if (file.file) {
  //     formData.append('delivery_details[files][]', file.file)
  //   }
  // }

  for (let id of deliveryDetails.filesToRemove) {
    formData.append('delivery_details[files_to_remove][]', id)
  }

  for (let note of deliveryDetails.notes) {
    note = {
      ...note,
    }

    delete note._id

    formData.append('delivery_details[notes][]', JSON.stringify(note))
  }

  if (!deliveryDetails.notes.length) {
    formData.append('delivery_details[notes][]', '')
  }
}

function saveDeliveryFacilityLocation(state, formData) {
  const deliveryFacilityLocation = state['deliveryFacilityLocation']

  // - -

  let inputs = _formMixin.methods.formCreateSendDateMixin(
    deliveryFacilityLocation.inputs
  )
  formData.append('facility_location[inputs]', JSON.stringify(inputs))
}
