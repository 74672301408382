import person from '../PersonStoreInstance'
import { employeeConfigs } from '@/constants/personsConfigs'

export default {
  namespaced: true,
  state: {
    props: { ...employeeConfigs },
    ...person.getState(),
  },
  mutations: {
    ...person.getMutations(),
  },
  actions: {
    ...person.getActions(),
  },
  getters: {
    ...person.getGetters(),
  },
}
