import windowSizes from '@/constants/windowSizes'

const mediaSizes = []
const mediaSizesDefaults = {}
for (let key in windowSizes) {
  mediaSizes.push({
    key,
    queryMax: `(max-width: ${windowSizes[key]}px)`,
  })
  mediaSizesDefaults[key] = false
}

const sizesHandlers = []

export default {
  namespaced: true,
  state: {
    ...mediaSizesDefaults,
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
  },
  getters: {
    xsAndDown(state) {
      return !!state['WINDOW_SIZE_XS']
    },
    smAndDown(state) {
      return !!state['WINDOW_SIZE_SM']
    },
    mdAndDown(state) {
      return !!state['WINDOW_SIZE_MD']
    },
    mlAndDown(state) {
      return !!state['WINDOW_SIZE_ML']
    },
    lgAndDown(state) {
      return !!state['WINDOW_SIZE_LG']
    },
    xlAndDown(state) {
      return !!state['WINDOW_SIZE_XL']
    },
    xxlAndDown(state) {
      return !!state['WINDOW_SIZE_XXL']
    },
  },
  actions: {
    checkMatches({ commit }, event) {
      const matchSize = mediaSizes.find((size) => size.queryMax === event.media)
      if (matchSize) {
        commit('setProperty', [matchSize.key, event.matches])
      }
    },
    init({ dispatch }) {
      mediaSizes.forEach(({ queryMax }) => {
        const sizeQuery = window.matchMedia(queryMax)
        sizeQuery.addEventListener('change', (event) =>
          dispatch('checkMatches', event)
        )
        dispatch('checkMatches', sizeQuery)
        sizesHandlers.push(sizeQuery)
      })
    },
  },
}
