<template functional>
  <div key="IncludesForBlanks">
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
      rel="stylesheet"
    />
  </div>
</template>

<script>
export default {
  name: 'IncludesForBlanks',
}
</script>
