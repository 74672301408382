import concatPath from '../../helpers/concatPath'

export default function getRoutesInner(arr, rootPath) {
  let routes = []

  for (const item of arr) {
    let curRoute = getRouteItem(item, rootPath)

    if (curRoute) routes.push(curRoute)

    if (Array.isArray(item.subItems)) {
      let subRoutes = getRoutesInner(item.subItems, rootPath)
      routes = routes.concat(subRoutes)
    }
  }

  return routes
}

function getRouteItem(item, rootPath) {
  if (!item.path || !item.component) return null
  return {
    path: concatPath(rootPath, item.path),
    component: item.component,
    props: item.props || {},
    meta: item.meta || {},
  }
}
