import Vue from 'vue'
import Counter from '@/helpers/Counter'
import setInputsErrors from '@/helpers/errors/setInputsErrors'
import { get as _get } from 'lodash'
import {
  STATUS_DISPATCHED,
  STATUS_PENDING_DELIVERY,
} from '@/constants/commoditiesStatuses'
import axios from 'axios'
import { axiosApi } from '@/axios'
let cancelRequest = null

const counter = new Counter()
const getInitialState = () => {
  return {
    pending: false,
    loaded: false,
    isMuted: false,
    inputs: {
      pick_up_date: {
        value: null,
        savedValue: null,
        error: null,
        required: true,
      },
      same_as_customer: {
        value: null,
        savedValue: null,
        error: null,
      },
      vendor: {
        value: null,
        savedValue: null,
        error: null,
        required: true,
      },
    },
    dispatchCanceled: false,
    hasSavedFields: false,
    notes: [],
    files: [],
    filesToRemove: [],
    waitingFiles: [],
    addedFiles: [],
    uploadRequests: {},
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    isRequiredSaved(state) {
      const inputs = state.inputs

      for (const key in inputs) {
        if (inputs[key].required && !inputs[key].savedValue) {
          return false
        }
      }

      return true
    },
    showSection(state, getters, rootState) {
      if (state.hasSavedFields) return true

      const statuses = _get(rootState, 'commodity.commodity.statuses', []).map(
        (s) => s.status.replace(' ', '_')
      )
      //
      // return rootGetters['commodity/deliveryAssignDispatch/isRequiredSaved']
      //     && !rootState.commodity.deliveryAssignDispatch.dispatchCanceled
      //     && !rootState.commodity.deliveryDetails.inputs.estimate_delivery_date.savedValue
      //     && !statuses.includes(STATUS_IN_YARD)

      return statuses.includes(STATUS_DISPATCHED)
    },
    canCancel(state, getters, rootState) {
      const statuses = _get(rootState, 'commodity.commodity.statuses', []).map(
        (s) => s.status.replace(' ', '_')
      )

      return (
        statuses.includes(STATUS_PENDING_DELIVERY) &&
        !rootState.commodity.deliveryDetails.inputs.estimate_delivery_date
          .savedValue &&
        !state.dispatchCanceled
      )
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState())
    },
    resetErrors(state) {
      const inputs = state.inputs
      for (let key in inputs) {
        inputs[key].error = null
      }
    },
    clearSection(state) {
      const newState = getInitialState()
      Object.assign(state.inputs, newState.inputs)
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
    setMuted(state, data = true) {
      state.isMuted = data
    },
    setInputValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.inputs, name)) return
      Vue.set(state.inputs[name], 'value', value)
    },
    setInputErrors(state, errors) {
      setInputsErrors(state.inputs, errors)
    },
    addNote(state) {
      state.notes.push(createNote())
    },
    removeNote(state, index) {
      state.notes.splice(index, 1)
    },
    setNoteValue(state, { index, value }) {
      if (!state.notes[index]) return
      state.notes[index].note = value
    },
    addFiles(state, files = []) {
      state.files = state.files.concat(files)
    },
    removeFile(state, index) {
      if (state.files[index].id) {
        state.filesToRemove.push(state.files[index].id)
      }
      const addedIndex = state.addedFiles.findIndex(
        (item) => item === state.files[index].id
      )
      if (addedIndex >= 0) {
        state.addedFiles.splice(addedIndex, 1)
      }

      state.files.splice(index, 1)
    },
    setAll(state, addStatus) {
      let hasSavedFields = false
      const inputs = state.inputs

      for (let key in inputs) {
        const value = addStatus.inputs[key]
        if (value !== null && (key !== 'same_as_customer' || value)) {
          hasSavedFields = true
        }

        inputs[key].value = value
        inputs[key].savedValue = value
      }

      state.files = addStatus.files || []
      state.notes = addStatus.notes
      state.dispatchCanceled = !!addStatus.inputs.dispatch_canceled

      // - - - -

      if (_get(addStatus.files, 'length') || _get(addStatus.notes, 'length')) {
        hasSavedFields = true
      }

      // - - - -

      state.loaded = true
      state.hasSavedFields = hasSavedFields
    },
    addWaitingFile(state, id) {
      state.waitingFiles.push(id)
    },
    removeWaitingFile(state, index) {
      if (cancelRequest && cancelRequest._id === state.waitingFiles[index]) {
        cancelRequest.source.cancel()
      }
      state.waitingFiles.splice(index, 1)
    },
    setFileId(state, [index, id]) {
      state.addedFiles.push(id)
      Vue.set(state.files[index], 'id', id)
    },
    setFileProgress(state, [index, progress]) {
      Vue.set(state.files[index], 'progress', progress)
    },
    addUploadRequest(state, [id, request, cancel]) {
      Vue.set(state.uploadRequests, id, { request, cancel })
    },
    removeUploadRequest(state, id) {
      Vue.delete(state.uploadRequests, id)
    },
  },
  actions: {
    async addFiles({ state, commit, dispatch }, files) {
      commit(
        'addFiles',
        files.map((item) => {
          item.progress = 0
          return item
        })
      )

      // add waiting
      for (let file of files) {
        if (!state.waitingFiles.includes(file._id)) {
          commit('addWaitingFile', file._id)

          const formData = new FormData()

          if (file.file) {
            formData.append('file', file.file)
          }

          const CancelToken = axios.CancelToken

          const cancel = {
            _id: file._id,
            source: CancelToken.source(),
          }

          const request = () => {
            if (!state.waitingFiles.includes(file._id)) {
              commit('removeUploadRequest', file._id)
              cancelRequest = null
              return Promise.resolve()
            }

            return axiosApi
              .post(`/temp_storage/`, formData, {
                cancelToken: cancel.source.token,
                onUploadProgress: (progressEvent) => {
                  const currentFileIndex = state.files.findIndex(
                    (item) => item._id === file._id
                  )
                  if (currentFileIndex >= 0) {
                    commit('setFileProgress', [
                      currentFileIndex,
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      ),
                    ])
                  }
                },
              })
              .then(({ data }) => {
                const currentFileIndex = state.files.findIndex(
                  (item) => item._id === file._id
                )
                if (currentFileIndex >= 0) {
                  commit('setFileId', [currentFileIndex, data.data.id])
                }
              })
              .finally(async () => {
                const currentFileIndex = state.files.findIndex(
                  (item) => item._id === file._id
                )
                if (
                  currentFileIndex >= 0 &&
                  !state.files[currentFileIndex].id
                ) {
                  commit('removeFile', currentFileIndex)
                }
                const currentWaitingIndex = state.waitingFiles.findIndex(
                  (item) => item === file._id
                )
                if (currentWaitingIndex >= 0) {
                  commit('removeWaitingFile', currentWaitingIndex)
                }
                commit('removeUploadRequest', file._id)
                cancelRequest = null
                await dispatch('checkUpload')
              })
            // .catch(Vue.prototype.$errorCatchDefault)
          }

          commit('addUploadRequest', [file._id, request, cancel])
        }
      }

      await dispatch('checkUpload')
    },
    async checkUpload({ state }) {
      if (cancelRequest) return
      for (let key in state.uploadRequests) {
        if (cancelRequest) {
          return
        } else {
          cancelRequest = state.uploadRequests[key].cancel
          await state.uploadRequests[key].request()
        }
      }
    },
    removeFile({ state, commit }, index) {
      const waitingIndex = state.waitingFiles.findIndex(
        (item) => item === state.files[index]._id
      )
      if (waitingIndex >= 0) {
        commit('removeUploadRequest', state.files[index]._id)
        commit('removeWaitingFile', waitingIndex)
      }
      commit('removeFile', index)
    },
    setAll({ commit }, commodity) {
      commit('reset')

      if (commodity && commodity.add_status) {
        commit('setAll', commodity.add_status)

        commit('setProperty', ['created', true])
      }

      commit('setMuted', false)
    },
    setErrors({ commit }, errors) {
      if (!errors) return
      commit('setInputErrors', errors.inputs)
    },
    cancel() {
      if (cancelRequest) {
        cancelRequest.source.cancel()
      }
    },
  },
}

function createNote() {
  return {
    _id: counter.value,
    note: null,
  }
}
