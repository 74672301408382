<template>
  <transition name="modal">
    <Overlay
      v-if="gallery.isOpen"
      :files="gallery.files"
      :currentIndex="gallery.currentIndex"
    />
  </transition>
</template>

<script>
import Overlay from './Overlay'

export default {
  name: 'VLightboxRoot',
  components: {
    Overlay,
  },
  computed: {
    gallery() {
      return this.$root.gallery
    },
  },
}
</script>
